import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button, TextField, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup } from '@mui/material';
import {getString, gotoSignin, httpPostUpload as httpPostFormData} from '@src/core/utils';
import {IBirthday, IUser, ICharacter} from "@shared/types";
import {setMe} from "@src/store/auth";
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "@src/store";
import Select, { SelectChangeEvent } from '@mui/material/Select';


interface Props {
    show: boolean
    save: boolean
    onClose: () => void
    onConfirm: (birthday: IBirthday) => void
    nowCharacter: any
    setTarotOpen: Dispatch<SetStateAction<boolean>>;
    userTarotInfo: boolean;
}

function BirthdayInputDialog({show, save, onClose, onConfirm, nowCharacter, setTarotOpen, userTarotInfo}: Props) {
    const history = useHistory();
    const [name, setName] = useState('');
    const [gender, setGender] = useState('male');
    const [yang, setYang] = useState(true);
    const [sangsi, setSangsi] = useState(true);
    const [birthdayYear, setBirthdayYear] = useState(2000);
    const [birthdayMonth, setBirthdayMonth] = useState(1);
    const [birthdayDay, setBirthdayDay] = useState(1);
    const [birthdayHour, setBirthdayHour] = useState(12);
    const [birthdayMinute, setBirthdayMinute] = useState(0);
    const [mbti, setMbti] = useState('ISTJ');
    const fullNameRegex = /^[가-힣]+$/;

    //
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const dispatch = useDispatch();

    let birthday: IBirthday = {
        name: name,
        year: birthdayYear,
        month: birthdayMonth,
        day: birthdayDay,
        yang: yang, // 필요에 따라 입력 받음
        sex: gender === 'male',
        sangsi: sangsi, // 필요에 따라 입력 받음
        hour: birthdayHour,
        minute: birthdayMinute,
        birthplace: true, // 필요에 따라 입력 받음
        mbti: mbti
    };

    function confirm() {
        birthday.name = name;
        birthday.year = birthdayYear;
        birthday.month = birthdayMonth;
        birthday.day = birthdayDay;
        birthday.hour = birthdayHour;
        birthday.minute = birthdayMinute;
        birthday.yang = yang;
        birthday.sex = gender === 'male';
        birthday.sangsi = sangsi;
        birthday.mbti = mbti;

        //
        onConfirm(birthday);

        //
        if(save) {
            //
            const formData = new FormData();
            formData.set('birthday', JSON.stringify(birthday));
            formData.set('name', name);

            //
            httpPostFormData('/api/users/edit', formData).then((res) => {
                if(res.status >= 0) {
                    dispatch(setMe(res.me));
                    onClose()
                }
            });
        }

        return false;
    }

    useEffect(() => {
        if(!me)
            gotoSignin(false);
        else {
            setName(me.name);
            setGender(me.birthday && (me.birthday?.sex ? 'male' : 'female') || '');
            setYang(me.birthday && me?.birthday?.yang ? true : false);
            setSangsi(me.birthday && me?.birthday?.sangsi ? true : false);
        }
    }, []);

    useEffect(() => {
        if (me && me?.birthday) {
            setBirthdayYear(me?.birthday.year ?? 2000);
            setBirthdayMonth(me?.birthday.month ?? 1);
            setBirthdayDay(me?.birthday.day ?? 1);
            setBirthdayHour(me?.birthday.hour ?? 12);
            setBirthdayMinute(me?.birthday.minute ?? 0);
            // 성별 상태 업데이트
            setGender(me?.birthday.sex ? 'male' : 'female');
            // 음양 상태 업데이트
            setSangsi(me?.birthday.sangsi);
            setMbti(me.birthday.mbti ?? '');
        }
    }, [me]);

    const handleMbtiChange = (event: SelectChangeEvent) => {
        setMbti(event.target.value as string);
    };

    return (
        <Dialog open={show} onClose={onClose} id="myInfo" className={`${nowCharacter?.type == 20 ? "tarot" : ""}`}>
            <DialogTitle>정보 입력</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => {e.preventDefault(); confirm();}}>
                    <div className="form-wrapper" style={{overflowX: 'hidden'}}>
                        <FormGroup style={{overflowX: 'hidden'}}>
                            {/*<FormControl component="fieldset" style={{display:'flex', justifyContent: 'center', marginBottom: '30px'}}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                                display: "none"
                            }}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: "120px",
                                height: "120px",
                                margin: "0px auto",
                                borderRadius: '100px',
                                textAlign: "center",
                                cursor:"pointer",
                                overflow: "hidden"
                            }}
                            onClick={() => imageUploader.current!.click()}
                        >
                            <EditOutlinedIcon id="editImage"/>
                            <img
                                src={me?.imageURL}
                                ref={uploadedImage}
                                style={{
                                    width: "120px",
                                    height: "120px",
                                    filter: 'brightness(60%)'
                                }}
                            />
                        </div>
                    </FormControl>*/}
                            {/*<div className="input-wrapper">
                        <label>{getString('Signup_Username')}</label>
                        <input className={`${email !== '' && !emailRegex.test(email) ? "error" : ""}`} type="email" value={email} placeholder="이메일 입력" onChange={(e) => setEmail(e.target.value)} autoComplete="email"/>
                        {
                            email !== '' && !emailRegex.test(email) &&
                            <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                        }
                    </div>*/}
                            <div className="global-input-wrapper input-name">
                                <label>{getString('Signup_Username')}</label>
                                <input className={`${name !== '' && !fullNameRegex.test(name) ? "error" : ""}`} type="text" defaultValue={name} placeholder="이름을 입력하세요" onChange={(e) => setName(e.target.value)}/>
                                {
                                    name !== '' && !fullNameRegex.test(name) &&
                                    <p className="error-text">올바르지 않은 이름 형식입니다</p>
                                }
                            </div>
                            <div className="global-input-wrapper input-gender">
                                <label>성별</label>
                                <div className="global-radio-wrapper">
                                    <button type="button" className={`${gender === 'male' ? "clicked" : ""}`} onClick={() => setGender('male')}>남성</button>
                                    <button type="button" className={`${gender === 'female' ? "clicked" : ""}`} onClick={() => setGender('female')}>여성</button>
                                </div>
                            </div>
                            <div className="global-input-wrapper input-birthdate">
                                <label>생년월일</label>
                                <div className="birthday-form">
                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                        <TextField
                                            type="text"
                                            value={birthdayYear ? String(birthdayYear) : ''}
                                            style={{marginRight: '5px'}}
                                            onChange={(e) => {
                                                const year = Number(e.target.value);
                                                setBirthdayYear(year);
                                            }}
                                        />
                                        년
                                    </div>
                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                        <TextField
                                            type="text"
                                            value={birthdayMonth ? String(birthdayMonth).padStart(2, '0') : ''}
                                            style={{marginRight: '5px'}}
                                            onChange={(e) => {
                                                const month = Number(e.target.value);
                                                setBirthdayMonth(month);
                                            }}
                                        />
                                        월
                                    </div>
                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                        <TextField
                                            type="text"
                                            value={birthdayDay ? String(birthdayDay).padStart(2, '0') : ''}
                                            style={{marginRight: '5px'}}
                                            onChange={(e) => {
                                                const day = Number(e.target.value);
                                                setBirthdayDay(day);
                                            }}
                                        />
                                        일
                                    </div>
                                </div>
                            </div>
                            <div className="global-input-wrapper input-solar">
                                <div className="global-radio-wrapper">
                                    <button type="button" className={`${yang === true ? "clicked" : ""}`} onClick={() => setYang(true)}>양력</button>
                                    <button type="button" className={`${yang === false ? "clicked" : ""}`} onClick={() => setYang(false)}>음력</button>
                                </div>
                            </div>
                            <div className="global-input-wrapper input-birthtime">
                                <label style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <span>태어난 시간</span>
                                    <span className="global-checkbox-wrapper">
                                        <input type="checkbox" name="sangsi" defaultChecked={!me?.birthday?.sangsi} onClick={(e) => setSangsi(!e.currentTarget.checked)}/> <b>모름</b>
                                    </span>
                                </label>
                                <TextField
                                    type="time"
                                    fullWidth
                                    style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    disabled={!sangsi}
                                    defaultValue={me?.birthday?.hour && (String(me?.birthday?.hour).padStart(2, '0') + ':' + String(me?.birthday?.minute).padStart(2, '0')) || undefined}
                                    onChange={(e) => {
                                        const [hour, minute] = e.target.value.split(':').map(Number);
                                        setBirthdayHour(hour);
                                        setBirthdayMinute(minute);
                                    }}
                                />
                            </div>
                            {
                                nowCharacter?.hashID == 'orjmvr' &&

                                <div className="global-input-wrapper">
                                    <label style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                        <span>MBTI</span>
                                    </label>
                                    <Select
                                        id="mbtiSelector"
                                        value={mbti}
                                        style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                        onChange={handleMbtiChange}
                                    >
                                        <MenuItem value={'ISTJ'}>ISTJ</MenuItem>
                                        <MenuItem value={'ISFJ'}>ISFJ</MenuItem>
                                        <MenuItem value={'INFJ'}>INFJ</MenuItem>
                                        <MenuItem value={'INTJ'}>INTJ</MenuItem>
                                        <MenuItem value={'ISTP'}>ISTP</MenuItem>
                                        <MenuItem value={'ISFP'}>ISFP</MenuItem>
                                        <MenuItem value={'INFP'}>INFP</MenuItem>
                                        <MenuItem value={'INTP'}>INTP</MenuItem>
                                        <MenuItem value={'ESTP'}>ESTP</MenuItem>
                                        <MenuItem value={'ESFP'}>ESFP</MenuItem>
                                        <MenuItem value={'ENFP'}>ENFP</MenuItem>
                                        <MenuItem value={'ENTP'}>ENTP</MenuItem>
                                        <MenuItem value={'ESTJ'}>ESTJ</MenuItem>
                                        <MenuItem value={'ESFJ'}>ESFJ</MenuItem>
                                        <MenuItem value={'ENFJ'}>ENFJ</MenuItem>
                                        <MenuItem value={'ENTJ'}>ENTJ</MenuItem>
                                    </Select>
                                </div>
                            }
                            {/*<FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>이름</FormLabel>
                        <TextField fullWidth value="" />
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>생년월일</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>거주지</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>전화번호</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>*/}
                            {
                                nowCharacter?.type === 20 && !userTarotInfo ?
                                    <button type="button" onClick={() => {window.scrollTo(0, 0); setTarotOpen(true)}} className="goodking-btn" style={{marginTop: '0px'}}>
                                        타로카드 뽑기
                                    </button>
                                    :
                                    <button type="submit" onClick={() => window.scrollTo(0, 0)} className="goodking-btn" style={{marginTop: '0px'}}>
                                        확인
                                    </button>
                            }
                        </FormGroup>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default BirthdayInputDialog;
