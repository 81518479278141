
function Notification() {

    return (
        <div className="chats notification f-container" style={{overflow: 'auto'}}>
            <h4 style={{fontSize: '29px'}}>알림</h4>
            <div className="notification-wrapper">
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70" alt="사주천왕"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
                <hr/>
                <div className="notification-box">
                    <img src="/goodking-bg.png" width="70" height="70"/>
                    <div className="notification-contents">
                        <h6 className="notification-title multi-line-text-truncate">200 금화가 충전되었습니다!</h6>
                        <p className="notification-text text-truncate">추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)추가 상담을 진행 해보세요 :)</p>
                    </div>
                    <time>13분 전</time>
                </div>
            </div>
        </div>
    )
}
 
 export default Notification
 