import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICategory, ICharacter, ITag, IUser, IUserAttachment } from '../../shared/types';
import { setMe } from '../../store/auth';
import AdminTabs, { AdminTab } from './AdminTabs';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageList: {
    width: 500,
    height: 450,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function AdminCharacterListScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [characters, setCharacters] = useState<ICharacter []>([]);
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(AdminTab.CHARACTERS);

  useEffect(() => {
    refreshCharacters();
  }, []);

  useEffect(() => {
    let elementApp = document.getElementsByClassName('App')[0];
    elementApp.id = 'adminApp';

    return () => {
      elementApp.id = 'goodkingApp';
    }
  }, []);

  function refreshCharacters() {
    httpGet('/api/characters').then((res) => {
      setCharacters(res.characters);
    });
  }

  function deleteCharacter(hashID: string) {
    if(window.confirm('Are you sure?')) {
      httpPost('/api/characters/remove', {
        characterHashID: hashID
      }).then((res) => res.status >= 0 && refreshCharacters());
    }
  }
  
  return (
    <>
      <AdminTabs tab={tab} onChange={(t) => setTab(t)}/>
      <Box sx={{flexGrow: 1, paddingX: '15px'}} className="character-list-page">
        <button className="goodking-btn" style={{ margin: "20px 0px", }} onClick={() => history.push('/admin/characters/add')}>새 캐릭터 만들기</button>

        <TableContainer component={Paper}>
          <Table width="100%">
            <colgroup>
              <col width="50%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{color: '#ffffff'}}>{getString('CharacterList_Name')}</StyledTableCell>
                <StyledTableCell style={{color: '#ffffff', textAlign: 'center'}}></StyledTableCell>
                <StyledTableCell style={{color: '#ffffff', textAlign: 'center'}}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {characters.map((a) => (
                  <StyledTableRow key={a.hashID}>
                    <StyledTableCell>
                      <div>
                        {a?.isUnse ? a?.unseName : a?.name}
                        <br/>Chats: {a.chatCount}<br/>Messages: {a.messageCount}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div style={{backgroundImage: `url('${a.imageURL}')`, width: "64px", height: "64px", backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '100px'}}></div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Box style={{display: 'flex', flexDirection: 'column'}}>
                        <Link style={{display: 'inline-block', width: "100%", marginBottom:'5px'}} to={'/admin/characters/' + a.hashID}>
                          <button className="goodking-sm-btn">편집</button>
                        </Link>
                        <Link to="#" onClick={(e) => deleteCharacter(a.hashID)}>
                          <button className="goodking-sm-btn red">삭제</button>
                        </Link>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
 
 export default AdminCharacterListScreen
 