import {Drawer, List, ListItem, ListItemIcon, ListItemText, Modal} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {nanoid} from "@reduxjs/toolkit";
import {loadPaymentWidget, PaymentWidgetInstance} from "@tosspayments/payment-widget-sdk";
import React, {useEffect, useRef, useState} from "react";
import {getString, gotoSignin, httpGet, httpPost} from "@src/core/utils";
import {setMe} from "@src/store/auth";
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {IShopItemModel, IUser} from "@src/shared/types";
import {IS_STAGE, TOSS_CLIENT_KEY} from "@src/core/commons";
import {ReducerType} from "@src/store";
import InfoFooter from "@src/screens/app/InfoFooter";
import {useHistory} from "react-router-dom";


export function ShopModal() {
    const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
    const [selectedItem, selectItem] = useState<IShopItemModel | null>(null);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<IShopItemModel []>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const history = useHistory();

    useEffect(() => {
        httpGet('/api/shop/items').then((res) => {
            let items: IShopItemModel [] = res.items;
            setItems(items);
            selectItem(items[0]);
        });
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    })


    useEffect(() => {
        if (selectedItem && selectedItem.realPrice > 0) {
            setTimeout(async () => {
                const paymentWidget = await loadPaymentWidget(TOSS_CLIENT_KEY, me?.hashID || '')
                paymentWidget.renderPaymentMethods("#payment-widget", {
                    value: selectedItem.realPrice,
                    currency: 'KRW',
                });
                paymentWidgetRef.current = paymentWidget;
            }, 0);
        }
    }, [selectedItem]);

    async function payment() {

        if(!me)
            return gotoSignin();

        //
        const paymentWidget = paymentWidgetRef.current;

        //
        setTimeout(() => setLoading(true), 500);

        try {
            let baseUrl = window.location.protocol + '//' + window.location.host;
            paymentWidget?.requestPayment({
                orderId: selectedItem?.hashID + "_" + nanoid(),
                orderName: selectedItem?.name || '',
                successUrl: baseUrl + '/shop/result/success',
                failUrl: baseUrl + '/shop/result/fail',
                customerName: me?.name,
                customerEmail: me?.email,
            });
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    const [drawerOpen, setDrawerOpen] = useState(false)

    useEffect(() => {
        const modalSeenBy = localStorage.getItem('modalSeenBy');
        if (!modalSeenBy && me) {
            setDrawerOpen(true);
        }
    }, [me]);

    const handleCloseForever = () => {
        if (me) {
            localStorage.setItem('modalSeenBy', me.hashID);
        }
        setDrawerOpen(false);
    };

    return (
        <article id="paymentsDialog">
            <div className="my-cash-wrapper">
                <div className="my-cash">
                    <div className="left">
                        <img src="/gold.svg" width={20} height={20} alt="사주천왕 금화"/>
                        보유금화
                    </div>
                    <div className="right">
                        <b>{me?.cashMoney ? me?.cashMoney?.toLocaleString() : '0'}</b>
                        금화
                    </div>
                </div>
            </div>
            <div className="payment-text-box">
                <strong>결제요금 선택</strong>
                {/*<sub>(VAT 별도)</sub>*/}
            </div>
            <div className="table-head">
                <p>충전 금화</p>
                <p>결제 금액</p>
            </div>
            <FormControl id="cashTable">
                <RadioGroup value={selectedItem}>
                    {items.map(x => (
                        <FormControlLabel className="cash-row" value={x} control={<Radio sx={{color: '#E5E6E8', '&.Mui-checked': {color: '#ED503C',},}}/>} label={
                            <>
                                <span>{x.name}</span>
                                <button>{x.realPrice?.toLocaleString()}원</button>
                            </>
                        } onClick={() => selectItem(x)}/>
                    ))}
                </RadioGroup>
            </FormControl>
            <div id="payment-widget"/>
            <div className="payment-info">
                <div className="pay-amount">
                    <h6>결제금액</h6>
                    <div className="pay-amount-detail">
                        <p>
                            <span>상품금액(부가세 포함)</span>
                            <span>{selectedItem?.realPrice.toLocaleString()}원</span>
                        </p>
                        <p>
                            <span>할인쿠폰</span>
                            <span>{selectedItem?.addCashMoneyPromotion.toLocaleString()}원</span>
                        </p>
                    </div>
                    <p className="total-amount">
                        <b>총 결제금액</b>
                        <b>{((selectedItem?.realPrice ?? 0) + (selectedItem?.addCashMoneyPromotion ?? 0)).toLocaleString()}원</b>
                    </p>
                </div>
                <div className="pay-information">
                    <ul>
                        <li>충전된 금화는 결제 시점으로부터 1년 이내에 사용해야하며, 이후 소멸됩니다</li>
                        <li>환불 가능기간은 결제 시점으로부터 7일 이내이며, 결제한 금화를 사용하지 않아야 환불이 가능합니다</li>
                    </ul>
                </div>
            </div>
            <div className="button-box" style={{marginBottom: '5rem'}}>
                <button className="goodking-btn" onClick={(e) => {
                    e.preventDefault();
                    payment();
                }}>{getString('Payment')}</button>
            </div>

            {
                drawerOpen &&
                <Drawer
                    anchor="bottom"
                    open={true}
                    onClose={() => setDrawerOpen(false)}
                >
                    <div className="shop-bottom-sheet">
                        <h6 style={{
                            fontSize: '15px',
                            fontWeight: '500',
                            color: '#000000D9',
                            marginBottom: '8px'
                        }}>
                            <b style={{fontSize: '13px', fontWeight: '600'}}>💁‍♀️ 사주천왕 결제창 첫 방문이시군요?</b> <br/><br/>
                            <p style={{fontSize: '13px', fontWeight: '600', color: '#000000A6', lineHeight: '19.5px'}}>
                                <strong style={{color: '#FD3D51'}}>‘내 정보’</strong> 탭에서 이용약관(포인트 구매 항목),환불정책, 결제 및 환불 문의를 위한 이메일 주소를
                                확인하실 수 있습니다
                            </p>
                        </h6>
                    </div>
                    <div className="btn-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '45px', padding: '0px 15px 15px'}}>
                        <button style={{visibility: 'hidden', border: 'none', backgroundColor: 'transparent', fontSize: '15px', fontWeight: '600', color: '#000000D9', cursor: 'pointer'}} onClick={() => handleCloseForever()}>닫기</button>
                        <button style={{border: 'none', backgroundColor: 'transparent', fontSize: '14px', fontWeight: '600', color: '#ED503C', cursor: 'pointer'}} onClick={() => {handleCloseForever(); history.push('/more');}}>확인</button>
                    </div>
                </Drawer>
            }

            <InfoFooter/>
            {/*충전된 금화는 결제 시점으로부터 1년 이내에 사용해야하며, 이후 소멸됩니다
            환불 가능기간은 결제 시점으로부터 7일 이내이며, 결제한 금화를 사용하지 않아야 환불이 가능합니다*/}
        </article>
    )
}

export default ShopModal;