import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles, Checkbox } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { CharacterType, ICategory, ICharacter, ICharacterAttachment, ICharacterConditionMessages, ICharacterUnseSteps, IChat, IChatMessage, ITag, IUser, IUserAttachment } from '../../shared/types';
import { setMe } from '../../store/auth';
import "./AdminCharacterScreen.css";
import { ChatMessageElement } from '../chat/ChatScreen';
import { DataGrid, GridColDef, GridCellEditCommitParams, GridSelectionModel } from '@mui/x-data-grid';

interface UnseRow {
    id: number;
    order: number;
    message: string;
}

function CharacterForm({
                           selectedCharacter,
                           allTags,
                       }: {
    selectedCharacter: ICharacter | null,
    allTags: ITag [],
}) {

    const history = useHistory();
    const imageFileInput = useRef<HTMLInputElement>(null);
    const subImageFileInput = useRef<HTMLInputElement>(null);
    const unseImageFileInput = useRef<HTMLInputElement>(null);
    const unseAdImageFileInput = useRef<HTMLInputElement>(null);
    const [name, setName] = useState('');
    const [modelID, setModelID] = useState('');
    const [desc, setDesc] = useState('');
    const [persona, setPersona] = useState('');
    const [helloText, setHelloText] = useState('');
    const [visible, setVisible] = useState(1);
    const [tags, setTags] = useState<number []>([]);
    const [type, setType] = useState<CharacterType>(CharacterType.SAJU_SINGLE);
    const [sayHints, setSayHints] = useState<ISayHint []>([]);
    const [conditionMessages, setConditionMessages] = useState<IConditionMesage []>([]);
    const [selectedSayHintIDs, selectSayHintIDs] = useState<number []>([]);
    const [isUnse, setIsUnse] = useState(false);
    const [unseName, setUnseName] = useState('');
    const [unseSteps, setUnseSteps] = useState<ICharacterUnseSteps>({ order: 0, message: [] });

    useEffect(() => {
        if (selectedCharacter) {
            setType(selectedCharacter.type);
            setName(selectedCharacter.name);
            setDesc(selectedCharacter.desc);
            setModelID(selectedCharacter.modelID || '');
            setPersona(selectedCharacter.persona || '');
            setHelloText(selectedCharacter.helloText);
            setVisible(selectedCharacter.visible);
            setTags((selectedCharacter.tags || []).map(x => x.id));
            setIsUnse(selectedCharacter.isUnse);
            setUnseName(selectedCharacter.unseName);

            const unseSteps = selectedCharacter.unseSteps;
            if (unseSteps && unseSteps.message) {
                setUnseSteps(unseSteps);
                // unseSteps 데이터를 unseRows로 변환
                const newUnseRows = unseSteps.message.map((msg, index) => ({
                    id: index + 1,
                    order: unseSteps.order,
                    message: msg
                }));
                setUnseRows(newUnseRows);
                setUnseOrder(unseSteps.order + 1); // unseOrder 초기값 설정
            } else {
                setUnseSteps({ order: 0, message: [] });
                setUnseRows([]);
                setUnseOrder(1); // unseOrder 초기값 설정
            }

            setSayHints(selectedCharacter.sayHints!.map((x: string, index: number) => ({
                id: index + 1,
                text: x,
            })));

            let cm = selectedCharacter.conditionMessages || ({} as ICharacterConditionMessages);
            cm.need_birthday ||= '';
            cm.need_birthday_2 ||= '';
            cm.nonpaying_persona ||= '';
            cm.nonpaying_text ||= '';
            cm.paying_completed ||= '';
            setConditionMessages(Object.keys(cm).map((k: string) => ({
                id: k,
                text: (cm as any)[k],
            })));
        } else {
            setType(CharacterType.SAJU_SINGLE);
            setName('');
            setDesc('');
            setModelID('');
            setPersona('');
            setHelloText('');
            setVisible(1);
            setTags([]);
            setSayHints([]);
            setConditionMessages([]);
            setIsUnse(false);
            setUnseName('');
            setUnseSteps({ order: 0, message: [] });
            setUnseRows([]);
            setUnseOrder(1); // unseOrder 초기값 설정
        }
    }, [selectedCharacter]);

    useEffect(() => {
        if (selectedCharacter) {
            setType(selectedCharacter.type);
            setName(selectedCharacter.name);
            setDesc(selectedCharacter.desc);
            setModelID(selectedCharacter.modelID || '');
            setPersona(selectedCharacter.persona || '');
            setHelloText(selectedCharacter.helloText);
            setVisible(selectedCharacter.visible);
            setTags((selectedCharacter.tags || []).map(x => x.id));
            setIsUnse(selectedCharacter.isUnse);
            setUnseName(selectedCharacter.unseName);

            const unseSteps = selectedCharacter.unseSteps;
            if (unseSteps && Object.keys(unseSteps).length > 0) {
                setUnseSteps(unseSteps);
                // unseSteps 데이터를 unseRows로 변환
                const newUnseRows = Object.entries(unseSteps).map(([key, value]) => ({
                    id: parseInt(key),
                    order: parseInt(key),
                    message: value
                }));
                setUnseRows(newUnseRows);
                setUnseOrder(newUnseRows.length + 1); // unseOrder 초기값 설정
            } else {
                setUnseSteps({ order: 0, message: [] });
                setUnseRows([]);
                setUnseOrder(1); // unseOrder 초기값 설정
            }

            setSayHints(selectedCharacter.sayHints!.map((x: string, index: number) => ({
                id: index + 1,
                text: x,
            })));

            let cm = selectedCharacter.conditionMessages || ({} as ICharacterConditionMessages);
            cm.need_birthday ||= '';
            cm.need_birthday_2 ||= '';
            cm.nonpaying_persona ||= '';
            cm.nonpaying_text ||= '';
            cm.paying_completed ||= '';
            setConditionMessages(Object.keys(cm).map((k: string) => ({
                id: k,
                text: (cm as any)[k],
            })));
        } else {
            setType(CharacterType.SAJU_SINGLE);
            setName('');
            setDesc('');
            setModelID('');
            setPersona('');
            setHelloText('');
            setVisible(1);
            setTags([]);
            setSayHints([]);
            setConditionMessages([]);
            setIsUnse(false);
            setUnseName('');
            setUnseSteps({ order: 0, message: [] });
            setUnseRows([]);
            setUnseOrder(1); // unseOrder 초기값 설정
        }
    }, [selectedCharacter]);

    function upload() {
        var form = new FormData();
        if (selectedCharacter)
            form.set('characterHashID', selectedCharacter.hashID);

        form.set('name', name);
        form.set('type', type?.toString() || '');
        if (imageFileInput.current?.files && imageFileInput.current?.files[0])
            form.set('image', imageFileInput.current?.files[0]);
        if (subImageFileInput.current?.files && subImageFileInput.current?.files[0])
            form.set('subImage', subImageFileInput.current?.files[0]);
        form.set('desc', desc);
        form.set('persona', persona);
        form.set('modelID', modelID);
        form.set('helloText', helloText);
        form.set('visible', visible?.toString() || '');
        form.set('tags', tags?.length > 0 ? tags.map(x => x?.toString() ?? '').join(',') : '');
        form.set('sayHints', sayHints.map(x => x.text).join('\n'));
        form.set('isUnse', isUnse + '');
        form.set('unseName', unseName);
        if (unseImageFileInput.current?.files && unseImageFileInput.current?.files[0])
            form.set('unseImage', unseImageFileInput.current?.files[0]);
        if (unseAdImageFileInput.current?.files && unseAdImageFileInput.current?.files[0])
            form.set('unseAdImage', unseAdImageFileInput.current?.files[0]);

        // Convert conditionMessages to object
        let conditionMessagesObj: { [key: string]: string } = {};
        conditionMessages.forEach(x => {
            conditionMessagesObj[x.id] = x.text;
        });
        form.set('conditionMessages', JSON.stringify(conditionMessagesObj));

        // unseSteps를 JSON으로 변환하여 form 데이터에 추가
        let unseStepsData: { [key: number]: string } = {};
        unseRows.forEach(row => {
            unseStepsData[row.order] = row.message;
        });
        form.set('unseSteps', JSON.stringify(unseStepsData));

        httpPostFormData('/api/characters/add', form).then((res) => {
            if (res.status >= 0) {
                setName('');
                setDesc('');
                setPersona('');
                setHelloText('');
                if (imageFileInput.current) imageFileInput.current.value = '';
                if (subImageFileInput.current) subImageFileInput.current.value = '';
                if (unseImageFileInput.current) unseImageFileInput.current.value = '';
                if (unseAdImageFileInput.current) unseAdImageFileInput.current.value = '';

                window.location.href = '/admin/characters';
            }
        });
    }

    function toggleTag(t: ITag) {
        if(tags.find(x => x == t.id)) {
            setTags(tags.filter(x => x != t.id));
        } else {
            setTags([...tags, t.id]);
        }
    }

    function addSayHint() {
        let newID = sayHints.reduce((prev, cur) => Math.max(prev, cur.id), 1) + 1;
        setSayHints([...sayHints, {
            id: newID,
            text: 'Hello?'
        }])
    }

    function removeSayHint() {
        setTimeout(() => {
            setSayHints(sayHints.filter(x => selectedSayHintIDs.indexOf(x.id) == -1));
        });
    }

    const [unseRows, setUnseRows] = useState<UnseRow[]>([]);
    const [unseOrder, setUnseOrder] = useState(1);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

    const unseColumns: GridColDef[] = [
        { field: 'order', headerName: 'Order', width: 50, editable: true },
        { field: 'message', headerName: 'Message', width: 400, editable: true }
    ];

    const addUnseRow = () => {
        const newRow = { id: unseRows.length + 1, order: unseOrder, message: "" };
        setUnseRows([...unseRows, newRow]);

        const newUnseSteps = { ...unseSteps, [unseOrder]: [] };
        setUnseSteps(newUnseSteps);

        setUnseOrder(unseOrder + 1);
    };

    const handleEditCellChangeCommitted = (params: GridCellEditCommitParams) => {
        const { id, field, value } = params;
        const processedValue = typeof value === 'string'
            ? value.split(',').map(item => item.trim())
            : Array.isArray(value) ? value : [];

        const newRows = unseRows.map(row => {
            if (row.id === Number(id)) {
                return { ...row, [field]: processedValue };
            }
            return row;
        });
        setUnseRows(newRows);

        const newUnseSteps = {
            ...unseSteps,
            [Number(id)]: processedValue
        };
        setUnseSteps(newUnseSteps);
    };

    const deleteUnseRow = () => {
        if (selectedRowId !== null) {
            const newRows = unseRows
                .filter(row => row.id !== selectedRowId)
                .map((row, index) => ({
                    ...row,
                    id: index + 1,
                    order: index + 1
                }));

            setUnseRows(newRows);

            const newUnseSteps = { ...unseSteps };
            delete newUnseSteps[selectedRowId];
            const updatedUnseSteps: { [key: number]: string[] } = {};
            Object.keys(newUnseSteps).forEach((key, index) => {
                updatedUnseSteps[index + 1] = newUnseSteps[Number(key)];
            });
            setUnseSteps(updatedUnseSteps as ICharacterUnseSteps);

            setSelectedRowId(null);
            setUnseOrder(newRows.length + 1);
        }
    };

    const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
        setSelectedRowId(newSelection.length > 0 ? Number(newSelection[0]) : null);
    };

    return (
        <Box sx={{flexGrow: 1}} textAlign="center">
            <form onSubmit={(e) => {
                e.preventDefault();
            }}
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 auto',
                      maxWidth: '100%',
                      borderRadius: '8px',
                      boxShadow: 'none',
                  }}
            >
                <div className="form-wrapper edit-character" style={{color: '#000000'}}>
                    <FormGroup>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '15px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Type')}</FormLabel>
                            <Select
                                value={type}
                                onChange={(e) => setType(e.target.value as number)}
                                style={{textAlign:"left"}}
                            >
                                <MenuItem value={CharacterType.SAJU_SINGLE}>{getString('Character_SajuSingle')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_SINGLE}>{getString('Character_SajuSingle')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_COUPLE}>{getString('Character_SajuCouple')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_FRIENDS}>{getString('Character_SajuFriends')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_TODAY}>{getString('Character_SajuToday')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_YEAR}>{getString('Character_SajuYear')}</MenuItem>
                                <MenuItem value={CharacterType.SAJU_MBTI_SINGLE}>{getString('Character_SajuMBTI_Single')}</MenuItem>

                                <MenuItem value={CharacterType.ASTROLOGY_SINGLE}>{getString('Character_AstrologySingle')}</MenuItem>
                                <MenuItem value={CharacterType.ASTROLOGY_COUPLE}>{getString('Character_AstrologyCouple')}</MenuItem>
                                <MenuItem value={CharacterType.ASTROLOGY_FRIENDS}>{getString('Character_AstrologyFriends')}</MenuItem>

                                <MenuItem value={CharacterType.TAROT}>{getString('Character_Tarot')}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl component="fieldset" style={{display: 'flex', marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'inline-block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>운세컨텐츠 여부</FormLabel>
                            <Checkbox
                                style={{ display: 'inline-flex', justifyContent: 'flex-start' }}
                                checked={isUnse}
                                onChange={(e) => setIsUnse(e.target.checked)}
                            />
                        </FormControl>

                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Name')}</FormLabel>
                            <TextField type="text" value={name} placeholder={getString('Character_Name_P')} onChange={(e: any) => setName(e.target.value)}/>
                        </FormControl>
                        {
                            isUnse &&
                            <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                                <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>운세 제목</FormLabel>
                                <TextField type="text" value={unseName} onChange={(e: any) => setUnseName(e.target.value)}/>
                            </FormControl>
                        }
                        <FormControl component="fieldset" style={{marginBottom: '40px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '20px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Cover')}</FormLabel>
                            <input
                                type="file"
                                accept="image/*"
                                ref={imageFileInput}
                            />
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '40px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '20px', fontSize: '13px', textAlign: 'left'}}>상세페이지 이미지</FormLabel>
                            <input
                                type="file"
                                accept="image/*"
                                ref={subImageFileInput}
                            />
                        </FormControl>

                        {
                            isUnse &&
                            <FormControl component="fieldset" style={{marginBottom: '40px'}}>
                                <FormLabel component="legend" style={{display: 'block', marginBottom: '20px', fontSize: '13px', textAlign: 'left'}}>운세 컨텐츠 이미지</FormLabel>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={unseImageFileInput}
                                />
                            </FormControl>
                        }

                        {
                            isUnse &&
                            <FormControl component="fieldset" style={{marginBottom: '40px'}}>
                                <FormLabel component="legend" style={{display: 'block', marginBottom: '20px', fontSize: '13px', textAlign: 'left'}}>캐릭터 광고 이미지</FormLabel>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={unseAdImageFileInput}
                                />
                            </FormControl>
                        }

                        <FormControl component="fieldset" style={{marginBottom: '30px', display: 'block'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '15px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Tags')}</FormLabel>
                            <div className="tag-box btn-box">
                                {allTags.map(x => (
                                    <button className={`goodking-sm-btn ${tags.find(t => t == x.id) && "contained" || ''}`} onClick={() => toggleTag(x)}>{x.name}</button>
                                ))}
                            </div>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Model')}</FormLabel>
                            <TextField type="text" value={modelID} placeholder={'LLM model name ex) gpt-4-1106-preview or claude-3-5-sonnet-20240620'} onChange={(e: any) => setModelID(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Introduction')}</FormLabel>
                            <TextField type="text" minRows={2} multiline value={desc}
                                       onChange={(e: any) => setDesc(e.target.value)}
                                       placeholder={getString('Character_Introduction_P')}
                            />
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Persona')}</FormLabel>
                            <TextField type="text" minRows={2} multiline value={persona}
                                       onChange={(e: any) => setPersona(e.target.value)}
                                       placeholder={getString('Character_Persona_P')}
                            />
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Hello')}</FormLabel>
                            <TextField type="text" minRows={2} multiline value={helloText}
                                       onChange={(e: any) => setHelloText(e.target.value)}
                                       placeholder={getString('Character_Hello_P')}
                            />
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '15px', fontSize: '13px', textAlign: 'left'}}>{getString('Character_Visible')}</FormLabel>
                            <Select
                                value={visible}
                                onChange={(e) => setVisible(e.target.value as number)}
                                style={{textAlign:"left"}}
                            >
                                <MenuItem value={0}>{getString('Visible_0')}</MenuItem>
                                <MenuItem value={2}>{getString('Visible_2')}</MenuItem>
                                <MenuItem value={1}>{getString('Visible_1')}</MenuItem>
                            </Select>
                        </FormControl>

                        {selectedCharacter && isUnse &&
                            <>
                                <FormGroup style={{ marginBottom: '30px', width: '100%', height: '400px' }}>
                                    <FormLabel component="legend">운세컨텐츠 플로우 작성</FormLabel>
                                    <DataGrid
                                        rows={unseRows}
                                        columns={unseColumns}
                                        checkboxSelection
                                        pageSize={5}
                                        disableSelectionOnClick
                                        onCellEditCommit={handleEditCellChangeCommitted}
                                        onSelectionModelChange={handleSelectionModelChange}
                                    />
                                </FormGroup>
                                <button type="button" onClick={addUnseRow}>운세플로우 추가</button>
                                <button type="button" style={{ margin: '10px 0px 50px' }} onClick={deleteUnseRow}>운세플로우 삭제</button>
                            </>
                        }

                        {selectedCharacter &&
                            <FormGroup style={{marginBottom: '30px', width: '100%', height: '400px'}}>
                                <FormLabel component="legend">{getString('Character_SayHints')}</FormLabel>
                                <DataGrid
                                    rows={sayHints}
                                    columns={SAYHINT_COLUMNS}
                                    pageSize={5}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(ids) => {
                                        selectSayHintIDs(ids.map(x => parseInt(x.toString())));
                                    }}
                                    onEditCellPropsChange={(e) => {
                                        let row = sayHints.find(x => x.id == e.id);
                                        if(row)
                                            row.text = e.props.value + '';
                                        setSayHints([...sayHints]);
                                    }}
                                />
                            </FormGroup>}

                        {selectedCharacter && <button type="button" onClick={addSayHint}>{getString('SayHint_Add')}</button>}
                        {selectedCharacter && <button type="button" style={{margin: '10px 0px'}} onClick={removeSayHint}>{getString('SayHint_Delete')}</button>}

                        {selectedCharacter &&
                            <FormGroup style={{marginBottom: '30px', width: '100%', height: '400px'}}>
                                <FormLabel component="legend">{getString('Character_ConditionMessages')}</FormLabel>
                                <DataGrid
                                    rows={conditionMessages}
                                    columns={CONDITION_MESSAGE_COLUMNS}
                                    pageSize={5}
                                    disableSelectionOnClick
                                    onEditCellPropsChange={(e) => {
                                        let row = conditionMessages.find(x => x.id == e.id);
                                        if(row)
                                            row.text = e.props.value + '';
                                        setConditionMessages([...conditionMessages]);
                                    }}
                                />
                            </FormGroup>
                        }

                        {selectedCharacter &&
                            <button className="goodking-btn" type="button" style={{marginBottom: '10px'}} onClick={upload}>{getString('Save')}</button>
                            ||
                            <button className="goodking-btn" type="button" style={{marginBottom: '10px'}} onClick={upload}>{getString('Add')}</button>
                        }

                        {selectedCharacter &&
                            <button className="goodking-btn" onClick={() => history.push(`/play/${selectedCharacter.hashID}`)}>{getString('Character_Test')}</button>
                        }
                    </FormGroup>
                </div>
            </form>
        </Box>
    )
}

const SAYHINT_COLUMNS = [
    {
        field: 'text',
        headerName: 'Text',
        width: 300,
        editable: true,
    },
];

const CONDITION_MESSAGE_COLUMNS = [
    {
        field: 'id',
        headerName: 'ID',
        width: 150,
        editable: false,
    },
    {
        field: 'text',
        headerName: 'Text',
        width: 300,
        editable: true,
    },
];

interface ISayHint {
    id: number
    text: string
}

interface IConditionMesage {
    id: string
    text: string
}

function AdminCharacterScreen() {

    const [selectedCharacter, selectCharacter] = useState<ICharacter | null>(null);
    const [tags, setTags] = useState<ITag []>([]);
    const params = useParams() as any;
    const characterHashID = params.characterHashID;

    function refresh() {
        if(characterHashID != 'add') {
            httpGet('/api/characters', {characterHashID: characterHashID}).then((res) => {
                selectCharacter(res.character);
            });
        }

        httpGet('/api/tags').then((res) => {
            setTags(res.tags);
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        let elementApp = document.getElementsByClassName('App')[0];
        elementApp.id = 'adminApp';

        return () => {
            elementApp.id = 'goodkingApp';
        }
    }, []);

    return (
        <div className="">
            <Box sx={{ flexGrow: 1 }} style={{width: '50em', margin: '1em auto', maxWidth: '100%'}}>
                <CharacterForm selectedCharacter={selectedCharacter} allTags={tags}/>
                {/* {selectedCharacter && <AttachmentInputForm/>} */}
            </Box>
        </div>
    )
}

export default AdminCharacterScreen