import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICategory, ICharacter, ITag, IUser, IUserAttachment, IUserImageGeneration } from '../../shared/types';
import { setMe } from '../../store/auth';
import AdminTabs, { AdminTab } from './AdminTabs';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageList: {
    width: 500,
    height: 450,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function GenerateForm({
  onRefresh,
}: {
  onRefresh: () => void
}) {

  const history = useHistory();
  const [prompt, setPrompt] = useState('');
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const [loading, setLoading] = useState(false);
  const allTags = ["masterpiece", "best quality", "highly detailed", "high quality", "high resolution", "8K", "anime", "japanese anime", "manga", "cartoon", "illustration", "render", "full shot", "full body", "wide angle", "bust shot", "close up", "waist shot", "fantasy", "realistic", "1girl", "1man"];
  const [tags, setTags] = useState(["masterpiece", "best quality", "highly detailed", "high quality", "high resolution"]);

  function submit() {
    //
    setLoading(true);

    let finalPrompt = tags.join(',') + ',' + prompt;
    if(finalPrompt.startsWith(','))
      finalPrompt = finalPrompt.substring(1);

    //
    httpPost('/api/images/generate', {
      prompt: finalPrompt,
    }).then((res) => {
      if(res.status >= 0) {
        setPrompt('');
        onRefresh();
      }
    }).finally(() => setLoading(false));
  }

  function toggleTag(t: string) {
    if(tags.find(x => x == t)) {
      setTags(tags.filter(x => x != t));
    } else {
      setTags([...tags, t]);
    }
  }

  return (
      <Box sx={{flexGrow: 1, paddingX: '15px'}} textAlign="center">
        <form onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '30em',
                margin: '0 auto',
                maxWidth: '100%',
                background: '#fff',
                padding: '60px 0px',
                borderRadius: '8px',
                boxShadow: 'none',
              }}
        >
          <div className="form-wrapper" style={{maxWidth: '480px'}}>
            <FormGroup>
              <FormControl component="fieldset" style={{marginBottom: '30px', display: 'block'}}>
                <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('Image_Prompt')}</FormLabel>
                <div className="tag-box btn-box">
                  {allTags.map(x => (
                    <Button className={`goodking-sm-btn ${tags.find(t => t == x) && "contained" || ""}`} onClick={() => toggleTag(x)}>{x}</Button>)
                  )}
                </div>
                <TextField type="text" style={{marginTop: '20px'}} value={prompt} placeholder={getString('Image_Prompt_P')} onChange={(e: any) => setPrompt(e.target.value)} fullWidth={true}/>
              </FormControl>
              <button className="goodking-btn" type="submit" disabled={loading}>{getString('Image_Generate')}</button>
            </FormGroup>
          </div>
        </form>
      </Box>
  )
}

function AdminImageListScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [selectedCharacter, selectCharacter] = useState<IUserImageGeneration | null>(null);
  const [images, setImages] = useState<IUserImageGeneration []>([]);
  const [tags, setTags] = useState<ITag []>([]);

  //
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(AdminTab.IMAGES);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    let elementApp = document.getElementsByClassName('App')[0];
    elementApp.id = 'adminApp';

    return () => {
      elementApp.id = 'goodkingApp';
    }
  }, []);

  function refresh() {
    selectCharacter(null);
    httpGet('/api/images/search').then((res) => {
      setImages(res.images);
    });
  }

  function deleteItem(hashID: string) {
    if(window.confirm('Are you sure?')) {
      httpPost('/api/characters/remove', {
        characterHashID: hashID
      }).then((res) => res.status >= 0 && refresh());
    }
  }
  
  return (
    <>

    <AdminTabs tab={tab} onChange={(t) => setTab(t)}/>

    <GenerateForm onRefresh={refresh}/>

    <Box sx={{flexGrow: 1, paddingX: '15px'}}>
      <TableContainer component={Paper}>

        <Table width="100%">
          <colgroup>
            <col width="50%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledTableCell>{getString('ImageList_Name')}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images.map((a) => (
              <StyledTableRow key={a.hashID} style={{backgroundColor: selectedCharacter?.hashID == a.hashID ? 'rgb(231 152 82 / 23%)' : undefined}}>
                <StyledTableCell>
                  {a.name}
                </StyledTableCell>
                <StyledTableCell>
                  <a href={a.imageURL} target="_blank">
                    <img src={a.imageURL} width="128" alt="사주천왕 캐릭터"/>
                  </a>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Box style={{display: 'flex', flexDirection: 'column'}}>
                    <Link to="#" onClick={(e) => deleteItem(a.hashID)}>
                      <Button style={{display: 'inline-block', width: "100%", boxShadow: 'none', backgroundColor: '#f50057'}} variant="contained" color="primary">삭제</Button>
                    </Link>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </>
  )
}
 
 export default AdminImageListScreen
 