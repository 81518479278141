import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {
    CodeResponse,
    CredentialResponse,
    TokenResponse,
    useGoogleLogin,
    useGoogleOneTapLogin
} from '@react-oauth/google';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '@src/store';
import {IUser} from '@src/shared/types';
import {setMe} from '../../store/auth';
import {StatusCode} from '@src/shared/statuscode';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    profile: {
        backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        width: '50%',
        height: '10em',
        maxWidth: '100%',
    }
}));


function RefundScreen() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [secret, setSecret] = useState('');
    const dispatch = useDispatch();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

    const classes = useStyles();
    const [language, setLanguage] = useState(true);
    return (
        <>
            <div className="policy-tabs">
                <a href="/terms">이용약관</a>
                <a href="/privacy">개인정보 보호정책</a>
                <a className="selected" href="/refund">환불정책</a>
            </div>
            <Box sx={{flexGrow: 1}} style={{fontSize: '14px', fontWeight:'400', lineHeight: '21px', color: 'rgba(0,0,0,0,85)', padding: '24px'}}>
                {/*<button type="submit" style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    color: '#ED503C',
                    border: 'none',
                    height: '42px',
                    fontWeight: 'bold',
                    marginLeft: 'auto',
                    float: 'right',
                    cursor: 'pointer'
                }}
                        onClick={() => setLanguage(!language)}
                >
                    {language ? "En" : "Ko"}
                </button>*/}
                {language ?
                    <>
                        <p style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginBottom: '16px',
                            padding: '10px',
                            border: '1px solid #E2E5EB',
                            borderRadius: '5px'
                        }}>시행일자 : 2024-03-05</p>
                        {/*<h3>사주천왕 환불 정책</h3>*/}
                        <br/>
                        {/*<p>마지막 업데이트: 03월 04일, 2024</p>*/}
                        <br/>
                        <p>1. 회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가 안내하는 정책 및 방법에 따라 진행됩니다.</p>
                        <br/>
                        <p>2. 이용계약의 신청 후 아래의 경우  환불이 가능합니다.</p>
                        <p>가. 회사로부터의 상담이 제공되지 않은 경우</p>
                        <p>나. 회사의 귀책사유로 결제 오류가 발생한 경우</p>
                        <p>다. 회사의 귀책사유로 서비스가 중단되는 경우</p>
                        <p>라. 상품 구매일로부터 7일 이내 환불요청을 한 경우</p>
                        <br/>
                        <p>3. 회사는 각 호의 방식으로 환불을 진행합니다.</p>
                        <p>가. 상품 구매일로부터 7일 이내에는 환불요청을 할 수 있습니다. 단, 환불요청 시 회원은 구매한 금화를 사용하지 않은 상태여야 합니다.</p>
                        <p>나. 상품 구매일로부터 7일 이내에 환불을 요청한 사용자는 전액 환불처리 되며, 상품 구매 이후 금화 사용 또는 상품 구매일로부터 7일 이후부터는 환불 요청에 대해서는 환불이 불가합니다</p>
                        <p>다. 환불은 반드시 결제가 되었던 수단으로만 진행 가능합니다</p>
                        <br/>
                        <b>문의하기</b>
                        <p>환불정책에 대해 궁금한 점이 있거나 환불요청이 필요한 경우 이메일(contact@flabs.ai)로 문의하시기 바랍니다.</p>
                    </>
                    :
                    <>
                        <p style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginBottom: '16px',
                            padding: '10px',
                            border: '1px solid #E2E5EB',
                            borderRadius: '5px'
                        }}>Effective Date : 2024-03-05</p>
                        <h3>goodking.ai Refund Policy</h3>
                        <br/>
                        <p>Last Updated: March 04, 2024</p>
                        <br/>
                        <p>1. Members may request a refund from the Company.Refunds will be issued in accordance with the policies and methods outlined by the Company.</p>
                        <br/>
                        <p>2. Refunds are available in the following cases after the application of the Service Agreement.</p>
                        <p>A. No counseling from the company is provided</p>
                        <p>B. The payment error is due to the company's fault</p>
                        <p>C. If the service is interrupted due to reasons attributable to the Company</p>
                        <p>D. If you requested a refund within 7 days of the purchase date</p>
                        <br/>
                        <p>3. the Company will issue refunds in each of the following ways</p>
                        <p>A. You can request a refund via the email below within 7 days from the date of purchase. However, the member must not have used the purchased gold coins when requesting a refund.</p>
                        <p>B. Users who request a refund within 7 days from the date of purchase will receive a full refund, and refunds will not be issued for the use of gold coins after purchase or for refund requests made after 7 days from the date of purchase.</p>
                        <p>C. Refunds can only be issued to the original method of payment</p>
                    </>
                }


            </Box>
        </>
    )
}

export default RefundScreen
 