import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {
    CodeResponse,
    CredentialResponse,
    TokenResponse,
    useGoogleLogin,
    useGoogleOneTapLogin
} from '@react-oauth/google';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '@src/store';
import {IUser} from '@src/shared/types';
import {setMe} from '../../store/auth';
import {StatusCode} from '@src/shared/statuscode';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    profile: {
        backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        width: '50%',
        height: '10em',
        maxWidth: '100%',
    }
}));


function TermsScreen() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [secret, setSecret] = useState('');
    const dispatch = useDispatch();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
    const classes = useStyles();
    const [language, setLanguage] = useState(true);
    return (
        <>
            <div className="policy-tabs">
                <a className="selected" href="/terms">이용약관</a>
                <a href="/privacy">개인정보 보호정책</a>
                <a href="/refund">환불정책</a>
            </div>
            <Box sx={{flexGrow: 1}} textAlign="left" style={{fontSize: '14px', fontWeight:'400', lineHeight: '21px', color: 'rgba(0,0,0,0,85)', padding: '24px'}}>
                {/*<button type="submit" style={{
                    display: 'inline-flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    color: '#ED503C',
                    border: 'none',
                    height: '42px',
                    fontWeight: 'bold',
                    marginLeft: 'auto',
                    float: 'right',
                    cursor: 'pointer'
                }}
                        onClick={() => setLanguage(!language)}
                >
                    {language ? "En" : "Ko"}
                </button>*/}
                { language ?
                    <>
                        <p style={{display: 'inline-block', width: 'auto', marginBottom: '16px', padding: '10px', border: '1px solid #E2E5EB', borderRadius: '5px'}}>시행일자 : 2024-03-05</p>
                        <br/>
                        <br/>
                        <p>사주천왕은 (주)탱고랜드의 소프트웨어를 통해 AI 상담 서비스를 제공하며, AI 상담 서비스를 제공하는 주체는 (주)탱고랜드로만 한정됩니다</p>
                        <br/>
                        <p>본 서비스 약관은 귀하와 (주)탱고랜드 사이에 체결된 계약입니다. 이는 위 서비스에서 제공하는 웹사이트(이하 "웹사이트") 및 사주천왕 모바일 애플리케이션(이하 "앱")에 적용됩니다. 본 약관에서 당사는 때때로 (주)탱고랜드를 "사주천왕", "회사", "당사" 또는 "우리"로 지칭할 것입니다. 당사는 당사 웹사이트 및 앱과 이를 통해 제공되는 모든 콘텐츠, 도구, 특징 및 기능을 "서비스"로 지칭합니다.</p>
                        <br/>
                        <p>본 약관은 귀하의 서비스 액세스 및 사용에 적용됩니다. 본 약관에는 회원님의 법적 권리에 관한 중요한 정보가 포함되어 있으므로 주의 깊게 읽어주시기 바랍니다. 서비스에 액세스하거나 서비스를 이용함으로써 귀하는 본 약관에 동의하는 것입니다. 본 약관을 이해하지 못하거나 동의하지 않는 경우 서비스를 이용하지 마시기 바랍니다.</p>
                        <br/>
                        <p>본 약관에서 "귀하"는 서비스 사용자인 귀하를 의미합니다. 귀하가 회사 또는 기타 법인을 대신하여 서비스를 사용하는 경우 "귀하"에는 귀하와 해당 법인이 포함되며, 귀하는 (a) 귀하가 해당 법인을 본 약관에 구속할 권한이 있는 해당 법인의 공인 대리인이며, (b)귀하가 해당 법인을 대신하여 본 약관에 동의함을 진술하고 보증합니다.</p>
                        <br/>
                        <p>참고: 본 약관에는 중재 조항 및 집단 소송 포기가 포함되어 있습니다. 본 약관에 동의함으로써 귀하는 당사와의 모든 분쟁을 구속력 있는 개별 중재를 통해 해결하는 데 동의하는 것입니다. 즉, 귀하는 해당 분쟁을 판사 또는 배심원이 결정하도록 할 권리를 포기하고 집단 소송, 집단 중재 또는 대표 소송에 참여할 권리를 포기하는 것입니다. 귀하는 아래에 설명된 대로 중재를 거부할 권리가 있습니다.</p>
                        <br/>

                        <b>서비스 이용</b>
                        <p>귀하의 등록 의무. 서비스 이용을 위해 등록하는 경우, 귀하는 자신에 대한 정확하고 완전한 정보를 제공하는 데 동의하는 것입니다.</p>

                        <p>회원 계정, 비밀번호 및 보안. 귀하는 귀하의 비밀번호와 계정의 기밀을 유지하고 귀하의 비밀번호 또는 계정으로 발생하는 모든 활동에 대한 책임이 있습니다. 귀하는 비밀번호 또는 계정의 무단 사용 또는 기타 보안 위반을 발견한 경우 즉시 사주천왕에 통보하고 서비스에 액세스할 때 각 세션이 끝날 때마다 계정에서 종료하는 데 동의합니다. 사주천왕은 귀하가 본 조항을 준수하지 않아 발생하는 어떠한 손실이나 손해에 대해서도 책임을 지지 않습니다.</p>
                        <br/>

                        <b>사용 및 저장에 관한 일반 관행</b>
                        <p>귀하는 사주천왕이 서비스 사용과 관련하여 일반적인 관행과 제한을 설정할 수 있음을 인정합니다. 여기에는 서비스에서 데이터 또는 기타 콘텐츠를 보관하는 최대 기간과 귀하를 대신하여 사주천왕의 서버에 할당되는 최대 저장 공간이 포함될 수 있으며 이에 국한되지 않습니다. 귀하는 사주천왕이 서비스에 유지되거나 업로드된 데이터 또는 기타 콘텐츠의 삭제 또는 저장 실패에 대해 어떠한 책임이나 의무도 지지 않는다는 데 동의합니다.</p>
                        <p>귀하는 사주천왕이 장기간 비활성 상태인 계정을 해지할 권리를 보유함을 인정합니다. 또한 귀하는 사주천왕이 통지 여부에 관계없이 단독 재량으로 언제든지 이러한 일반적인 관행과 제한을 변경할 권리가 있음을 인정합니다.</p>
                        <br/>

                        <b>이메일 알림</b>
                        <p>귀하는 귀하가 당사에 제공한 이메일 주소로 당사의 알림을 전자적으로 수신하는데 동의합니다. 이러한 알림은 귀하의 계정, 서비스 변경 또는 플랫폼과 관련된 기타 업데이트 또 는 마케팅에 관한 것일 수 있습니다.</p>
                        <br/>

                        <b>포인트 구매</b>
                        <p>충전된 금화의 이용기간은 결제 시점으로부터 1년, 환불 가능기간은 결제 시점으로부터 7일 이내로 제한됩니다.</p>
                        <br/>
                        <br/>

                        <b>사용 조건</b>
                        <p>사용자 행동. 귀하는 여기에 포함된 커뮤니티 가이드라인을 준수하는데 동의하는 것 외에도 서비스 이용시 다음 조건을 준수하는 데 동의합니다.</p>
                        <p>귀하가 서비스에 제출하는 모든 내용에 대한 책임은 전적으로 귀하에게 있습니다. ("귀하가 제출하는 콘텐츠" 및 이와 유사한 용어는 텍스트, 이미지, 사운드, 동영상, 그래픽, 정보 또는 기타 데이터를 포함하되 이에 국한되지 않고 귀하가 게시, 전송 또는 공유하고자 하는 모든 것을 의미 합니다).</p>
                        <p>귀하는 다음과 같은 콘텐츠를 제출하지 않을 것에 동의합니다:</p>
                        <br/>

                        <p>(i) 당사자의 지적 재산권 또는 기타 소유권을 침해하는 경우</p>
                        <p>(ii) 컴퓨터 소프트웨어 또는 하드웨어 또는 통신 장비의 기능을 방해, 파괴 또는 제한하도록 설계 된 소프트웨어 바이러스 또는 컴퓨터 코드, 파일 또는 프로그램이 포함되어 있습니다;</p>
                        <p>(iii) 다른 사람에게 개인정보 또는 보안 위험을 초래하는 경우;</p>
                        <p>(iv) 원치 않거나 승인되지 않은 광고, 판촉물, 상업적 활동 및/또는 판매, '정크 메일', '스팸', '연쇄 편지', '피라미드 조직', '콘테스트', '경품 행사' 또는 기타 형태의 권유를 구성합니다;</p>
                        <p>(v) 위협, 학대, 괴롭힘, 불법행위, 괴롭힘 또는 지나치게 폭력적인 행위;</p>
                        <p>(vi) 타인을 해칠 목적으로 명예를 훼손하거나 비방하거나 명백히 허위인 경우;</p>
                        <p>(vii) 인종, 민족, 성별, 종교, 나이 또는 기타 사회적, 신체적 또는 정신적 특성에 근거하여 차별이 나 폭력을 비하하거나 조장하는 증오 발언에 해당합니다;</p>
                        <p>(viii) 외설적이거나 음란한 내용입니다; 이는 성희롱에 해당합니다;</p>
                        <p>(ix) 아동 성 착취 또는 학대 이미지 또는 콘텐츠 공유, 그루밍 또는 성적 갈취를 포함하여 미성년 자에 대한 성 착취 또는 학대에 해당합니다;</p>
                        <p>(xi) 자해, 자살, 섭식 장애 등 자해를 미화하는 내용입니다</p>
                        <p>(xii) 테러 또는 폭력적 극단주의를 조장합니다</p>
                        <p>(xiii) 범죄 행위를 조장하거나 조장하는 경우</p>
                        <p>(xiv) 불법 약물을 구매하거나 판매하려고 하는 경우</p>
                        <p>(xv) 개인의 법적 권리에 부정적인 영향을 미치거나 구속력 있고 집행 가능한 의무를 발생시키는 완전 자동화된 의사 결정을 촉진하는 행위</p>
                        <p>(xvi) 의료, 법률, 재정 또는 세무 자문</p>
                        <p>(xvii) 서비스 또는 서비스에 연결된 서버 또는 네트워크를 방해하거나 방해하는 행위</p>
                        <p>(xviii)  타인의 이름, 초상 또는 초상을 (a) 허가 없이 사용하거나 (b) 비상업적 패러디 또는 공개 논평과 같이 허용된 맥락을 벗어나서 사용함으로써 타인의 퍼블리시티권을 방해하거나 도용하는 행위</p>

                        <p>(xix) 사주천왕의 단독 판단에 따라 불쾌감을 주거나 다른 사람이 서비스를 사용하거나 즐기는 것을 제한 또는 억제하거나 [사주천왕](http://goodking.ai/) 또는 그 사용자를 위험이나 책임에 노출시킬 수 있는 경우</p>
                        <br/>

                        <p>또한 귀하는 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않을 것에 동의합니다</p>
                        <p>(i) 서비스에 연결된 네트워크의 요구사항, 절차, 정책 또는 규정을 위반하는 경우</p>
                        <p>(ii) 관련 법률 또는 규정을 위반하는 경우</p>
                        <p>(iii) 다른 사람 또는 단체를 사칭하거나 귀하가 다른 사람 또는 단체와 소속되어 있다고 허위 진 술하는 행위</p>
                        <p>(iv) 18세 미만의 사람에게 개인 정보를 요청하는 행위</p>
                        <p>(v) 원치 않는 이메일 또는 기타 원치 않는 커뮤니케이션을 전송할 목적으로 전자적 또는 기타 수 단을 통해 서비스에서 다른 사용자의 이메일 주소 또는 기타 연락처 정보를 수집하거나 수집하는 행위</p>
                        <p>(vi) 서비스를 통해 의도적으로 이용 가능하거나 제공되지 않은 수단을 통해 정보를 획득하거나 획득을 시도하는 행위</p>
                        <p>(vii) 서비스의 일부를 임대, 대여, 판매 또는 재라이선스하는 행위</p>
                        <p>(viii) 서비스 또는 서비스와 관련된 기술을 보호하기 위해 고안된 기술적 조치를 회피하려고 시도 하는 행위</p>
                        <p>(ix) 서비스 소스 코드의 전체 또는 일부를 리버스 엔지니어링, 분해, 디컴파일, 디코딩, 개조 또는 기타 방식으로 파생하거나 액세스하려는 시도(단, 서비스 내 코드의 일부가 오픈 소스로 공개되고 해당 코드에 적용되는 오픈 소스 라이선스에서 리버스 엔지니어링, 복사 또는 기타 수정을 명시 적으로 허용하는 경우 제외)</p>
                        <br/>

                        <p>사주천왕이 음성 또는 오디오 기능을 지원하기로 선택한 범위 내에서 귀하는 서비스 사용과 관련하여 다음 중 어느 것도 하지 않을 것에 동의합니다</p>
                        <p>(i) 제3자(유명인을 포함하되 이에 국한되지 않음)의 음성 녹음을 당사자의 동의 없이 제출하는 행위</p>
                        <p>(ii) 정치적 허위 정보를 생성하거나, 사기 또는 사기를 저지르거나, 제3자의 평판을 훼손하거나, 기타 유해한 행위에 해당하는 것을 포함하되 이에 국한되지 않는 모든 종류의 '딥페이크' 또는 사칭을 위해 사주천왕 음성 기능을 사용하는 행위</p>
                        <p>당사는 단독 재량에 따라 위의 조항을 위반했다고 판단되는 모든 사람을 조사하고 적절한 조치를 취할 권리를 보유합니다. 이러한 조치에는 서비스에서 콘텐츠 삭제, 계정 일시 정지 또는 해지, 법 집행 기관에 신고 등이 포함될 수 있으며 이에 국한되지 않습니다.</p>
                        <br/>

                        <b>지적 재산권</b>
                        <p>귀하가 제출하는 콘텐츠. 귀하가 서비스에 콘텐츠를 제출할 때 귀하는 해당 콘텐츠에 대한 모든 권리, 소유권 및 이익(모든 저작권 및 퍼블리시티권을 포함하되 이에 국한되지 않음)을 소유하거나 본 약관에서 고려하는 용도로 서비스에 콘텐츠를 제출하기 위해 필요한 모든 허가, 승인 및 승인을 받았음을 진술하고 보증합니다.</p>

                        <p>귀하가 그러한 콘텐츠를 제출할 때, 귀하는 해당 콘텐츠에 대한 모든 소유권을 보유합니다. 귀하는 법률에 따라 허용되는 최대 한도 내에서 사주천왕에 서비스를 운영, 개선 및 제공하는 것을 포함하여 현재 알려져 있거나 향후 개발되는 모든 형태, 매체 또는 기술로 콘텐츠를 복사, 표시, 업로드, 수행, 배포, 전송, 제공, 저장, 수정, 악용, 상업화 및 기타 [사주천왕](http://goodking.ai/) 관련 목적으로 사용 할 수 있는 비독점적, 전 세계, 로열티 무료, 완납, 양도 가능, 재라이센스 가능, 영구, 취소 불가능한 라이선스를 부여합니다. 귀하는 이러한 권리 및 라이선스에 사주천왕이 당사와 계약 관계에 있는 다른 사람에게 콘텐츠를 제공하고 이러한 권리를 전달할 수 있는 권리가 포함되며, 당사가 그러한 접근이 필요하거나 적절하다고 판단하는 경우 제3자에게 콘텐츠에 대한 접근을 허용하거 나 공개할 수 있는 권리가 포함된다는 데 동의합니다.</p>
                        <p>당사는 의무사항은 아니지만, 서비스 제공 및 개발을 위해 또는 콘텐츠가 본 약관 또는 관련 법률을 위반한다고 판단되는 경우 등 어떠한 이유로든 언제든지 귀하의 콘텐츠에 액세스, 검토, 선별, 편집, 수정 및 삭제할 수 있습니다.</p>
                        <p>서비스 콘텐츠, 소프트웨어 및 상표. 귀하는 서비스에 저작권, 특허, 상표, 영업비밀 또는 기타 독점적 권리 및 법률에 의해 보호되는 콘텐츠 또는 기능이 포함될 수 있음을 인정하고 이에 동의합니다. 귀하는 서비스 이용과 관련하여 데이터 마이닝, 로봇, 스크래핑 또는 이와 유사한 자동화된 데이터 수집 또는 추출 방법에 관여하거나 이를 사용하지 않습니다. 귀하가 당사에 의해 서비스 액세스가 차단된 경우(귀하의 IP 주소 차단 포함), 귀하는 그러한 차단을 우회하기 위한 어떠한 조치(예: 귀하의 IP 주소 마스킹 또는 프록시 IP 주소 사용)도 취하지 않을 것에 동의합니다. 본 약관에서 구체적으로 승인된 것 이외의 서비스 또는 서비스 상의 콘텐츠 사용은 엄격히 금지됩니 다. 여기에 명시적으로 부여되지 않은 모든 권리는 당사가 보유합니다.</p>
                        <p>사주천왕의 이름과 로고는 사주천왕의 상표입니다(총칭하여 "사주천왕 상표"). 서비스를 통해 사용 및 표시되는 기타 상표는 사주천왕을 보증하거나 제휴 또는 연결되지 않을 수 있는 각 소유자의 상표일 수 있습니다. 본 약관 또는 서비스의 어떠한 내용도 각 경우에 대한 당사의 사전 서면허가 없이 사주천왕 상표를 사용할 수 있는 라이선스 또는 권리를 부여하는 것으로 해석되어서는 안됩니다. 사주천왕 상표의 사용으로 인해 발생하는 모든 영업권은 당사의 독점적 이익으로 귀속됩니다.</p>
                        <p>귀하는 당사가 단독 재량으로 서비스를 통해 제공되는 콘텐츠(콘텐츠, 캐릭터 포함)를 거부하거나 삭제할 권리(의무는 아님)가 있음을 인정합니다. 전술한 내용을 제한하지 않고, 당사는 본 약관을 위반하거나 기타 당사가 불쾌하다고 판단하는 콘텐츠를 당사의 단독 재량에 따라 삭제 할 권리가 있습니다.</p>
                        <p>귀하는 귀하가 사주천왕에 제공한 서비스에 관한 질문, 의견, 제안, 아이디어, 피드백 또는 기타 정보가 기밀이 아니며, 당사가 귀하에게 승인이나 보상 없이 어떠한 목적으로든 이를 사용하고 배포할 수 있음을 인정하고 이에 동의합니다.</p>
                        <p>귀하는 당사가 관련 법률 및 개인정보 처리방침에 따라 콘텐츠 및 메타데이터를 보존할 수 있음을 인정하고 이에 동의합니다. 귀하는 법률에 의해 요구되거나 그러한 보존 또는 공개가 합리적으로 필요하다는 선의의 믿음으로 콘텐츠 및 메타데이터를 공개할 수 있음을 인정하고 이에 동의합니다: (a) 법적 절차, 관련 법률 또는 정부 요청을 준수하기 위해, (b) 본 약관을 집행하기 위해, (c) 콘텐츠가 제3자의 권리를 침해한다는 주장에 대응하기 위해, 또는 (d) [사주천왕](http://goodking.ai/) 사용자 및 대중의 권리, 재산 또는 개인 안전을 보호하기 위해.</p>
                        <br/>

                        <b>저작권 침해 신고</b>
                        <p>사주천왕은 타인의 지적 재산을 존중하며 사용자들에게도 동일한 조치를 취하도록 요청합니다. 귀하의 저작물이 저작권 침해에 해당하는 방식으로 복사되거나 귀하의 지적 재산권이 침해된다고 생각되는 경우, 아래에 명시된 절차에 따라 사주천왕에 귀하의 침해 주장을 통지해야 합니다.</p>
                        <p>저작권 또는 기타 지적 재산권 소유자를 대리할 권한이 있는 사람의 전자 서명 또는 실제 서명.</p>
                        <p>저작권이 침해된다고 주장하는 저작물 또는 기타 지적 재산에 대한 설명.</p>
                        <p>귀하가 저작권을 침해한다고 주장하는 자료가 서비스에서 어느 위치에 있는지에 대한 설명(서비스에서 해당 자료를 찾을 수 있을 정도로 상세히 포함)</p>
                        <p>주소, 전화번호, 이메일 주소</p>
                        <p>분쟁 중인 사용이 저작권 또는 지적 재산권 소유자, 그 대리인 또는 법률에 의해 승인되지 않았다는 진술서</p>
                        <p>위증 시 처벌을 받는다는 전제 하에 귀하가 통지의 정보가 정확하며 귀하가 저작권 또는 지적 재산권 소유자이거나 저작권 또는 지적 재산권 소유자를 대신하여 행동할 권한이 있다는 진술서.</p>
                        <br/>

                        <b>반대 통지</b>
                        <p>콘텐츠가 삭제되거나 비활성화된다고 생각하지만 콘텐츠가 저작권을 침해하지 않는다고 생각하는 경우, 다음 정보가 포함된 서면 이의제기 통지를 당사에 보낼 수 있습니다</p>
                        <br/>

                        <b>실제 서명 또는 전자 서명</b>
                        <p>삭제되거나 액세스가 비활성화된 콘텐츠의 식별 및 콘텐츠가 삭제되거나 비활성화되기 전에 표시된 위치.</p>
                        <p>실수 또는 콘텐츠 오인의 결과로 콘텐츠가 삭제 또는 비활성화되었다는 진술서.</p>
                        <p>귀하의 이름, 주소, 전화번호 및 이메일 주소.</p>
                        <p>이의제기 통지를 받은 경우, 당사는 이의제기 당사자가 콘텐츠와 관련된 법원 소송을 제기했다고 밝히지 않는 한 영업일 기준 14일 이내에 삭제/차단된 콘텐츠를 복원할 것임을 알리는 이의제기</p>
                        <p>통지 사본을 원래 이의제기 당사자에게 발송합니다.</p>
                        <p>반복적 침해자 정책. 기타 관련 법률에 따라 사주천왕은 적절한 상황에서 당사의 단독 재량에 따라 반복적인 침해자로 간주되는 사용자를 해지하는 정책을 채택했습니다. 또한 사주천왕은 반복적인 침해 여부에 관계없이 단독 재량으로 타인의 지적 재산권을 침해하는 사용 자의 서비스 액세스를 제한하거나 등록을 해지할 수 있습니다.</p>
                        <br/>

                        <b>타사 웹사이트 및 서비스</b>
                        <p>서비스 또는 제3자는 인터넷의 다른 사이트 및 리소스 또는 제3자 애플리케이션에 대한 링크 또는 기타 액세스를 제공할 수 있습니다. 사주천왕은 이러한 사이트, 리소스 또는 애플리케이션을 통제할 수 없으며 사주천왕은 이에 대해 책임지지 않으며 보증하지 않습니다. 귀하는 그러한 사이트, 리소스 또는 애플리케이션에서 또는 이를 통해 제공되는 콘텐츠, 이벤트, 상품 또는 서비스 에 대한 의존으로 인해 발생하는 모든 손해 또는 손실에 대해 사주천왕이 직간접적으로 책임을 지지 않음을 인정하고 이에 동의합니다. 귀하가 서비스를 사용하는 동안 발견한 제 3자와의 거래는 귀하와 제 3자 간 거래이며, 귀하는 사주천왕이 귀하가 그러한 제 3자에 대해 가질 수 있는 손실이나 청구에 대해 책임을 지지 않는다는데 동의합니다.</p>
                        <br/>

                        <b>배상 및 면책</b>
                        <p>귀하는 귀하의 서비스 사용으로 인해 또는 이와 관련하여 발생하는 모든 종류의 손실, 손해 및 비용으로부터 사주천왕과 그 계열사 및 그 임원, 직원, 이사 및 대리인을 면제, 면책 및 보호하는데 동의합니다. 전술한 내용을 제한하지 않고, 위에 설명된 면책 및 면책에는 귀하의 서비스 사용으로 인해 또는 이와 관련하여 발생하는 합리적인 변호사 수임료, 권리, 청구, 모든 종류의 소송 및 상해(사망 포함)가 포함됩니다.</p>
                        <p>일반 면책은 채권자 또는 면책 당사자가 면책 실행 당시 자신에게 유리한 청구권이 존재한다는 사실을 알지 못했거나 의심하고 있으며, 이를 알았다면 채무자 또는 면책 당사자와의 합의에 중대한 영향을 미쳤을 청구권에는 적용되지 않습니다.</p>
                        <p>귀하가 다른 관할권의 거주자인 경우, 귀하는 이와 유사한 법령이나 원칙을 포기합니다.</p>
                        <br/>

                        <b>보증 면책 조항</b>
                        <p>서비스 이용에 따른 위험은 전적으로 귀하의 책임입니다. 본 사이트는 "있는 그대로" 및 "사용 가능한 상태"로 제공됩니다. 사주천왕은 상품성, 특정 목적에의 적합성, 소유권 및 비침해에 대한 묵시적 보증을 포함하되 이에 국한되지 않는 모든 종류의 명시적, 묵시적 또는 법적 보증을 명시 적으로 부인합니다. 사주천왕은 (i) 서비스가 귀하의 요구 사항을 충족할 것, (ii) 서비스가 중단되지 않고, 적시에 제공되거나, 안전하거나, 오류가 없을 것, (iii) 서비스 사용으로 얻을 수 있는 결과가 정확하거나 신뢰할 수 있을 것임을 보증하지 않습니다.</p>
                        <br/>

                        <b>책임의 제한</b>
                        <p>귀하는 사주천왕이 영업권 사용, 데이터 또는 기타 무형적 손실에 대한 손해를 포함하되 이에 국한되지 않는 간접적, 부수적, 특별, 결과적 또는 예시적 손해 또는 이익 손실에 대한 손해에 대해 책임을지지 않음을 이해하고 동의합니다. (사주천왕이 그러한 손해의 가능성을 사전에 통지받은 경우에도 포함), 계약, 불법행위, 과실, 무과실 책임 또는 기타에 근거하여 발생하는 손해는 다음과 같습니다: (i) 서비스의 사용 또는 사용 불능, (ii) 귀하의 전송 또는 데이터 에 대한 무단 접근 또는 변경 (iii) 서비스에 대한 제3자(사용자 포함)의 진술 또는 행위 (iv) 서비스와 관련된 기타 모든 사안으로 인한 것. 어떠한 경우에도 모든 손해, 손실 또는 소송 원인에 대한 사주천왕의 귀하에 대한 총 책임은 다음 금액을 초과하지 않습니다: (i) 귀하의 서비스 사용과 관련하여 귀하가 사주천왕에 지불한 금액(있는 경우).</p>
                        <p>사이트의 일부 또는 본 이용약관에 불만이 있는 경우, 귀하의 유일하고 배타적인 구제 책은 서비스 이용을 중단하는 것입니다.</p>
                        <br/>

                        <b>구속력 있는 중재를 통한 분쟁 해결</b>
                        <p>이 섹션은 회원님의 권리에 영향을 미치므로 주의 깊게 읽어주세요.</p>
                        <p>중재에 대한 동의. 본 약관의 구속력 있는 중재를 통한 분쟁 해결 섹션은 본 약관에서 "중재 계약"으로 지칭합니다. 귀하는 본 약관(위반 혐의 포함), 웹사이트 또는 서비스, 당사 간의 관계 또는 거래의 모든 측면으로 인해 발생했거나 발생할 수 있는 모든 분쟁 또는 청구가 본 중재 계약의 조건에 따라 법원이 아닌 최종적이고 구속력 있는 중재를 통해서만 해결된다는 데 동의합니다(단, 귀하의 청구가 자격이 있는 경우 소액 청구 법원에 개별 청구를 제기할 수 있다는 점을 제외합니다). 또한, 본 중재 계약은 귀하가 지역 기관에 문제를 제기하는 것을 배제하지 않으며, 해당 기관은 법이 허용하는 경우 귀하를 대신하여 당사를 상대로 구제를 요청할 수 있습니다. 귀하는 본 약관을 체결함으로써 귀하와 사주천왕이 각각 재판을 받거나 소송에 참여할 권리를 포기한다는데 동의합니다.</p>
                        <br/>

                        <b>집단 및 대표 소송 및 비개인적 구제의 금지</b>
                        <p>귀하와 사주천왕은 각자가 개별적으로만 상대방에 대한 청구를 제기할 수 있으며, 집단 또는 대표 소송 또는 절차에서 원고 또는 집단 구성원으로서 청구를 제기할 수 없다는데 동의합니다. 귀하와 사주천왕이 달리 동의하지 않는 한, 중재자는 한 명 이상의 개인 또는 당사자의 청구를 통합하거나 합류할 수 없으며, 어떠한 형태의 통합, 대표 또는 집단 소송 절차도 주재할 수 없습니다. 또한, 중재자는 구제를 요청하는 개별 당사자에게 유리하고 해당 당사자의 개별 청구에 필요한 구제를 제공하는 데 필요한 범위 내에서만 구제(금전적, 금지명령적, 선언적 구제 포함)를 판정할 수 있습니다.</p>
                        <br/>

                        <b>중재 전 분쟁 해결</b>

                        <p>사주천왕은 항상 원만하고 공정하게 분쟁을 해결하는데 관심이 있으므로 우려사항이 있는 경우 먼저 이메일로 문의하실 것을 강력히 권장합니다. 이러한 노력이 실패한 것으로 판명된 경우, 중재를 요청하려는 당사자는 먼저 상대방에게 서면 분쟁 통지("통지") 를 우편으로 보내야 합니다. 통지는 다음 주소로 보내야 합니다: 서울시 서초구 사임당로8길 13, 4층 402호 엠-204호. 통지에는 (i) 청구 또는 분쟁의 성격과 근거를 설명하고, (ii) 원하는 구제책을 구체적으로 명시해야 합니다. 통지를 받은 후 60일 이내에 사주천왕과 귀하가 클레임을 해결하지 못하는 경우, 귀하 또는 사주천왕은 중재 절차를 개시할 수 있습니다. 중재 기간 동안, 중재인이 귀하 또는 사주천왕이 받을 수 있는 금액(있는 경우)을 결정할 때까지 [사주천왕](http://goodking.ai/) 또는 귀하가 제안한 모든 합의 금액은 중재인에게 공개되지 않습니다.</p>
                        <br/>

                        <b>기밀 유지</b>
                        <p>중재 절차의 모든 측면과 중재인의 모든 판결, 결정 또는 판정은 모든 당사자의 이익을 위해 엄격하게 기밀로 유지됩니다.</p>
                        <br/>

                        <b>분리 가능성</b>
                        <p>법원 또는 중재자가 본 중재 계약의 특정 용어 또는 조항(위의 "집단 및 대표 소송 금지 및 비개인적 구제" 조항 제외)이 무효이거나 집행 불가능하다고 결정하는 경우, 당사자들은 해당 용어 또는 조항을 유효하고 집행 가능하며 무효이거나 집행 불가능한 용어 또는 조항의 의 도를 가장 근접하게 표현하는 용어 또는 조항으로 대체하는 데 동의하며 본 중재 계약은 그렇게 변경된 대로 집행할 수 있게 됩니다. 법원 또는 중재자가 위 "집단 및 대표 소송 및 비개인적 구 제 금지"라는 제목의 하위 조항이 무효이거나 집행할 수 없다고 결정하는 경우, 본 중재 계약 전 체가 무효가 됩니다. 나머지 약관은 계속 적용됩니다.</p>
                        <br/>

                        <b>중재 계약의 향후 변경</b>
                        <p>본 약관의 상반되는 조항에도 불구하고, 귀하가 서비스를 사용하는 동안 본 중재 계약을 향후 변경하는 경우(통지 주소 변경 제외), 귀하는 위에 제공된 통지 주소로 변경 일로부터 30일 이내에 사주천왕에 서면 통지를 보내 그러한 변경을 거부할 수 있음에 동의합니다. 향후 변경을 거부함으로써 귀하는 본 약관을 처음 수락한 날짜(또는 본 약관의 후속 변경을 수락한 날짜)를 기준으로 본 중재 계약의 문구에 따라 당사 간의 모든 분쟁을 중재하는 데 동의 하는 것입니다.</p>
                        <br/>

                        <b>해지</b>
                        <p>귀하는 사주천왕이 단독 재량으로 귀하의 계정(또는 그 일부) 또는 서비스 사용을 일시 중지 또는 종료하고, 사용 부족을 포함하되 이에 국한되지 않는 어떠한 이유로든 또는 귀하가 본 약관의 문자 또는 정신을 위반하거나 일관되지 않게 행동했다고 판단하는 경우 서비스 내의 모든 콘텐츠를 제거 및 폐기할 수 있다는데 동의합니다. 또한 사주천왕은 단독 재량으로 언제든지 통지 여부에 관계없이 서비스 또는 그 일부의 제공을 중단할 수 있습니다. 귀하는 본 약관의 조항에 따라 서비스에 대한 귀하의 액세스 권한이 사전 통지 없이 종료될 수 있다는 데 동의하며, 사주천왕이 귀하의 계정과 계정의 모든 관련 정보 및 파일을 즉시 비활성화 또는 삭제하거나 그러한 파일 또는 서비스에 대한 추가 액세스를 금지할 수 있음을 인정하고 이에 동의합니다(단, 그럴 의무는 없음). 귀하의 계정을 해지하거나 서비스의 모든 구성 요소에 대한 액세스 권한이 종료되더라도 귀하의 콘텐츠에 대한 사주천왕의 권리는 종료되지 않습니다. 또한, 귀하는 서비스에 대한 귀하의 액세스 종료에 대해 사주천왕이 귀하 또는 제3자에게 책임을 지지 않음에 동의합니다.</p>
                        <br/>
                        <br/>
                        <b>일반</b>
                        <br/>

                        <b>전체 계약</b>
                        <p>본 약관은 귀하와 [사주천왕](http://goodking.ai/) 간의 완전한 계약을 구성하며 귀하의 서비스 사용에 적용되며, 서비스와 관련하여 귀하와 [사주천왕](http://goodking.ai/) 간의 모든 이전 계약을 대체합니다.</p>
                        <br/>

                        <b>해지</b>
                        <p>본 약관의 어떤 조항이 관할 법원에 의해 무효로 판명되는 경우, 양 당사자는 법원이 해당 조항에 반영된 당사자의 의사를 반영하도록 노력해야 한다는 데 동의하며, 본 약관의 다른 조항은 완전한 효력을 유지합니다.</p>
                        <br/>

                        <b>포기 없음</b>
                        <p>사주천왕이 본 약관의 권리 또는 조항을 행사하거나 집행하지 않는다고 해서 그러한 권리 또는 조항의 포기로 간주되지 않습니다.</p>
                        <br/>

                        <b>클레임의 만료</b>
                        <p>귀하는 이와 상반되는 법령이나 법률에 관계없이 웹사이트 또는 본 약관의 사용으로 인해 또는 이와 관련하여 발생하는 모든 청구 또는 소송 사유는 해당 청구 또는 소송 사유 가 발생한 후 1년 이내에 제기되어야 하며, 그렇지 않을 경우 영원히 금지된다는데 동의합니다.</p>
                        <br/>

                        <b>양도</b>
                        <p>귀하는 사주천왕의 사전 서면 동의 없이 본 약관을 양도할 수 없으나, 사주천왕은 본 약관의 전부 또는 일부를 제한 없이 양도하거나 이전할 수 있습니다.</p>
                        <br/>

                        <b>통지</b>
                        <p>회원에 대한 통지는 이메일 또는 일반 우편을 통해 이루어질 수 있습니다. 사이트는 또한 본 약관 또는 기타 사항의 변경에 대한 통지를 서비스에 일반적으로 공지 또는 공지 링크를 표시하여 귀하에게 통지할 수 있습니다.</p>
                        <br/>

                        <b>본 약관의 변경 사항</b>
                        <p>당사는 단독 재량에 따라 언제든지 본 약관의 일부를 변경하거나 수정할 수 있는 권리를 보유합니다. 이 경우 당사는 본 페이지에 변경 사항을 게시하고 본 페이지 상단에 본 약관이 마지막으로 개정된 날짜를 표시합니다. 이러한 변경사항은 게시된 후 14일 이내에 효력이 발생하지만, 서비스의 새로운 기능에 관한 변경사항이나 법적 사유로 인한 변경사항은 즉시 효력이 발생합니다. 그러한 변경사항이 발효된 날 이후에도 서비스를 계속 사용하면 새로운 약관에 동의하는 것으로 간주됩니다.</p>
                        <br/>

                        <b>문의하기</b>
                        <p>서비스에 대해 궁금한 점이 있거나 본 약관 위반을 신고하려면 이메일(contact@flabs.ai)로 문의하시기 바랍니다.</p>
                    </>
                    :
                    <>
                        <p style={{display: 'inline-block', width: 'auto', marginBottom: '16px', padding: '10px', border: '1px solid #E2E5EB', borderRadius: '5px'}}>Effective Date : 2024-03-05</p>
                        <br/>
                        <br/>
                        <p>GoodKing provides AI counseling services through Tangoland's software, and the entity providing AI counseling services is limited to Tangoland Co.</p>
                        <br/>
                        <p>These Terms of Service are an agreement between you and Tango Land Co., Ltd. This applies to the website (hereinafter “Website”) and the goodKing mobile application (hereinafter “App”) provided by the above services. In these Terms and Conditions, we will sometimes refer to Tango Land Co., Ltd. as “goodKing”, “Company”, “we” or “us”. We refer to our websites and apps, and all content, tools, features and functionality available through them, as the “Services”.</p>
                        <br/>
                        <p>Thse Terms apply to your access and use of the Service. Please read these Terms carefully as they contain important information about your legal rights. By accessing or using the Service you agree to be bound by these Terms. If you do not understand or agree to these Terms and Conditions, please do not use the Service.</p>
                        <br/>
                        <p>In these Terms, “you” means you, the user of the Service. If you use the Service on behalf of a company or other legal entity, “you” includes you and such entity, and you represent that (a) you are an authorized representative of such entity with the authority to bind such entity to these Terms; , (b) you represent and warrant that you agree to these Terms on behalf of such entity.</p>
                        <br/>
                        <p>NOTE: THESE TERMS CONTAIN AN ARBITRATION PROVISION AND CLASS ACTION WAIVER. By agreeing to these Terms, you agree to resolve all disputes with us through binding individual arbitration. This means you are waiving your right to have such disputes decided by a judge or jury, and you are waiving your right to participate in a class action, class arbitration, or representative action. You have the right to opt out of arbitration as described below.</p>
                        <br/>

                        <b>Use of Service</b>
                        <p>Your registration obligations. If you register to use the Service, you agree to provide accurate and complete information about yourself.</p>
                        <p>Member Accounts, Passwords and Security. You are responsible for maintaining the confidentiality of your password and account and for all activities that occur under your password or account. You agree to immediately notify goodKing if you discover any unauthorized use of your password or account or any other breach of security and ensure that you exit from your account at the end of each session when accessing the Service. goodKing will not be liable for any loss or damage arising from your failure to comply with this provision.</p>
                        <br/>

                        <b>General practices regarding use and storage</b>
                        <p>You acknowledge that goodKing may establish general practices and limits regarding use of the Services. This may include, but is not limited to, the maximum period of time that data or other content will be retained by the Services and the maximum amount of storage space allocated on goodKing's servers on your behalf. You agree that goodKing has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded to the Service.</p>
                        <p>You acknowledge that goodKing reserves the right to terminate any account that is inactive for an extended period of time. You further acknowledge that goodKing reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>
                        <br/>

                        <b>Email notification</b>
                        <p>You consent to receive notices from us electronically at the email address you provided to us. These notices may be about changes to your account, services, or other updates or marketing related to the Platform.</p>
                        <br/>

                        <b>Points purchase</b>
                        <p>The redeemed gold coins are valid for one year from the time of payment and are refundable within 7 days from the time of payment</p>
                        <br/>
                        <br/>

                        <b>Terms of Use</b>
                        <p>User behavior. In addition to agreeing to abide by the Community Guidelines contained herein, you agree to comply with the following terms when using the Service.</p>
                        <p>You are solely responsible for all content you submit to the Service. (“Content you submit” and similar terms mean anything you wish to post, transmit, or share, including but not limited to text, images, sounds, video, graphics, information, or other data).</p>
                        <p>You agree not to submit content that:</p>
                        <br/>

                        <p>(i) Infringes on the intellectual property rights or other proprietary rights of a party</p>
                        <p>(ii) contains software viruses or any computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
                        <p>(iii) poses a privacy or security risk to others;</p>
                        <p>(iv) any unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, 'junk mail', 'spam', 'chain letters', 'pyramid schemes', 'contests', 'sweepstakes' or Constitutes any other form of solicitation;</p>
                        <p>(v) any threatening, abusive, harassing, tortious, harassing or excessively violent conduct;</p>
                        <p>(vi) If it is defamatory, slanderous, or clearly false for the purpose of harming others;</p>
                        <p>(vii) constitutes hate speech that disparages or promotes discrimination or violence on the basis of race, ethnicity, gender, religion, age, or other social, physical or mental characteristics;</p>
                        <p>(viii) is obscene or pornographic; This constitutes sexual harassment;</p>
                        <p>(ix) constitutes sexual exploitation or abuse of a minor, including sharing child sexual exploitation or abuse images or content, grooming or sexual extortion;</p>
                        <p>(xi) Content that glorifies self-harm, such as self-harm, suicide, and eating disorders</p>
                        <p>(xii) Promotes terrorism or violent extremism</p>
                        <p>(xiii) If it promotes or encourages criminal activity</p>
                        <p>(xiv) When attempting to purchase or sell illegal drugs</p>
                        <p>(xv) Facilitate fully automated decision-making that adversely affects the legal rights of any person or creates binding and enforceable obligations</p>
                        <p>(xvi) Medical, legal, financial or tax advice</p>
                        <p>(xvii) interfere with or disrupt the Service or servers or networks connected to the Service</p>
                        <p>(xviii) Interfere with or misappropriate the rights of publicity of others by using their name, likeness, or likeness (a) without permission, or (b) outside of permitted contexts, such as in non-commercial parody or public commentary.</p >
                        <p>(xix) in the sole judgment of goodKing, may be offensive, restrict or inhibit any other person from using or enjoying the Service, or endanger [goodKing](http://goodking.ai/) or its users; Cases that may expose you to liability</p>
                        <br/>

                        <p>In addition, you agree not to engage in any of the following acts in connection with the use of the service</p>
                        <p>(i) Violates any requirements, procedures, policies or regulations of networks connected to the Service</p>
                        <p>(ii) Violation of relevant laws or regulations</p>
                        <p>(iii) Impersonate any person or entity or misrepresent your affiliation with another person or entity</p>
                        <p>(iv) Requesting personal information from anyone under the age of 18</p>
                        <p>(v) harvest or collect email addresses or other contact information of other users of the Services through electronic or other means for the purpose of sending unsolicited email or other unsolicited communications</p>
                        <p>(vi) Obtaining or attempting to obtain information through any means not intentionally made available or provided for through the Services</p>
                        <p>(vii) Renting, leasing, selling or sublicensing any part of the Services</p>
                        <p>(viii) Attempt to circumvent technical measures designed to protect the Services or technology associated with the Services</p>
                        <p>(ix) attempt to reverse engineer, disassemble, decompile, decode, adapt, or otherwise derive or access the source code of the Services, in whole or in part, provided that any portion of the code within the Services is disclosed as open source and (Except where reverse engineering, copying, or other modification is expressly permitted by the open source license applicable to </p>
                        <br/>

                        <p>To the extent goodKing chooses to support voice or audio functionality, you agree not to do any of the following in connection with your use of the Services</p>
                        <p>(i) Submitting voice recordings of third parties (including but not limited to celebrities) without their consent</p>
                        <p>(ii) 'deepfakes' of any kind, including but not limited to those that create political disinformation, commit fraud or fraud, damage the reputation of a third party, or constitute other harmful conduct; Using the goodKing voice feature for impersonation</p>
                        <p>We reserve the right to investigate and take appropriate action against anyone who we determine, in our sole discretion, has violated the above provisions. Such action may include, but is not limited to, removing your content from the Services, suspending or terminating your account, and reporting you to law enforcement authorities.</p>
                        <br/>

                        <b>Intellectual Property Rights</b>
                        <p>Content you submit. When you submit Content to the Service, you either own all right, title and interest (including, without limitation, all copyright and publicity rights) in and to such Content or to submit Content to the Service for the purposes contemplated by these Terms. You represent and warrant that you have obtained all necessary permits, approvals and approvals.</p>
                        <p>When you submit such content, you retain all ownership rights in that content. You may not copy, display, upload, perform, and distribute the Content in any form, medium, or technology, now known or hereafter developed, including to operate, improve, and provide the Services to goodKing to the fullest extent permitted by law. , a non-exclusive, worldwide, royalty-free, fully-paid, transferable, license to transmit, make available, store, modify, exploit, commercialize and otherwise use for [goodKing](http://goodking.ai/) related purposes; We grant you a sublicensable, perpetual, irrevocable license. You grant that these rights and licenses include the right for goodKing to provide content and to convey these rights to others with whom we have a contractual relationship, and to third parties if we determine that such access is necessary or appropriate. You agree that this includes the right to provide access to or disclose the Content.</p>
                        <p>Although we are not obligated to do so, we may access, review, screen, edit, modify and delete your Content at any time for any reason, including to provide and develop the Services or if we believe that the Content violates these Terms or applicable laws. You can.</p>
                        <p>Service Content, Software and Trademarks. You acknowledge and agree that the Services may contain content or features that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. You will not engage in or use any data mining, robots, scraping or similar automated data collection or extraction methods in connection with your use of the Services. If you are blocked by us from accessing the Services (including by blocking your IP address), you agree not to take any action to circumvent such blocking (such as masking your IP address or using a proxy IP address). . Any use of the Service or Content on the Service other than as specifically authorized by these Terms is strictly prohibited. All rights not expressly granted herein are reserved.</p>
                        <p>The goodKing name and logo are trademarks of goodKing (collectively, the “goodKing Trademarks”). Other trademarks used and displayed through the Service may be trademarks of their respective owners, who may not endorse, be affiliated with, or connected to goodKing. Nothing in these Terms or the Service should be construed as granting any license or right to use any goodKing trademark without our prior written permission in each case. All goodwill arising from the use of the goodKing trademark shall inure to our exclusive benefit.</p>
                        <p>You acknowledge that we have the right (but not the obligation), in our sole discretion, to refuse or remove any content (including any content or characters) that is available through the Service. Without limiting the foregoing, we reserve the right, in our sole discretion, to remove any Content that violates these Terms or that we determine to be otherwise objectionable.</p>
                        <p>You acknowledge that any questions, comments, suggestions, ideas, feedback or other information regarding the Services that you provide to goodKing are non-confidential and that we may not use or distribute them for any purpose without acknowledgment or compensation to you. I acknowledge and agree to this.</p>
                        <p>You acknowledge and agree that we may preserve your content and metadata in accordance with applicable laws and our Privacy Policy. You acknowledge and agree that we may disclose Content and Metadata if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable law or governmental requests; (b) to enforce these Terms, (c) to respond to claims that any Content violates the rights of third parties, or (d) to [goodKing](http://goodking.ai/) users. and to protect the rights, property, or personal safety of the public.</p>
                        <br/>

                        <b>Report copyright infringement</b>
                        <p>goodKing respects the intellectual property of others and asks our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement or your intellectual property rights are otherwise violated, you must notify goodKing of your claim of infringement by following the procedures set forth below.</p>
                        <p>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property right.</p>
                        <p>A description of the copyrighted work or other intellectual property that you claim has been infringed.</p>
                        <p>A description of where the material that you claim is infringing is located on the Service, with sufficient detail to enable us to locate the material on the Service.</p>
                        <p>Address, phone number, email address</p>
                        <p>A statement that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law</p>
                        <p>A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</p>
                        <br/>

                        <b>Notice of Opposition</b>
                        <p>If you believe that your content has been removed or disabled but you believe that the content does not infringe your copyright, you may send us a written counter-notice containing the following information</p>
                        <br/>

                        <b>Physical or electronic signature</b>
                        <p>Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled.</p>
                        <p>A statement that the content was removed or disabled as a result of mistake or misidentification of the content.</p>
                        <p>Your name, address, phone number, and email address.</p>
                        <p>Upon receipt of a counter-notification, we will notify you that we will restore the removed/blocked content within 14 business days, unless the complaining party discloses that they have filed a court action related to the content.</p>
                        <p>A copy of the notice will be sent to the original complaining party.</p>
                        <p>Repeat infringer policy. Subject to other applicable laws, goodKing has adopted a policy of terminating, in appropriate circumstances and at our sole discretion, users who are deemed to be repeat infringers. Additionally, goodKing may, in its sole discretion, restrict access to the Service or terminate the registration of any user who infringes the intellectual property rights of others, regardless of whether or not there are repeat infringements.</p>
                        <br/>

                        <b>Third-party websites and services</b>
                        <p>The Service or third parties may provide links or other access to other sites and resources on the Internet or third party applications. goodKing has no control over, and goodKing is not responsible for and does not endorse, any of these sites, resources or applications. You acknowledge and agree that goodKing shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with your reliance on any content, events, goods or services available on or through any such site, resource or application. do. Any dealings you encounter with third parties while using the Services are between you and such third parties, and you hold goodKing liable for any loss or claim you may have against such third parties. I agree that it doesn't.</p>
                        <br/>

                        <b>Compensation and Indemnification</b>
                        <p>You agree to release, indemnify and hold harmless goodKing and its affiliates and their officers, employees, directors and agents from and against any and all losses, damages and expenses of any kind arising from or in connection with your use of the Services. . Without limiting the foregoing, the indemnification and indemnification set forth above includes reasonable attorneys' fees, rights, claims, actions of any kind and injury (including death) arising out of or related to your use of the Services.</p>
                        <p>A general release does not apply to claims for which the creditor or indemnified party did not know or suspect that claims existed in his or her favor at the time of executing the release, and which, if known, would have materially affected his or her settlement with the debtor or indemnified party. .</p>
                        <p>If you are a resident of another jurisdiction, you waive any similar laws or principles.</p>
                        <br/>

                        <b>Warranty Disclaimer</b>
                        <p>Use of the service is entirely at your own risk. The Site is provided on an “as is” and “as available” basis. goodKing expressly disclaims all warranties of any kind, whether express, implied or statutory, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. goodKing does not guarantee that (i) the Service will meet your requirements, (ii) the Service will be uninterrupted, timely, secure or error-free, (iii) the results that may be obtained from the use of the Service; does not guarantee that it will be accurate or reliable.</p>
                        <br/>

                        <b>Limitation of Liability</b>
                        <p>You agree that goodKing will not be liable for any indirect, incidental, special, consequential or exemplary damages or damages for loss of profits, including but not limited to damages for loss of goodwill, use of data, or other intangible losses. I understand and agree. Damages arising, whether in contract, tort, negligence, strict liability or otherwise (even if goodKing has been advised of the possibility of such damages), arising out of: (i) the use of, or inability to use, the Service; , (ii) unauthorized access to or alteration of your transmissions or data, (iii) statements or conduct of any third party (including users) on the Service, or (iv) any other matter related to the Service. In no event will goodKing's total liability to you for all damages, losses or causes of action exceed the amount of: (i) the amount paid by you to goodKing in connection with your use of the Services (if any); case).</p>
                        <p>If you are dissatisfied with any part of the Site, or with any of these Terms of Use, your sole and exclusive remedy is to discontinue using the Service.</p>
                        <br/>

                        <b>Dispute resolution through binding arbitration</b>
                        <p>Please read this section carefully as it affects your rights.</p>
                        <p>Consent to Arbitrate. The Resolving Disputes Through Binding Arbitration section of these Terms is referred to in these Terms as the “Arbitration Agreement.” You agree that any and all disputes or claims that arise or may arise out of or in connection with these Terms (including any alleged breach thereof), the Website or Services, or any aspect of the relationship or transactions between us shall be arbitrated in final and binding arbitration, rather than in court, pursuant to the terms of this Arbitration Agreement. You agree that all claims will be resolved only through (except that you may bring an individual claim in small claims court, if your claim qualifies). Additionally, this Arbitration Agreement does not preclude you from bringing issues before a local agency, which may then seek relief against us on your behalf where permitted by law. You agree that by entering into these Terms, you and goodKing are each waiving the right to a trial or to participate in a lawsuit.</p>
                        <br/>

                        <b>Prohibition of class and representative actions and non-individual relief</b>
                        <p>You and goodKing agree that each may bring claims against the other only on an individual basis, and not as a plaintiff or class member in any class or representative action or proceeding. Unless both you and goodKing agree otherwise, the arbitrator may not consolidate or join more than one person's or party's claims, and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Additionally, the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party's individual claims.</p >
                        <br/>

                        <b>Dispute Resolution Before Arbitration</b>
                        <p>goodKing is always interested in resolving disputes amicably and fairly, so we strongly encourage you to first contact us to email if you have any concerns. If these efforts prove unsuccessful, the party wishing to seek arbitration must first mail a written Notice of Dispute (“Notice”) to the other party. Notices must be sent to the following address: Room M-204, Room 402, 4th Floor, 13 Saimdang-ro 8-gil, Seocho-gu, Seoul. The Notice must (i) describe the nature and basis of the claim or dispute, and (ii) specifically state the relief sought. If goodKing and you do not resolve the claim within 60 days after the Notice is received, you or goodKing may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by [goodKing](http://goodking.ai/) or you will be disclosed to the arbitrator until the arbitrator determines the amount, if any, to which you or goodKing is entitled. It does not work.</p>\
                        <br/>

                        <b>Confidentiality</b>
                        <p>All aspects of the arbitration proceeding and any decision, decision, or award of the arbitrator will be strictly confidential for the benefit of all parties.</p>
                        <br/>

                        <b>Severability</b>
                        <p>If a court or arbitrator decides that any term or provision of this Arbitration Agreement (except the “Prohibition of Class and Representative Actions and Non-Individualized Relief” provisions above) is invalid or unenforceable, then the parties will remain in effect for that term or provision. You agree to replace the invalid or unenforceable term or provision with a term or provision that most closely expresses the intent of the invalid or unenforceable term or provision, and this Arbitration Agreement will be enforceable as so changed. If a court or arbitrator decides that any of the subsections above titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” is invalid or unenforceable, then the entirety of this Agreement to Arbitrate shall be null and void. The remaining terms and conditions still apply.</p>
                        <br/>

                        <b>Future Changes to Arbitration Agreement</b>
                        <p>Notwithstanding any provision in these Terms to the contrary, if we make any changes to this Arbitration Agreement in the future (other than a change to the notice address) while you are using the Service, you must notify us at the notice address provided above within thirty (30) days of the date of the change. You agree that you may reject any such changes by sending written notice to ai. By rejecting any future changes, you are agreeing to arbitrate all disputes between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms (or the date you accepted any subsequent changes to these Terms).</p>
                        <br/>

                        <b>Cancellation</b>
                        <p>You agree that goodKing, in its sole discretion, may suspend or terminate your account (or any part thereof) or your use of the Services, for any reason, including but not limited to lack of use, or if you violate the letter or text of these Terms. You agree that we may remove and discard any Content within the Service if we determine that you have violated its spirit or acted inconsistently. Additionally, goodKing may, in its sole discretion, discontinue providing the Service, or any part thereof, at any time, with or without notice. You agree that any termination of your access to the Service under any provision of these Terms may result in goodKing immediately deactivating or deleting your account and all related information and files in your account or any such files. You acknowledge and agree that we may (but are not obligated to) prohibit you from further accessing the Services. Termination of your account or access to any components of the Service will not terminate goodKing's rights to your Content. Additionally, you agree that goodKing will not be liable to you or any third party for any termination of your access to the Service.</p>
                        <br/>
                        <br/>
                        <b>General</b>
                        <br/>

                        <b>Full Agreement</b>
                        <p>These Terms constitute the entire agreement between you and [goodKing](http://goodking.ai/) and govern your use of the Service, and relate to the Service. ://goodKing/) and supersedes all previous agreements.</p>
                        <br/>

                        <b>Cancellation</b>
                        <p>If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties agree that the court should endeavor to reflect the parties' intentions as reflected in the provision, and the other provisions of these Terms will remain in full force and effect. I do.</p>
                        <br/>

                        <b>No giving up</b>
                        <p>goodKing's failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>
                        <br/>

                        <b>Expiration of Claim</b>
                        <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or these Terms must be filed within one (1) year after such claim or cause of action arose; Otherwise, I agree to be banned forever.</p>
                        <br/>

                        <b>Transfer</b>
                        <p>You may not assign these Terms without goodKing's prior written consent, but goodKing may assign or transfer these Terms, in whole or in part, without restriction.</p>
                        <br/>

                        <b>Notice</b>
                        <p>Notices to members may be made via email or regular mail. The Site may also notify you of changes to these Terms or other matters by displaying a general notice or notice link on the Services.</p>
                        <br/>

                        <b>Changes to these Terms and Conditions</b>
                        <p>We reserve the right, at our sole discretion, to change or modify any part of these Terms at any time. When we do this, we will post the changes on this page and indicate at the top of this page the date these Terms were last revised. These changes will be effective 14 days after they are posted, however, changes regarding new features of the Service or changes for legal reasons will be effective immediately. Your continued use of the Service after the effective date of any such changes will constitute your agreement to the new Terms.</p>
                        <br/>

                        <b>Contact</b>
                        <p>If you have any questions about the Service or would like to report a violation of these Terms, please contact us to email</p>
                    </>
                }
            </Box>
        </>
    )
}

export default TermsScreen
