import React, {useEffect, useState} from 'react'
import "./MoreScreen.css";
import {Link} from 'react-router-dom';
import {getString, httpPost} from '../../core/utils';
import {IUser, SignupType} from '../../shared/types';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '../../store';
import {Drawer, Grid, Checkbox} from "@material-ui/core";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { setMe } from '@src/store/auth';
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";


function MoreItem({title, link, onClick, target, arrow}: {
    title: string
    link: string
    onClick?: (e: any) => void
    target?: string
    arrow: boolean
}) {

    return (
        <Link to={link} onClick={onClick} className="more-item" target={target}>
            <h4>{title}</h4>
            {
                arrow &&
                <img src="/arrow.svg" width={24} height={24}/>
            }
        </Link>
    )
}

function MoreItemA({title}: {
    title: string
}) {

    return (
        <a href="https://flabsai.notion.site/61615eaf63bb41f886625171e0dac822?pvs=4" className="more-item">
            <h4>{title}</h4>
            <img src="/arrow.svg" width={24} height={24}/>
        </a>
    )
}

function MoreScreen() {
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);

    const [reviewEvent, setReviewEvent] = useState(false)
    const [selectedRadio, setSelectedRadio] = useState(true)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [badReason, setBadReason] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        /*setReviewEvent(me ? (!!me.recommendEventJoined) : false);*/
    }, []);

    function surveyComplete() {
        setReviewEvent(false)

        //
        httpPost('/api/users/recommendEvent', {
            rate: selectedRadio ? 1 : 0,
            reason: badReason,
            mobileNumber: phoneNumber
        }).then((res) => {
            if(res.status >= 0) {
                //
                setTimeout(() => {
                    window.location.reload();
                }, 200); // 500ms 후에 페이지를 새로 고침
            }
        });
    }

    const [privacyAgree, setPrivacyAgree] = useState(true);

    const phoneNumberRegex = /^\d{11}$/;

    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

    useEffect(() => {
        const handler = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e as any);
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA installed');
                }
                setDeferredPrompt(null);
            });
        }
    };

    return (
        <div className={`page-contents-wrapper more`}>

            <div className="top-info-box">
                <div className={`user-info ${me?.hashID ? "login" : "not-login"}`}>
                    {
                        me?.hashID &&
                        <>
                            <h6>{me?.name}{me?.birthday ? (me.birthday.sex === true ? "군" : "양") : ""}, 사주천왕에 온 걸 환영하네!</h6>
                            <p>
                                {
                                    me?.birthday &&
                                        <span>{me?.birthday?.sex === true ? "남성" : "여성"} · {me?.birthday?.year}년 {me?.birthday?.month}월 {me?.birthday?.day}일</span>

                                }
                                <a href="/profile" className={`${me?.birthday ? "" : "no-data"}`}>
                                    {me?.birthday ? "생년월일 수정" : "생년월일 입력"}

                                </a>
                            </p>
                        </>
                    }

                    {
                        !me?.hashID &&
                        <>
                            <p className="login-require"><b>로그인</b>이 필요합니다</p>
                            <a href="/signin">로그인</a>
                        </>
                    }
                </div>
                <div className="cash-info">
                    <p className="cash-text">
                        <img src="/gold.svg" width={20} height={20} style={{marginRight: '8px'}} alt="사주천왕 금화"/>
                        보유금화
                    </p>
                    <p className="my-cash">
                        <span style={{marginRight: '5px'}}>{me?.cashMoney ? me?.cashMoney?.toLocaleString() : '0'}</span>금화
                        <a href="/shop" className="goodking-btn">충전</a>
                    </p>
                </div>
                {/*<div className="ad-info">
                    🎉 띠배너 문구가 들어갑니다.
                </div>*/}
                {
                    me && !!me?.recommendEventJoined === false &&
                    <img src="/surveyBanner.jpg" style={{marginTop: '20px', borderRadius: '10px', cursor: 'pointer'}} onClick={() => setReviewEvent(true)} alt="사주천왕 설문"/>
                }


            </div>
            <div className="more-item-box">
                {me?.hashID && <MoreItem
                    title={getString('User_Info')}
                    link={"/userInfo"}
                    arrow={true}
                />}
                {me?.hashID && <MoreItem
                    title={getString('More_Profile')}
                    link={"/profile"}
                    arrow={true}
                />}
                {me?.hashID && me.gmLevel! >= 100 && <MoreItem
                    title={getString('More_Admin')}
                    link={"/admin/characters"}
                    arrow={true}
                />}
                {me?.hashID && <MoreItem
                    title={getString('More_Point')}
                    link={"/payments"}
                    arrow={true}
                />}
                <MoreItem
                    title={getString('More_Terms')}
                    link={"/terms"}
                    arrow={true}
                />
                <MoreItem
                    title={getString('More_Refund')}
                    link={"/refund"}
                    arrow={true}
                />
                <MoreItem
                    title={getString('More_Privacy')}
                    link={"/privacy"}
                    arrow={true}
                />
                <a
                    className="more-item"
                    href="https://www.instagram.com/goodking.ai/"
                    target="_blank"
                >
                    <h4>고객센터</h4>
                    <img src="/arrow.svg" width={24} height={24}/>
                </a>
                {deferredPrompt && (
                    <div className="more-item" onClick={handleInstallClick}>
                        <h4>앱 다운로드</h4>
                        <img src="/arrow.svg" width={24} height={24}/>
                    </div>
                )}
                {me?.hashID && me?.signupType != SignupType.GUEST ?
                    <MoreItem
                        title={getString('More_Signout')}
                        link={"/signout"}
                        arrow={false}
                    />
                    :
                    <MoreItem
                        title={getString('More_Signin')}
                        link={"/signin"}
                        arrow={false}
                    />
                }
            </div>

            <Drawer
                anchor="bottom"
                open={reviewEvent}
                onClose={() => setReviewEvent(false)}
                style={{position: 'relative', boxSizing: 'border-box'}}
                id="surveyDrawer"
            >
                <div id="reviewPage">
                    <div className="reviewPage-contents">
                        <div className="header" style={{borderBottom: '1px solid #F1F1F1'}}>
                            <div className="left-side" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0px 24px'}}>
                                <h6>사주천왕 사용 만족도 조사</h6>
                            </div>
                        </div>
                        <img src="/surveyImage.jpeg" width="100%" alt="사주천왕 설문"/>
                        <form style={{padding: '24px', boxSizing: 'border-box'}}>
                            <div className="form-wrapper">
                                <div className="input-wrapper" style={{marginBottom: '0px'}}>
                                    <label>사주천왕 사용에 만족하시나요?</label>
                                    <FormControl>
                                        <RadioGroup value={selectedRadio} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <FormControlLabel className="cash-row" value={true} control={
                                                <Radio sx={{color: '#E5E6E8', '&.Mui-checked': {color: '#ED503C',},}}/>} label={
                                                <>
                                                    <span>네</span>
                                                </>
                                            } onClick={() => setSelectedRadio(true)}/>
                                            <FormControlLabel className="cash-row" value={false} control={
                                                <Radio sx={{color: '#E5E6E8', '&.Mui-checked': {color: '#ED503C',},}}/>} label={
                                                <>
                                                    <span>아니요</span>
                                                </>
                                            } onClick={() => setSelectedRadio(false)}/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className="input-wrapper" style={{marginBottom: '16px'}}>
                                    <label>기프티콘 받으실 연락처</label>
                                    <input className={`${phoneNumber !== '' && !phoneNumberRegex.test(phoneNumber) ? "error" : ""}`} type="text" value={phoneNumber} placeholder="ex) 01012345678" onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    {
                                        phoneNumber !== '' && !phoneNumberRegex.test(phoneNumber) &&
                                        <p className="error-text">올바르지 않은 휴대폰 번호 형식입니다</p>
                                    }
                                </div>
                                {
                                    !selectedRadio &&
                                    <div className="input-wrapper" style={{marginBottom: '16px'}}>
                                        <label>만족하지 않으시는 이유를 알려주세요</label>
                                        <input className="line-input"
                                               type="text"
                                               value={badReason}
                                               onChange={(e) => setBadReason(e.target.value)}
                                               placeholder="ex) 사주 캐릭터를 더 만들어주세요"
                                        />
                                        {/*{
                                        email !== '' && !emailRegex.test(email) &&
                                        <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                                    }*/}
                                    </div>
                                }

                                <div
                                    style={{display: 'flex', alignItems: 'center', gap: '0px 8px', marginBottom: '22px', width: '100%', height: '48px', padding: '12px 16px', border: privacyAgree ? '1px solid #ED503C' : '1px solid #CBCDD1', borderRadius: '8px', boxSizing: 'border-box', fontSize: '16px', fontWeight: '400', color: privacyAgree ? '#000000' : '#000000D9', cursor: 'pointer'}}
                                    onClick={() => setPrivacyAgree(!privacyAgree)}
                                >
                                    <CheckCircleOutline style={{color: privacyAgree ? '#ED503C' : '#BCC0C4'}}/>
                                    개인정보 제공 동의
                                </div>

                                <button
                                    type="submit"
                                    className={`signin-btn ${(phoneNumber !== '' && phoneNumberRegex.test(phoneNumber)) && ((selectedRadio === true) || (selectedRadio === false && badReason !== '')) && privacyAgree === true ? "filled" : ""}`}
                                    style={{marginBottom: '0px'}}
                                    disabled={(phoneNumber !== '' && phoneNumberRegex.test(phoneNumber)) && ((selectedRadio === true) || (selectedRadio === false && badReason !== '')) && privacyAgree === true ? false : true}
                                    onClick={()=> {surveyComplete()}}
                                >
                                    제출하기
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default MoreScreen
 