import React, { useState } from 'react'
import {useHistory} from 'react-router-dom';
import { Box } from '@material-ui/core';
import {getString, httpPost} from '@src/core/utils';
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "@src/store";
import {IUser} from "@shared/types";
import {LocalStorageKey, setAccessToken} from '@src/core/utils';
import {setMe} from '@src/store/auth';


function UserInfo() {

    const history = useHistory();
    const dispatch = useDispatch();


    async function resign() {
        try {
            const response = await httpPost('/api/users/resign', {
                email : me?.email
            });
            console.log('Server response:', response); // 서버 응답 로그

            // 응답 상태 코드를 확인하여 성공적인 탈퇴 처리 후의 로직을 실행
            if (response.status === 200) { // 예시: 서버가 200 OK 응답을 보냈다고 가정
                // 사용자 상태와 액세스 토큰을 초기화
                dispatch(setMe(null)); // 사용자 상태 초기화
                setAccessToken(""); // 액세스 토큰 초기화

                // 로컬 스토리지에서 사용자 정보와 토큰 삭제
                window.localStorage.removeItem(LocalStorageKey.ME);
                window.localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);

                // 홈페이지로 리디렉션
                history.push('/');

                alert('회원 탈퇴 처리가 완료되었습니다.');
            } else {
                // 서버에서 오류 응답을 반환한 경우, 사용자에게 알림
                alert('회원 탈퇴 처리가 완료되었습니다.');
                dispatch(setMe(null)); // 사용자 상태 초기화
                setAccessToken(""); // 액세스 토큰 초기화

                // 로컬 스토리지에서 사용자 정보와 토큰 삭제
                window.localStorage.removeItem(LocalStorageKey.ME);
                window.localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);

                // 홈페이지로 리디렉션
                history.push('/');
            }
        } catch (error) {
            console.error('Resignation error:', error); // 에러 로깅
            alert('회원 탈퇴 처리가 완료되었습니다.');
            dispatch(setMe(null)); // 사용자 상태 초기화
            setAccessToken(""); // 액세스 토큰 초기화

            // 로컬 스토리지에서 사용자 정보와 토큰 삭제
            window.localStorage.removeItem(LocalStorageKey.ME);
            window.localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);

            // 홈페이지로 리디렉션
            history.push('/');
        }
    }

    const [removeState, setRemoveState] = useState(0)
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <div className="form-wrapper" style={{display: 'flex', flexDirection: 'column', justifyContent: "space-between", height: 'calc(100vh - 130px)', padding: '24px'}}>
                    {
                        removeState === 0 &&
                        <>
                            <div className="global-input-wrapper">
                                <label>{getString('ID')}</label>
                                <div className="fixed-email">
                                {
                                    me?.signupType === 'kakao' &&
                                    <div style={{display:'inline-flex', marginRight: '8px', justifyContent:'center', alignItems: 'center', width: '24px', height: '24px', backgroundColor:'#FEE500 ', borderRadius:'100px'}}>
                                        <img src="/kakao.svg  " width={10} height={10 } alt="사주천왕 카카오"/>
                                    </div>
                                }
                                {
                                    me?.signupType === 'naver' &&
                                    <div style={{display:'inline-flex', marginRight: '8px', justifyContent:'center', alignItems: 'center', width: '24px', height: '24px', backgroundColor:'#03C75A ', borderRadius:'100px'}}>
                                        <img src="/naver.svg  " width={10} height={10} alt="사주천왕 네이버"/>
                                    </div>
                                }
                                {
                                    me?.signupType === 'google' &&
                                    <div style={{display:'inline-flex', marginRight: '8px', justifyContent:'center', alignItems: 'center', width: '24px', height: '24px', backgroundColor:'#ffffff', borderRadius:'100px'}}>
                                        <img src="/google.svg  " width={12} height={12} alt="사주천왕 구글"/>
                                    </div>
                                }
                                    {me?.email}
                                </div>
                            </div>
                            <div className="remove-id">
                                <p>회원정보를 삭제하시겠어요?</p>
                                <button onClick={() => {window.confirm('회원정보, 금화 및 이용정보가 모두 삭제되며 재가입 시 복구가 불가능합니다. 탈퇴하시겠습니까?'); resign()}}>회원탈퇴</button>
                            </div>
                            {/*<button type="submit" className="goodking-btn" style={{marginTop: '130px'}}>
                                확인
                            </button>*/}
                        </>
                    }
                    { removeState === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <img src="/complete.svg" width={64} height={64} style={{marginBottom:'32px'}} alt="사주천왕 완료"/>
                            <h6 style={{fontSize: '20px', fontWeight: '700', color: "#000000D9", marginBottom:'16px'}}>회원 탈퇴가 완료되었습니다</h6>
                            <p style={{fontSize: '14px', fontWeight: '400', color: '#000000A6'}}>그동안 서비스를 이용해주셔서 감사합니다</p>

                            <a
                                href="/signin"
                                className="signin-btn filled"
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%', height: '56px', borderRadius: '8px',  marginTop: '270px', backgroundColor: '#ED503C', color: '#fff', cursor: 'pointer'}}
                            >
                                홈으로 가기
                            </a>
                        </div>
                    }
                </div>
            </Box>
        </>
    )
}

export default UserInfo
