import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    ListSubheader,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {getString, getStringFromBirthday, httpPost} from '@src/core/utils';
import InfoIcon from '@material-ui/icons/Info';
import {ICharacter, IChat, IUser, IUserAttachment} from '../../shared/types';
import ImageModal from './ImageModal';
import {format} from 'util';

interface FortuneDoc {
    [key: string]: any;
}

const yaml = require('js-yaml');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

function ChatFortune({chat, character, fortunePayload}: {
    chat: IChat,
    character: ICharacter | null | undefined,
    fortunePayload: any
}) {
    const classes = useStyles();
    const history = useHistory();

    let modifiedYaml: string | undefined;  // try 블록 외부에 변수를 선언

    try {
        // yaml 데이터를 자바스크립트 객체로 변환
        const docs: FortuneDoc[] = yaml.loadAll(fortunePayload) as FortuneDoc[];

        const keysOrder = ['Name', 'Age', '만세력', '사주명식', '오행분포'];

        // 각 문서를 수정하여 '개인별 사주정보'로 합치기
        const modifiedDocs = docs.map((doc: FortuneDoc) => {
            const 개인별사주정보: { [key: string]: any } = {};
            let index = 0;

            // 'Name', 'Age', '만세력', '사주명식', '오행분포' 키를 포함하는 모든 키를 찾기
            Object.keys(doc).forEach(key => {
                if (keysOrder.every(k => doc[key]?.hasOwnProperty(k))) {
                    keysOrder.forEach(k => {
                        const newKey = index === 0 ? k : `${k}_${index + 1}`;
                        개인별사주정보[newKey] = doc[key][k];
                    });
                    index++;
                }
            });

            // 기존 키 삭제
            Object.keys(doc).forEach(key => {
                if (keysOrder.every(k => doc[key]?.hasOwnProperty(k))) {
                    delete doc[key];
                }
            });

            // '개인별 사주정보' 키 추가
            if (Object.keys(개인별사주정보).length > 0) {
                doc['개인별 사주정보'] = 개인별사주정보;
            }

            // '운세' 키를 '오늘의 운세 점수'로 변경
            if (doc.hasOwnProperty('운세')) {
                doc['오늘도 화이팅!'] = doc['운세'];
                delete doc['운세'];
            }

            return doc;
        });

        // 필요한 경우, 수정된 객체를 YAML 문자열로 변환
        modifiedYaml = modifiedDocs.map((doc: FortuneDoc) =>
            yaml.dump(doc, { noCompatMode: true, noRefs: true, lineWidth: -1 })  // lineWidth 옵션 추가
        ).join('---\n');
    } catch (e) {
    }

    let modifiedText = JSON.stringify(modifiedYaml, null, 4)
        .replace(/[|-]/g, '')
        .replace(/[{}",]/g, '')
        .replace(/[{}",]/g, '')
        .replace(/[{}",>]/g, '')
        .replace(/-</g, '')
        .replace(/[가-힣A-Za-z0-9]+:<strong[^>]*>[^<]*<\/strong>/g, '')
        .replace(/Name:/g, '<strong style="display:inline-block; margin-top: 50px">🙂 이름</strong> : ')
        .replace(/Name_2:/g, '<strong style="display:inline-block; margin-top: 50px">🙂 이름</strong> : ')
        .replace(/Sex:/g, '<strong style="display:inline-block; margin-top: 30px">⚥ 성별</strong> : ')
        .replace(/(female|male)/g, function (match) {
            return match === 'female' ? '여성' : '남성';})
        .replace(/가치과궁합/g, '가치관궁합')
        .replace(/Age:/g, '<strong style="display:inline-block; margin-top: 10px">🌟 나이</strong> : ')
        .replace(/Age_2:/g, '<strong style="display:inline-block; margin-top: 10px">🌟 나이</strong> : ')
        .replace(/Time:/g, '<strong style="display:block; margin-top: 30px">🕰️ 생시</strong>')
        .replace(/Birthdate:/g, '<strong style="display:inline-block; margin-top: 30px">🎂 생일</strong> ->')
        .replace(/Tarot cards:/g, '<strong style="display:block; margin-top: 30px">🃏 타로카드</strong>')
        .replace(/- - /g, '')
        .replace(/- reversed/g, '<p style="display:block; margin-bottom: 20px">(reversed)</p>')
        .replace(/- upright/g, '<p style="display:block; margin-bottom: 20px">(upright)</p>')

        .replace(/시작시기: 양력/g, '<strong style="display:block; margin-top: 30px;">💫 시작시기</strong>')
        .replace(/시작시기: 음력/g, '<strong style="display:block; margin-top: 30px;">💫 시작시기</strong>')
        .replace(/좋은 운:/g, '<strong style="display:block; margin-top: 30px;">🍀 좋은 운</strong>')
        .replace(/나쁜 운:/g, '<strong style="display:block; margin-top: 30px;">🐦‍⬛ 나쁜 운</strong>')
        .replace(/운 요약:/g, '<strong style="display:block; margin-top: 30px;">🗞️ 운 요약</strong>')
        .replace(/설명:/g, '<strong style="display:block; margin-top: 30px;">📜  설명</strong>')

        .replace(/오늘:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: lightcoral; margin-bottom: 3px;">오늘</strong> 🟰 ')
        .replace(/내일:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: mediumaquamarine; margin-bottom: 3px;">내일</strong> 🟰 ')
        .replace(/올해:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: royalblue; margin-bottom: 3px;">올해</strong> 🟰 ')
        .replace(/내년:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: darkorange; margin-bottom: 3px;">내년</strong> 🟰 ')
        .replace(/이번달:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: teal; margin-bottom: 3px;">이번달</strong> 🟰 ')
        .replace(/다음달:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 14px; padding: 0px 5px; color:white; width: auto; height: 22px; border-radius: 5px; background-color: maroon; margin-bottom: 3px;">다음달</strong> 🟰 ')

        .replace(/時:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: darkolivegreen; margin-bottom: 3px;">時</strong> 🟰 ')
        .replace(/日:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: crimson; margin-bottom: 3px;">日</strong> 🟰 ')
        .replace(/月:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: darkgoldenrod; margin-bottom: 3px;">月</strong> 🟰 ')
        .replace(/年:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: darkblue; margin-bottom: 3px;">年</strong> 🟰 ')

        .replace(/木:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: green; margin-bottom: 3px;">木</strong> 🟰 ')
        .replace(/木\(목\): /g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: green; margin-bottom: 3px;">木</strong> 🟰 ')
        .replace(/火:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: #bb1717; margin-bottom: 3px;">火</strong> 🟰 ')
        .replace(/火\(화\): /g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: #bb1717; margin-bottom: 3px;">火</strong> 🟰 ')
        .replace(/土:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: saddlebrown; margin-bottom: 3px;">土</strong> 🟰 ')
        .replace(/土\(토\): /g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: saddlebrown; margin-bottom: 3px;">土</strong> 🟰 ')
        .replace(/金:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: goldenrod; margin-bottom: 3px;">金</strong> 🟰 ')
        .replace(/金\(금\): /g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: goldenrod; margin-bottom: 3px;">金</strong> 🟰 ')
        .replace(/水:/g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: dodgerblue; margin-bottom: 3px;">水</strong> 🟰 ')
        .replace(/水\(수\): /g, '<strong style="display:inline-flex; align-items: center; justify-content: center; font-size: 18px; color:white; width: 25px; height: 25px; border-radius: 5px; background-color: dodgerblue; margin-bottom: 3px;">水</strong> 🟰 ')

        .replace(/배우자궁합 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">💍 배우자 궁합 점수</strong> ->')
        .replace(/궁합총평 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">👑 궁합총평 점수</strong> ->')
        .replace(/궁합총평:/g, '<strong style="display:inline-block; margin-top: 10px;">👑 궁합총평</strong> ->')
        .replace(/전통궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">⚫ 전통궁합</strong> ->')
        .replace(/%/g, '')

        .replace(/결혼후 배우자를 보는 눈:/g, '')
        .replace(/영향력궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">🪭 영향력 궁합</strong> ->')
        .replace(/가치관궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">🧠 가치관 궁합</strong> ->')
        .replace(/배우자궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">💍 부부금슬</strong> ->')
        .replace(/종합점수:/g, '<strong style="display:inline-block; margin-top: 30px;">🪄 종합점수 (50점 이상이면 좋은 궁합입니다)</strong> ->')

        .replace(/사주명식:/g, '<strong style="display:block; margin-top: 22px; margin-bottom: 10px;">🔴 사주명식</strong>')
        .replace(/사주명식_2:/g, '<strong style="display:block; margin-top: 22px; margin-bottom: 10px;">🔴 사주명식</strong>')
        .replace(/오늘 날짜:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">📆 오늘 날짜</strong>')
        .replace(/날짜:/g, '<strong style="display:block; margin-top: 30px">📆 날짜</strong>')
        .replace(/양력/g, '<span style="display:inline-block; margin-top: 30px">양력</span>')
        .replace(/음력/g, '<span style="display:inline-block; margin-top: 30px">음력</span>')
        .replace(/상반기:/g, '<strong style="display:block; margin-top: 30px">🎊 상반기</strong>')
        .replace(/하반기:/g, '<strong style="display:block; margin-top: 30px">🎊 하반기</strong>')
        .replace(/대운:/g, '<strong style="display:block; margin-top: 30px">📈 대운</strong>')
        .replace(/가족궁:/g, '<strong style="display:block; margin-top: 25px; margin-bottom: 5px;">👩🏻‍👩🏻‍👧🏻‍👦🏻 가족궁</strong>')
        .replace(/겉성격:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🔎 겉성격</strong>')
        .replace(/속성격:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🔎 속성격</strong>')
        .replace(/가족관계:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">👩🏻‍👩🏻‍👧🏻‍👦🏻 가족관계</strong>')
        .replace(/의지력:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🔎 의지력</strong>')
        .replace(/재물복:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💵 재물복</strong>')
        .replace(/주 재능:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🎁 주 재능</strong>')
        .replace(/재능의 상태:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🎁 재능의 상태</strong>')
        .replace(/배우자관:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💍 배우자관</strong>')
        .replace(/사주특징:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">✔️ 성격유형</strong>')
        .replace(/성적패턴:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💓 성적패턴</strong>')
        .replace(/연애심리:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💓 연애심리</strong>')
        .replace(/진로선택:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🏢 진로선택</strong>')
        .replace(/추천직종:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🏢 추천직종</strong>')
        .replace(/업무스타일:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🏢 업무스타일</strong>')
        .replace(/적성키워드:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🎁 적성키워드</strong>')
        .replace(/학업스타일:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">📚 학업스타일</strong>')
        .replace(/이성의 존재:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💓 이성의 존재</strong>')
        .replace(/여성x/g, '여성 & ')
        .replace(/남성x/g, '남성 & ')
        .replace(/상담명식:/g, '<strong style="display:block; margin-top: 30px;">🧧 상담명식</strong>')
        .replace(/만세력:/g, '<strong style="display:block; margin-top: 13px; margin-bottom: 10px;">📅 만세력</strong>')
        .replace(/만세력_2:/g, '<strong style="display:block; margin-top: 13px; margin-bottom: 10px;">📅 만세력</strong>')
        .replace(/오행분포:/g, '<strong style="display:block; margin-top: 22px; margin-bottom: 10px;">🔵 오행분포</strong>')
        .replace(/오행분포_2:/g, '<strong style="display:block; margin-top: 22px; margin-bottom: 10px;">🔵 오행분포</strong>')
        .replace(/사이:/g, '<strong style="display:inline-block; margin-top: 30px;">⚪ 사이</strong> ->')
        .replace(/명궁합 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">🫀 명궁합 점수</strong> ->')
        .replace(/명궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">🫀 명궁합</strong> -> ')
        .replace(/평균:/g, '<strong style="display:inline-block; margin-top: 10px;">🧮 평균점수</strong> : ')
        .replace(/겉궁함 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">💖 겉궁합 점수</strong> ->')
        .replace(/겉궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">💖 겉궁합 </strong> -> ')
        .replace(/속궁합 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">❤️‍🔥 속궁합 점수</strong> ->')
        .replace(/속궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">❤️‍🔥 속궁합</strong> -> ')
        .replace(/남음궁합 점수:/g, '<strong style="display:inline-block; margin-top: 30px;">🧡 남음궁합 점수</strong> ->')
        .replace(/남음궁합:/g, '<strong style="display:inline-block; margin-top: 10px;">🧡 남음궁합 </strong> -> ')
        .replace(/궁합 점수:/g, '<strong style="display:inline-block; margin-top: 10px;">🪄 궁합 점수</strong> ->')
        .replace(/개인별 사주정보:/g, '')
        .replace(/세상을 보는 눈이 비슷한가\?:/g, '<strong style="display:inline-block; margin-top: 10px; margin-bottom: 30px">👀 세상을 보는 눈의 유사도</strong> ->')
        .replace(/전통궁합점수:/g, '')
        .replace(/최종궁합점수 \(50점 이상이면 좋은 궁합입니다.\):/g, '')
        .replace(/가치관:/g, '<strong style="display:block; margin-top: 30px;">🧠 가치관</strong>')
        .replace(/결론:/g, '<strong style="display:block; margin-top: 30px;">🧧 해석</strong>')
        .replace(/이성을 만나는 시기:/g, '<strong style="display:block; margin-top: 30px">💕 이성을 만나는 시기(%)</strong>')
        .replace(/세:/g, '세')
        .replace(/\\n/g, '<div style="margin-bottom: 5px"></div>')
        .replace(/\\/g, '')
        .replace(/---/g, '<div style="margin-top: 30px"/>')

        .replace(/3세~7세/g, '<strong style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold;">3세~7세</strong>')
        .replace(/8세~12세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">8세~12세</div>')
        .replace(/6세~10세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">6세~10세</div>')
        .replace(/11세~15세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">11세~15세</div>')
        .replace(/16세~20세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">16세~20세</div>')
        .replace(/21세~25세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">21세~25세</div>')
        .replace(/26세~30세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">31세~35세</div>')
        .replace(/31세~35세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">31세~35세</div>')
        .replace(/36세~40세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">36세~40세</div>')
        .replace(/41세~45세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">41세~45세</div>')
        .replace(/46세~50세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">46세~50세</div>')
        .replace(/51세~55세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">51세~55세</div>')
        .replace(/56세~60세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">56세~60세</div>')
        .replace(/61세~65세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">61세~65세</div>')
        .replace(/66세~70세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">66세~70세</div>')
        .replace(/71세~75세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">71세~75세</div>')
        .replace(/76세~80세:/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">76세~80세</div>')
        .replace(/81세~85세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">81세~85세</div>')
        .replace(/13세~17세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">13세~17세</div>')
        .replace(/18세~22세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">18세~22세</div>')
        .replace(/23세~27세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">23세~27세</div>')
        .replace(/28세~32세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">28세~32세</div>')
        .replace(/33세~37세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">33세~37세</div>')
        .replace(/38세~42세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">38세~42세</div>')
        .replace(/43세~47세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">43세~47세</div>')
        .replace(/48세~52세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">48세~52세</div>')
        .replace(/53세~57세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">53세~57세</div>')
        .replace(/58세~62세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">58세~62세</div>')
        .replace(/63세~67세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">63세~67세</div>')
        .replace(/68세~72세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">68세~72세</div>')
        .replace(/73세~77세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">73세~77세</div>')
        .replace(/78세~82세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">78세~82세</div>')
        .replace(/10세~14세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">10세~14세</div>')
        .replace(/15세~19세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">15세~19세</div>')
        .replace(/20세~24세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">20세~24세</div>')
        .replace(/25세~29세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">25세~29세</div>')
        .replace(/30세~34세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">30세~34세</div>')
        .replace(/35세~39세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">35세~39세</div>')
        .replace(/40세~44세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">40세~44세</div>')
        .replace(/45세~49세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">45세~49세</div>')
        .replace(/50세~54세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">50세~54세</div>')
        .replace(/55세~59세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">55세~59세</div>')
        .replace(/60세~64세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">60세~64세</div>')
        .replace(/65세~69세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">65세~69세</div>')
        .replace(/70세~74세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">70세~74세</div>')
        .replace(/75세~79세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">75세~79세</div>')
        .replace(/80세~84세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">80세~84세</div>')
        .replace(/83세~87세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">83세~87세</div>')
        .replace(/85세~89세/g, '<div style="display:block; color: #5a5a5a; margin-top: 15px; font-weight: bold">85세~89세</div>')
        .replace(/순풍:/g, '<div style="display:inline-block; font-weight: 600">🎐 순풍 </div> -> ')
        .replace(/역풍:/g, '<div style="display:inline-block; font-weight: 600">🌪️ 역풍</div> -> ')
        .replace(/내용:/g, '<div style="display:inline-block; font-weight: 600">📜 내용</div> -> ')

        .replace(/오늘의 운세/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">🌷 오늘의 운세</strong>')
        .replace(/오늘의 팁:/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">💝 오늘의 팁</strong>')
        .replace(/오늘의 처세/g, '<strong style="display:block; margin-top: 30px; margin-bottom: 5px;">⛑️ 오늘의 처세</strong>')
        .replace(/흉운:/g, '<strong style="display:inline-block; margin-top: 30px; margin-bottom: 5px;">🐦‍⬛ 흉운</strong> -> ')
        .replace(/길운:/g, '<strong style="display:inline-block; margin-top: 0px; margin-bottom: 5px;">💫 길운</strong> -> ')
        .replace(/오늘도 화이팅!:/g, '<strong style="display:block; margin-top: 40px; margin-bottom: -20px;">🍀 오늘의 운세 점수</strong>')


    const tarotCards = [
        "The Fool", "The Magician", "The High Priestess", "The Empress", "The Emperor", "The Hierophant", "The Lovers", "The Chariot",
        "Strength", "The Hermit", "The Wheel of Fortune", "Justice", "The Hanged Man", "Death", "Temperance", "The Devil", "The Tower",
        "The Star", "The Moon", "The Sun", "Judgment", "The World", "Ace of Wands", "Two of Wands", "Three of Wands", "Four of Wands",
        "Five of Wands", "Six of Wands", "Seven of Wands", "Eight of Wands", "Nine of Wands", "Ten of Wands", "Page of Wands",
        "Knight of Wands", "Queen of Wands", "King of Wands", "Ace of Cups", "Two of Cups", "Three of Cups", "Four of Cups", "Five of Cups",
        "Six of Cups", "Seven of Cups", "Eight of Cups", "Nine of Cups", "Ten of Cups", "Page of Cups", "Knight of Cups", "Queen of Cups",
        "King of Cups", "Ace of Swords", "Two of Swords", "Three of Swords", "Four of Swords", "Five of Swords", "Six of Swords",
        "Seven of Swords", "Eight of Swords", "Nine of Swords", "Ten of Swords", "Page of Swords", "Knight of Swords", "Queen of Swords",
        "King of Swords", "Ace of Pentacles", "Two of Pentacles", "Three of Pentacles", "Four of Pentacles", "Five of Pentacles",
        "Six of Pentacles", "Seven of Pentacles", "Eight of Pentacles", "Nine of Pentacles", "Ten of Pentacles", "Page of Pentacles",
        "Knight of Pentacles", "Queen of Pentacles", "King of Pentacles"
    ];

    tarotCards.forEach(card => {
        let regex = new RegExp(card, 'g');
        modifiedText = modifiedText.replace(regex, (match) => {
            return `${match}<br/><img src="/tarot/${match}.jpg" width="80px" style="margin-bottom: -10px" alt="사주천왕 타로"/>`;
        });
    });

    let textToRemovePattern = /생일.*?Z/g;

    modifiedText = modifiedText.replace(textToRemovePattern, function(match) {
        return ''; // '생일'부터 'Z'까지의 텍스트를 제거
    });

    // 15세부터 70세까지 순회하면서 점수를 HTML로 교체
    for (let age = 15; age <= 70; age++) {
        const key = `${age}세 `;
        const regex = new RegExp(`${key}(\\d{1,2})`);  // '{age}세: ' 뒤에 오는 1~2자리 숫자와 '점'을 찾는 정규식

        if (regex.test(modifiedText)) {
            modifiedText = modifiedText.replace(regex, (match, p1) => {
                // 진행 바 UI를 생성하고 키에서 ':'를 제거
                return `
                    <div style="display: flex; align-items: center; justify-content: flex-start; margin-top: 15px; color: #000000">
                        ${age}세
                        <div style="width: 250px; height: 20px; border-radius: 10px; background: #ececec; border: none; margin-left: 10px; position: relative; overflow: hidden">
                            <div style="width: ${p1}%; height: 100%; border-radius: 10px; background: #1491EE;"></div>
                            <span style="position: absolute; top: 50%; left: 8%; transform: translate(-50%, -50%); color: #ffffff; font-weight: normal">${p1}%</span>
                        </div>
                    </div>
                    `;
            });
        }
    }

    const searchString = '<strong style="display:inline-block; margin-top: 10px;">⚫ 전통궁합</strong> ->';
    const firstIndex = modifiedText.indexOf(searchString);
    if (firstIndex !== -1) {
        const secondIndex = modifiedText.indexOf(searchString, firstIndex + 1);
        if (secondIndex !== -1) {
            modifiedText = modifiedText.slice(0, secondIndex) + modifiedText.slice(secondIndex + searchString.length);
        }
    }

    return (
        <>
            {/*<div className="paper-info" style={{backgroundImage: "url(/paper-pattern.svg)"}}>
                <img src="/tokki.svg" width={66}/>
                <h6>김떡국 | 남</h6>
                <time>1995년 12월 22일 · 자시 (09:00 ~ 11:00)</time>
            </div>*/}
            <div className="paper-contents-wrapper">
                <div className="paper-owner">
                    <p>{character?.name}의</p>
                    {
                        character?.type !== 20 ?
                            <b>🧧 종합사주 풀이 🧧</b>
                            :
                            <b>🔮 선택한 타로카드 🔮</b>
                    }
                </div>
                <div className="paper-contents" dangerouslySetInnerHTML={{__html: modifiedText}}/>
            </div>
        </>
    );
}

export default ChatFortune
 