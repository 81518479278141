import React, {useEffect, useRef, useState} from "react";
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import './App.css';
import Header from "./Header";
import Footer from "./Footer"
import InfoFooter from "@src/screens/app/InfoFooter";
import SwipeButtons from "./screens/app/SwipeButtons";
import HomeScreen from "./screens/app/HomeScreen";
import Ting from "./screens/app/Ting";
import DetailScreen from "./screens/app/DetailScreen";
import ChatListScreen from "./screens/chat/ChatListScreen";
import ChatScreen from "./screens/chat/ChatScreen";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./core/commons";
import SigninScreen from "./screens/app/SigninScreen";
import SignupScreen from "./screens/app/SignupScreen";
import EditProfileScreen from "./screens/profile/EditProfileScreen";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";
import { LocalStorageKey, getString, hasAccessToken, httpGet, httpPost, setAccessToken } from "./core/utils";
import { setMe } from "./store/auth";
import { useDispatch } from "react-redux";
import AdminChatHistoryScreen from "./screens/admin/AdminChatHistoryScreen";
import ChatCharacterListScreen from "./screens/chat/ChatCharacterListScreen";
import AdminCharacterScreen from "./screens/admin/AdminCharacterScreen";
import RankingScreen from "./screens/app/RankingScreen";
import ForumIcon from "@material-ui/icons/Forum" ;
import ForgotPasswordScreen from "./screens/app/ForgotPasswordScreen";
import TermsScreen from "./screens/app/TermsScreen";
import PrivacyScreen from "./screens/app/PrivacyScreen";
import RefundScreen from "./screens/app/RefundScreen";
import MoreScreen from "./screens/app/MoreScreen";
import SignoutScreen from "./screens/app/SignoutScreen";
import {Helmet} from "react-helmet";
import CategoryScreen from "./screens/app/CategoryScreen";
import AdminCharacterListScreen from "./screens/admin/AdminCharacterListScreen";
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import AdminImageListScreen from "./screens/admin/AdminImageListScreen";
import FavoriteCharacterListScreen from "./screens/chat/FavoriteCharacterListScreen";
import Payments from "./screens/app/Payments";
import Notification from "./screens/app/Notification";
import SigninNaverScreen from "./screens/app/SigninNaverScreen";
import CharacterStartScreen from "./screens/chat/CharacterStartScreen";
import PointListScreen from "@src/screens/app/PointListScreen";
import ShopModal from "@src/screens/app/ShopModal";
import PassWordEdit from "@src/screens/app/PassWordEdit";
import UserInfo from "@src/screens/app/UserInfo";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ConnectScreen from "./screens/app/ConnectScreen";
import ShopResultModal from "./screens/app/ShopResultModal";
import AdminPaymentListScreen from './screens/admin/AdminPaymentListScreen';
import SigninKakaoScreen from "./screens/app/SigninKakaoScreen";
import AdminConnectScreen from "./screens/admin/AdminConnectScreen";
import TagManager from 'react-gtm-module'

const InApp = require('detect-inapp');
const inapp = new InApp(navigator.userAgent || navigator.vendor);

const gaMeasurementId = "G-FF5J0QE4ED";
ReactGA.initialize(gaMeasurementId);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const history = createBrowserHistory();
history.listen((response: any) => {
  if(response && response.location) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: window.location.pathname,
      },
    });
  }
});


function App() {

  let dispatch = useDispatch();
  let { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    document.title = getString('HomeTitle');
    // ChannelService.loadScript();
    // ChannelService.boot({
    //   "pluginKey": "850b55b0-d581-40cd-a873-4c7219fbcc0f",
    // });

    TagManager.initialize({ gtmId: 'GTM-KQ5QBH7R' });

    //
    if(window.location.pathname.startsWith('/play'))
      ChannelService.hideChannelButton();

    //
    if(hasAccessToken()) {
      //
      httpGet('/api/users/me').then((res) => {
        if(res && res.status >= 0) {
          dispatch(setMe(res.user));
        } else {
          setAccessToken('');
        }
      });
    } else {
      if (inapp.isInApp) {
        let guestToken = window.localStorage.getItem(LocalStorageKey.GUEST_TOKEN);
        if (guestToken) return;

        httpPost('/api/users/signup', {
          isGuest: true,
        })
            .then((res) => {
              if (res && res.status >= 0) {
                setAccessToken(res.token);
                dispatch(setMe(res.me));
                window.localStorage.setItem(LocalStorageKey.GUEST_TOKEN, res.token);
              }
            })
            .catch((error) => {
              alert(`Error during guest signup: ${error}`, );
              // 여기에 에러 처리 로직 추가
            });
      }
    }
  }, []);

  return (
      <div className={`App ${history.location.pathname.includes(`/chat/`) ? "chat-screen" : ""}`} id="goodkingApp">
        <Router>
          <GoogleOAuthProvider
              clientId={GOOGLE_CLIENT_ID}
          >
            <GoogleReCaptchaProvider reCaptchaKey="6Lcydi0pAAAAADfZPvqBBtei3dlCKCKM4EQz3JhO">
              <Switch>
                <Route path="/@:userName" exact>
                  <Header backButton="/chats" title=""/>
                  <ChatCharacterListScreen/>
                </Route>
                <Route path="/chat/:chatHashID" exact>
                  <ChatScreen />
                </Route>
                <Route path="/play/:characterHashID" exact>
                  <Header backButton="/" title=" " rightButton/>
                  <DetailScreen/>
                </Route>
                <Route path="/play/:characterHashID/start" exact>
                  <CharacterStartScreen />
                </Route>
                <Route path="/chats" exact>
                  {/*<Header backButton="/" title=""/>*/}
                  <ChatListScreen/>
                  <Footer/>
                </Route>
                <Route path="/notification" exact>
                  <Header backButton="/" title=""/>
                  <Notification/>
                </Route>
                <Route path="/favorites" exact>
                  <Header backButton="/" title=""/>
                  <FavoriteCharacterListScreen/>
                </Route>
                <Route path="/rankings" exact>
                  <Header backButton="/" title=""/>
                  <RankingScreen/>
                </Route>
                <Route path="/signin/naver" exact>
                  <SigninNaverScreen/>
                </Route>
                <Route path="/signin/kakao" exact>
                  <SigninKakaoScreen/>
                </Route>
                <Route path="/signin" exact>
                  <Header backButton="/" title=""/>
                  <SigninScreen/>
                </Route>
                <Route path="/signin" exact>
                  <SigninScreen/>
                </Route>
                <Route path="/signout" exact>
                  <SignoutScreen/>
                </Route>
                <Route path="/signup" exact>
                  <SignupScreen/>
                </Route>
                <Route path="/password" exact>
                  <PassWordEdit/>
                </Route>
                <Route path="/forgotPassword" exact>
                  <Header backButton="/" title=""/>
                  <ForgotPasswordScreen/>
                </Route>
                <Route path="/categories" exact>
                  <Header backButton="/" title=""/>
                  <CategoryScreen/>
                </Route>
                <Route path="/terms" exact>
                  <Header backButton="/more" title="서비스 이용약관"/>
                  <TermsScreen/>
                </Route>
                <Route path="/userInfo" exact>
                  <Header backButton="/more" title="회원정보"/>
                  <UserInfo/>
                </Route>
                <Route path="/point" exact>
                  <Header backButton="/" title=""/>
                  <PointListScreen/>
                </Route>
                <Route path="/privacy" exact>
                  <Header backButton="/more"  title="개인정보 보호정책"/>
                  <PrivacyScreen/>
                </Route>
                <Route path="/refund" exact>
                  <Header backButton="/more" title="환불정책"/>
                  <RefundScreen/>
                </Route>
                <Route path="/profile" exact>
                  <Header backButton="/more" title="생년월일 입력"/>
                  <EditProfileScreen/>
                </Route>
                <Route path="/profile/edit" exact>
                  <Header backButton="/" title=""/>
                  <EditProfileScreen/>
                </Route>
                <Route path="/more" exact>
                  {/*<Header backButton="/" title=""/>*/}
                  <MoreScreen/>
                  <Footer/>
                </Route>
                <Route path="/payments" exact>
                  <Header backButton="/more" title="금화충전/사용내역"/>
                  <Payments/>
                </Route>
                <Route path="/shop" exact>
                  <Header backButton="/" title="금화충전"/>
                  <ShopModal/>
                  <Footer/>
                </Route>
                <Route path="/shop/result/:result" exact>
                  <ShopResultModal/>
                </Route>
                <Route path="/c/:type" exact>
                  <ConnectScreen/>
                </Route>
                <Route path="/" exact>
                  <HomeScreen/>
                  <Footer/>
                </Route>
                <Route path="/ting" exact>
                  <Ting/>
                  <Footer/>
                </Route>
                <Route path="/admin/characters" exact>
                  <Header backButton="/more" title="관리자" rightButton={true}/>
                  <AdminCharacterListScreen/>
                </Route>
                <Route path="/admin/images" exact>
                  <Header backButton="/more" title=""/>
                  <AdminImageListScreen/>
                </Route>
                <Route path="/admin/histories" exact>
                  <AdminChatHistoryScreen/>
                </Route>
                <Route path="/admin/characters/:characterHashID" exact>
                  <Header backButton="/admin/characters" title=""/>
                  <AdminCharacterScreen/>
                </Route>
                <Route path="/admin/connect/:signupType" exact>
                  <AdminConnectScreen/>
                </Route>
                <Route path="/admin/payments" exact>
                  <AdminPaymentListScreen/>
                </Route>
              </Switch>
            </GoogleReCaptchaProvider>
          </GoogleOAuthProvider>
        </Router>
      </div>
  );
}

export default App;