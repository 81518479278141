import React, { useEffect, useRef, useState } from 'react'
import "./HomeScreen.css";
import {httpGet, httpPost} from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICharacter, IUser, IUserCashReceipt } from '../../shared/types';
import { Link, useHistory } from 'react-router-dom';
import {Avatar, Drawer, Grid, makeStyles, Paper, Box} from '@material-ui/core';
import { getString } from "../../core/utils";
import InfoFooter from "@src/screens/app/InfoFooter";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { setMe } from '@src/store/auth';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Carousel from 'react-material-ui-carousel';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';


const yaml = require('js-yaml');

enum Tab {
    FORTUNE = 'fortune',
    ALL = 'all',
    RECOMMEND = 'recommend',
    FREE = 'free',
}
const carouselItems = [
    {
        name: "First Item",
        description: "Description for first item",
    },
    {
        name: "Second Item",
        description: "Description for second item",
    },
];

function HomeScreen() {
    const history = useHistory();
    const [characters, setCharacters] = useState<ICharacter []>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const [order, setOrder] = useState('popular');
    const [keyword, setKeyword] = useState('');
    const [tag, setTag] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('fortune')
    const [todayFortuneOpen, setTodayFortuneOpen] = useState(false)
    const dispatch = useDispatch();
    const [modifiedText, setModifiedText] = useState('');
    const [oneLineText, setOneLineText] = useState('');

    function refresh(reset = true) {
        let _offset = 0;
        if (!reset) {
            _offset = characters.length;
        }

        // 데이터 가져오기 시작 전에 로딩을 true로 설정한다
        setLoading(true);

        let type = 'all';
        if(tab == Tab.FREE)
            type = 'free';
        else if(tab == Tab.RECOMMEND)
            type = 'recommend';

        httpGet('/api/characters/search', {
            order: order,
            keyword: keyword,
            tag: tag,
            type: type,
            offset: _offset,
        })
            .then((res) => {
                if (res.status >= 0) {
                    if (reset) {
                        setCharacters(res.characters.slice(0, 20)); // Only take the first 20 characters for initial load
                    } else {
                        // Add only up to 20 new characters when fetching additional data
                        const newCharacters = res.characters.slice(0, 20);
                        setCharacters([...characters, ...newCharacters]);
                    }
                }
            })
            .catch((error) => {
                console.error("데이터 가져오기 오류: ", error);
            })
            .finally(() => {
                // Set loading to true as soon as loading is done
                setLoading(true);

                // Set a timeout to change loading to false after 0.5 seconds
                setTimeout(() => {
                    setLoading(false);
                }, 500); // 500 milliseconds = 0.5 seconds
            });
    }

    useEffect(() => {
        setTodayFortuneOpen(false)
    }, []);

    useEffect(() => {
        refresh();
    }, [order, tag, tab]);

    useEffect(() => {
        window.scrollTo(0, -50);
    })

    const [showCheckIn, setShowCheckIn] = useState(false)

    useEffect(() => {
        if(me && !me.checkinToday) {
            setShowCheckIn(true);
        } else {
            setShowCheckIn(false);
        }

        if(me) {
            setModifiedText(JSON.stringify(me?.todayFortune || {}, null, 4)
                .replace(/[{}",]/g, '')
                .replace(/Name:/g,'이름: ')
                .replace(/Sex:/g,'성별: ')
                .replace(/(female|male)/g, function(match) {return match === 'female' ? '여성' : '남성';})
                .replace(/Age:/g,'나이: ')
                .replace(/Time:/g,'생시: ')
                .replace(/사주명식:/g, '<strong style="display:block; margin-top: 30px;">사주명식</strong>')
                .replace(/만세력:/g, '<strong style="display:block; margin-top: 30px;">만세력</strong>')
                .replace(/오행분포:/g, '<strong style="display:block; margin-top: 30px;">오행분포</strong>')
                .replace(/날짜: /g, '<strong style="display:block; margin-top: 20px; font-weight: bold;">날짜</strong>')
                .replace(/오늘의 운세: /g, '<strong style="display:block; margin-top: 20px; margin-bottom: 7px; font-weight: bold; color: #000000D9; font-size: 16px;">🧧 오늘의 운세</strong>')
                .replace(/오늘의 팁: /g, '<strong style="display:block; margin-top: 20px; margin-bottom: 7px; font-weight: bold; color: #000000D9; font-size: 16px">🔮 오늘의 팁</strong>')
                .replace(/오늘의 처세: /g, '<strong style="display:block; margin-top: 20px; margin-bottom: 7px; font-weight: bold; color: #000000D9; font-size: 16px">🪭 오늘의 처세</strong>')
                .replace(/운세: /g, '')
                .replace(/운세:/g, '')
                .replace(/흉운: /g, '<strong style="display:block; margin-top: 20px; margin-bottom: 7px; font-weight: bold; color: #000000D9; font-size: 16px">😔 흉운</strong>')
                .replace(/길운: /g, '<strong style="display:block; margin-top: 20px; margin-bottom: 7px; font-weight: bold; color: #000000D9; font-size: 16px">🤩 길운</strong>')
                .replace(/세:/g, '세')
                .replace(/\\n/g, '<strong style="display:block; margin-bottom: 5px"></strong>')
                .replace(/\\/g, '')
                .replace(/---/g, '<div style="margin-top: 20px"/>')
                .replace(/이름(.*?)날짜/g, '')); // "start"부터 "end"까지의 모든 내용을 삭제


            setOneLineText(JSON.stringify(me?.todayFortune || {}, null, 4)
                .replace(/[{}",]/g, '')
                .replace(/Name:/g,'이름: ')
                .replace(/\\n/g, '')
                .replace(/:/g, '')
                .replace(/\\/g, '')
                .replace(/---/g, '')
                .replace(/이름(.*?)오늘의 운세/g, '')); // "start"부터 "end"까지의 모든 내용을 삭제
        }
    }, [me]);

    /*쿠팡 파트너스*/
    /*useEffect(() => {
        const adBanner = document.getElementById('adBanner');
        const script = document.createElement('script');
        script.src = "https://ads-partners.coupang.com/g.js";
        script.async = true;

        if (adBanner) {
            adBanner.appendChild(script);
        }

        script.onload = () => {
            // 스크립트 로드 후 쿠팡 파트너스 광고 생성
            new (window as any).PartnersCoupang.G({
                "id": 777178,
                "template": "carousel",
                "trackingCode": "AF8611176",
                "width": "680",
                "height": "140",
                "tsource": ""
            });
        };

        return () => {
            if (adBanner) {
                adBanner.removeChild(script);
            }
        };
    }, []);*/

    const today = new Date();

    const todayMonth = today.getMonth() + 1;

    const todayDate = today.getDate();

    const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
    const todayDay = daysOfWeek[today.getDay()];

    const [topBanner, setTopBanner] = useState(true)

    const slider = document.querySelector('.another-characters') as HTMLElement;

    if (slider) {
        let isDown = false;
        let startX: number;
        let scrollLeft: number;

        const start = (e: MouseEvent | TouchEvent) => {
            isDown = true;
            slider.classList.add('active');
            startX = 'touches' in e ? e.touches[0].pageX : e.pageX;
            scrollLeft = slider.scrollLeft;
        };

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const move = (e: MouseEvent | TouchEvent) => {
            if (!isDown) return;
            e.preventDefault();
            const x = 'touches' in e ? e.touches[0].pageX : e.pageX;
            const walk = (x - startX) * 1.5;
            slider.scrollLeft = scrollLeft - walk;
        };

        // 마우스 이벤트
        slider.addEventListener('mousedown', start);
        slider.addEventListener('mouseleave', end);
        slider.addEventListener('mouseup', end);
        slider.addEventListener('mousemove', move);

        // 터치 이벤트
        slider.addEventListener('touchstart', start);
        slider.addEventListener('touchend', end);
        slider.addEventListener('touchcancel', end);
        slider.addEventListener('touchmove', move);
    }

    function attendGold() {
        setShowCheckIn(false);

        //
        httpPost('/api/users/checkin', {}).then((res) => {
            httpGet('/api/users/me').then((res) => {
                if(res && res.status >= 0) {
                    dispatch(setMe(res.user));
                }
            });
        });

        //
        window.open('https://link.coupang.com/a/bFyyrb', '_blank');
    }

    const [isBlur, setIsBlur] = useState(true);

    const [fortuneNumber, setFortuneNumber] = useState<number | null>(null);

    useEffect(() => {
        if (me?.todayFortune) {
            // 정규 표현식을 사용하여 "흉운: "과 "%" 사이의 숫자를 추출
            const match = me.todayFortune.match(/길운:\s*(\d+)%/);
            if (match && match[1]) {
                setFortuneNumber(parseFloat(match[1]));
            }
        }
    }, [me]);

    if (me?.signupType === "richgo") {
        const index = characters.findIndex(character => character.hashID === "k8llg8");
        if (index !== -1) {
            const [richgoCharacter] = characters.splice(index, 1);
            characters.unshift(richgoCharacter);
        }
    }

    const useStyles = makeStyles({
        navButtons: {
            backgroundColor: '#ff8576 !important',
            borderRadius: '50% !important',
            opacity: '1 !important',
            '&:hover': {
                backgroundColor: '#ff8576 !important',
                opacity: '1 !important',
            },
        },
        navButtonsWrapper: {
            bottom: '0 !important',
            top: 'unset !important',
            opacity: '1 !important',
            '&:hover': {
                opacity: '1 !important',
            },
        },
    });

    const classes = useStyles();

    return (
        <div className="page-contents-wrapper" id="homeScreen">
            {
                !me &&
                <div className={`login-plz ${topBanner ? "" : "d-none"}`}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <CloseOutlined onClick={() => setTopBanner(false)}/>
                        <p style={{ display: 'flex', alignItems: 'center', fontSize: '15px'}}>지금 시작하고 최대 &nbsp; <img src="./gold.svg" width={16} height={16} style={{marginRight: '3px'}} alt="사주천왕 금화"/> <span style={{color: '#FF5471'}}>6금화 &nbsp;</span> 받아가세요</p>
                    </div>
                    <button onClick={() => history.push('/signin')}>로그인</button>
                </div>
            }


            <div style={{padding: '24px 18px 0px', backgroundColor: '#ffffff', borderBottom: '8px solid #F7F8FA', boxSizing: 'border-box'}}>
                {
                    me && !(me?.signupType == 'richgo') &&
                    <>
                        {/*<div className="home-my-info">
                            <p>
                                {me?.name} · {me?.birthday?.year}년 {me?.birthday?.month}월 {me?.birthday?.day}일({me?.birthday?.yang === true ? "양력" : "음력"})
                            </p>
                            <ModeEditRoundedIcon onClick={() => history.push('/profile')}/>
                        </div>*/}
                        <div className="today-fortune">
                            <div className="today-fortune-contents">
                                <div className="today-fortune-text">
                                    <span className="today-text">{todayMonth}월 {todayDate}일({todayDay}) 오늘의 운세</span>
                                    <span className="name-text">{me?.name ? me?.name + '님' : "안녕하세요!"}</span>
                                    {
                                        me?.todayFortune && me?.todayFortune !== 'Cannot calculate today fortune.' ?
                                            <p dangerouslySetInnerHTML={{ __html : oneLineText}}/>
                                            :
                                            <p>안녕하세요! 오늘 당신의 하루를 알아보세요!</p>
                                    }
                                </div>
                                <div className="today-fortune-score">
                                    <GradientCircularProgressbar
                                        fortuneNumber={fortuneNumber !== null ? fortuneNumber : 0}
                                    />
                                </div>
                            </div>
                            <button className="go-to-detail"  onClick={() => {me?.todayFortune && me?.todayFortune !== 'Cannot calculate today fortune.' ? setTodayFortuneOpen(true) : history.push('/profile');}}>
                                {
                                    me?.todayFortune && me?.todayFortune !== 'Cannot calculate today fortune.' ?
                                        '자세히 알아보기' : '사주정보 입력하기'
                                }
                            </button>
                        </div>
                    </>
                }
            </div>

            <div style={{padding: '24px 18px 16px', backgroundColor: '#ffffff', boxSizing: 'border-box'}}>
                <p style={{padding: '0px 6px', fontSize: '20px', fontWeight: '700', color: 'rgba(0,0,0,0.85)', marginBottom: '10px'}}>
                    {
                        tab == Tab.FORTUNE &&
                        '당신의 인생질문을 모두 해소해드립니다'
                    }
                    {
                        tab == Tab.ALL &&
                        '지금 바로 상담이 필요하다면!'
                    }
                    {
                        tab == Tab.RECOMMEND &&
                        '사주천왕이 강력 추천합니다'
                    }
                    {
                        tab == Tab.FREE &&
                        '무료로 사주천왕을 경험해보세요'
                    }
                    {/*{me ? me?.name + '님 반가워요 :)' : ''}*/}
                </p>
                <p style={{padding: '0px 6px', fontSize: '14px', fontWeight: '400', color: 'rgba(0,0,0,0.45)'}}>
                    {
                        tab === Tab.FORTUNE &&
                        <div>
                            현재 이용가능 궁금 🪭 <b><span style={{color: '#ED503C'}}>{characters.filter(character => character.isUnse).length}</span>개</b>
                        </div>
                    }

                    {
                        tab === Tab.ALL &&
                        <div>
                            현재 이용가능 채팅 💝 <b><span style={{color: '#ED503C'}}>{characters.filter(character => !character.isUnse).length}</span>개</b>
                        </div>
                    }
                    {/*{
                        tab === Tab.RECOMMEND &&
                        '추천'
                    }*/}
                    {
                        tab === Tab.FREE &&
                        <div>
                            현재 이용가능 무료 🎁 <b><span style={{color: '#ED503C'}}>{characters.filter(character => character.cashPrice === 0).length}</span>개</b>
                        </div>
                    }
                </p>
            </div>
            <div className="character-tabs">
                <div className={`tab ${tab === Tab.FORTUNE ? "select" : ""}`} onClick={() => setTab(Tab.FORTUNE)}>궁금 🪭</div>
                <div className={`tab ${tab === Tab.ALL ? "select" : ""}`} onClick={() => setTab(Tab.ALL)}>채팅 💝</div>
                {/*<div className={`tab ${tab === Tab.RECOMMEND ? "select" : ""}`} onClick={() => setTab(Tab.RECOMMEND)}>추천 🌹</div>*/}
                <div className={`tab ${tab === Tab.FREE ? "select" : ""}`} onClick={() => setTab(Tab.FREE)}>무료 🎁</div>
            </div>
            {/*<div id="adBanner"/>*/}
            <div id="characterList">
                {tab !== Tab.FORTUNE && characters
                    .filter(c => !c?.isUnse) // isUnse가 false인 항목만 필터링
                    .map((c) => (
                        <Grid item key={c.hashID}>
                            {c &&
                                <Link to={`/play/${c.hashID}`} style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', padding: '18px 16px', boxSizing: 'border-box'}}>
                                    <div style={{position: 'relative', display: 'flex', alignItems: 'flex-end', maxWidth: '126px', minWidth: '126px', width: '126px', height: '126px', padding: '8px', backgroundImage: `url('${c.imageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px'}}>
                                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 8px', fontSize: '11px', fontWeight: '400', color: '#ffffff', borderRadius: '4px', backgroundColor: 'rgba(0,0,0,0.95)' }}>채팅무료 <b style={{marginLeft: '2px', fontWeight: '600', color: '#FF5471'}}>{c.freeChatCount}회</b></span>
                                    </div>
                                    <div className="character-info">
                                        <div className="category-title">
                                            <span className="category">{c?.categoryName}</span>
                                            <span className="character-list--item" dangerouslySetInnerHTML={{ __html: c.name }}/>
                                        </div>
                                        <p className="description">{c?.desc}</p>
                                        <div className="pricing">
                                            <img src="./gold.svg" width={16} height={16} style={{marginRight: '4px', marginTop: '1px'}} alt="사주천왕 금화"/>
                                            {
                                                c.cashPrice.toLocaleString() == '0' ?
                                                    <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>무료</b>
                                                    :
                                                    <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>{c.cashPrice.toLocaleString()}금화</b>
                                            }
                                        </div>
                                        <div className="tags">
                                            {c?.tags?.map(x => <span>{getString('#' + x.name)}</span>)}
                                        </div>
                                    </div>
                                </Link>}
                        </Grid>
                    ))
                }
                {
                    tab === Tab.FORTUNE &&
                    <Box sx={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
                        <Carousel
                            interval={5000}
                            indicators={true}
                            navButtonsAlwaysVisible={false}
                            className="recommend-fortunes"
                            /*navButtonsProps={{
                                className: classes.navButtons,
                            }}*/
                            indicatorContainerProps={{
                                style: {
                                    zIndex: 100
                                }
                            }}
                            indicatorIconButtonProps={{
                                style: {
                                    color: 'rgba(0,0,0,0.38)'
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: 'rgb(255,255,255)'
                                }
                            }}
                            navButtonsWrapperProps={{
                                className: classes.navButtonsWrapper,
                            }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& .MuiPaper-root': {
                                    flex: '0 0 80%', // Adjust this percentage to control the visible portion of next/previous slides
                                    margin: '0 10px', // Adjust the margin to control spacing between slides
                                }
                            }}
                        >
                            {characters
                                .filter(item => item?.isUnse === true)
                                .map((item, i) => (
                                    <Link to={`/play/${item?.hashID}`} key={i}>
                                        <Box sx={{ flex: '0 0 80%', margin: '0 10px', padding: 2, textAlign: 'center' }}>
                                            <Paper
                                                className={`each-fortune fortune-${i}`}
                                                style={{
                                                    margin: '0px',
                                                    backgroundImage: `url(${item?.unseAdImageURL})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                    height: 300, // Adjust height as needed
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                    </Link>
                                ))}
                        </Carousel>
                    </Box>
                }
                {tab === Tab.FORTUNE && characters
                    .filter(c => c?.isUnse) // isUnse가 true인 항목만 필터링
                    .map((c) => (
                        <Grid item key={c.hashID}>
                            {c &&
                                <Link to={`/play/${c.hashID}`} style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', padding: '18px 16px', boxSizing: 'border-box'}}>
                                    <div style={{position: 'relative', display: 'flex', alignItems: 'flex-end', minWidth: '155px', width: '155px', height: '124px', minHeight: '124px', padding: '8px', backgroundImage: `url('${c.unseImageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px', boxSizing: 'border-box'}}>
                                        {/*<span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 8px', fontSize: '11px', fontWeight: '400', color: '#ffffff', borderRadius: '4px', backgroundColor: 'rgba(0,0,0,0.95)' }}>자유채팅 <b style={{marginLeft: '2px', fontWeight: '600', color: '#FF5471'}}>{c.freeChatCount}회</b></span>*/}
                                    </div>
                                    <div className="character-info unse">
                                        <div className="category-title">
                                            <span className="category">{c?.categoryName}</span>
                                            <span className="character-list--item" style={{marginRight: '-5px'}}>by</span>
                                            <span className="character-list--item" dangerouslySetInnerHTML={{ __html: c.name }}/>
                                        </div>
                                        <b className="character-list--unse" dangerouslySetInnerHTML={{ __html: c.unseName }}/>
                                        <p className="description" style={{margin: '10px 0px 15px', textOverflow: 'unset', overflow: 'unset', maxWidth: '85%'}}>{c?.desc}</p>
                                        <div className="bottom-line">
                                            <div className="pricing">
                                                <img src="./gold.svg" width={16} height={16} style={{marginRight: '4px', marginTop: '1px'}} alt="사주천왕 금화"/>
                                                {
                                                    c.cashPrice.toLocaleString() == '0' ?
                                                        <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>무료</b>
                                                        :
                                                        <div>
                                                        <s style={{fontSize: '13px', color: '#666666'}}>10</s><b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}> {c.cashPrice.toLocaleString()}금화</b><span style={{fontSize: '10px', color: '#0057f8'}}>기간한정</span>
                                                        </div>
                                                }
                                            </div>
                                            <div className="star-point">
                                                ⭐<span>{(c?.starPoint ?? 0).toFixed(2).replace(/\.?0+$/, '')}</span>
                                            </div>
                                        </div>
                                        {/*<div className="tags">
                                            {c?.tags?.map(x => <span>{getString('#' + x.name)}</span>)}
                                        </div>*/}
                                    </div>
                                </Link>}
                        </Grid>
                    ))
                }
                <div className="sns-wrapper">
                    <div className="sns" onClick={() => window.open('https://instagram.com/goodking.ai', '_blank')}>
                        <img src="./instagramLogo.png" alt="사주천왕 인스타그램"/>
                        <h6>사주천왕 인스타</h6>
                        <p>@goodking.ai</p>
                    </div>
                    <div className="sns" onClick={() => window.open('https://youtube.com/@_goodking', '_blank')}>
                        <img src="./youtubeLogo.png" alt="사주천왕 유튜브"/>
                        <h6>사주천왕 유튜브</h6>
                        <p>@_goodking</p>
                    </div>
                </div>
                <div className="MuiGrid-root MuiGrid-item" style={{width: '100%', height: '165px', borderTop: 'none'}}>
                    <h1 style={{visibility: 'hidden'}}>사주천왕</h1>
                </div>
            </div>


            <Drawer
                anchor="bottom"
                open={todayFortuneOpen}
                onClose={() => setTodayFortuneOpen(false)}
                style={{position: 'relative', boxSizing: 'border-box', borderRadius: '0px'}}
                id="todayFortunePaperWrapper"
            >
                <div id="todayFortunePaper">
                    <div className="header">
                        <div className="left-side" style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 24px'}}>
                            <h6>오늘의 운세</h6>
                            <CloseOutlined onClick={() => setTodayFortuneOpen(false)} style={{cursor: 'pointer'}}/>
                        </div>
                    </div>
                    <div className="today-contents">
                        <>
                            <div className="my-info">
                                {me?.name} · {me?.birthday?.year}년 {me?.birthday?.month}월 {me?.birthday?.day}일 ({JSON.stringify(me?.birthday?.yang) ? "양력" : "음력"})
                            </div>
                            <div className="fortune-info" dangerouslySetInnerHTML={{ __html : modifiedText }}/>
                        </>
                        {/*{
                            isBlur ?
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <img src="/today-blur-min.png" alt="사주천왕 오늘의운세" width="100%"/>
                                    <button onClick={() => {setIsBlur(false); window.open('https://link.coupang.com/a/bEnDOQ', '_blank');}} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '50px', borderRadius: '8px', border: 'none', backgroundColor: '#3385FF', fontSize: '16px', fontWeight: '600', color: '#ffffff', marginTop: '30px', cursor: 'pointer'}}>
                                        광고보고 오늘의 운세 무료로 확인하기
                                    </button>
                                    <p  style={{ fontSize: '10px', fontWeight:'400', color: '#00000073', marginTop: '10px', marginBottom: '30px'}}>
                                        해당 링크는 쿠팡 파트너스 활동의 일환으로, 일정액의 수수료를 제공받습니다.
                                    </p>
                                </div>
                                :
                                <>
                                    <div className="my-info">
                                        {me?.name} · {me?.birthday?.year}년 {me?.birthday?.month}월 {me?.birthday?.day}일 ({JSON.stringify(me?.birthday?.yang) ? "양력" : "음력"})
                                    </div>
                                    <div className="fortune-info" dangerouslySetInnerHTML={{ __html : modifiedText }}/>
                                    <div className="share-fortune">
                                    <p>운세가 마음에 들었나요?</p>
                                    <sub>지금 친구에게 공유해보세요!</sub>
                                    <div className="share-images">
                                        <div className="share kakao">
                                            <img src="/kakao-share.svg" alt=""/>
                                        </div>
                                        <div className="share">
                                            <img src="/share.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                </>
                        }*/}
                        <div className="another-character-wrapper">
                            <h6>아쉬우신가요? 실시간 상담으로 해결하세요!</h6>
                            <div className="another-characters">
                                {characters.map((c) => (
                                    c && !c?.isUnse &&
                                    <Grid item key={c.hashID}>
                                        {c && !c?.isUnse &&
                                            <Link to={`/play/${c.hashID}`} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', boxSizing: 'border-box'}} id="todayCharacters">
                                                <div style={{width: '130px', minHeight: '130px', backgroundImage: `url('${c.imageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px'}}/>
                                                <div className="character-info">
                                                    <div className="category-title" style={{marginBottom: '0px'}}>
                                                        <span className="character-list--item" style={{color: '#000000D9', marginBottom: '5px'}} dangerouslySetInnerHTML={{ __html: c.name }}/>
                                                    </div>
                                                    <p className="description" style={{ fontSize: '14px', fontWeight: '400', marginBottom: '18px', height: '34px'}}>{c?.desc}</p>
                                                    <button style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: '#ED503C', borderRadius: '4px', width: '100%', height: '32px', padding: '0px 12px', color: '#ffffff', }}>대화 시작하기</button>
                                                </div>
                                                <p className="description" style={{ fontSize: '14px', fontWeight: '400', marginBottom: '18px', height: '34px'}}>{c?.desc}</p>
                                                <button style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: '#ED503C', borderRadius: '4px', width: '100%', height: '32px', padding: '0px 12px', color: '#ffffff', }}>대화 시작하기</button>
                                            </Link>
                                        }
                                    </Grid>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <InfoFooter/>
            {/* {
                drawerOpen &&
                <Drawer
                    anchor="bottom"
                    open={true}
                    onClose={() => setDrawerOpen(false)}
                >
                    <div className="character-info" style={{height: '335px', backgroundImage: `url('/beta-banner.jpg')`, backgroundSize: 'contain', backgroundPosition: 'center',}}>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSddZbWKnI8k4hIiUis8Y7jsLTXguGTV3c0ItvdgfAXVo_C1uQ/viewform" target="_blank" style={{display: 'block', width: '100%', height: '100%'}}/>
                    </div>
                    <div className="btn-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '45px', padding: '0px 16px'}}>
                        <button style={{border: 'none', backgroundColor: 'transparent', fontSize: '15px', fontWeight: '400', color: '#00000073', cursor: 'pointer'}} onClick={() => handleIgnoreForDay()}>오늘은 그만보기</button>
                        <button style={{border: 'none', backgroundColor: 'transparent', fontSize: '15px', fontWeight: '600', color: '#000000D9', cursor: 'pointer'}} onClick={() => setDrawerOpen(false)}>닫기</button>
                    </div>
                </Drawer>
            }*/}


            {
                me?.hashID && showCheckIn && !(me?.signupType == 'richgo') &&
                <Drawer
                    anchor="bottom"
                    open={true}
                    onClose={() => setShowCheckIn(false)}
                    style={{position: 'relative', boxSizing: 'border-box'}}
                >
                    <div style={{height: '28px', width: '100%', backgroundColor: '#D9F2FA'}}/>
                    <div className="character-info" style={{height: '305px', backgroundImage: `url('/daily-promotion.jpeg')`, backgroundSize: 'contain', backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundColor: '#D9F2FA', border: 'none'}}/>
                    <div style={{position: 'absolute', bottom: '10px', boxSizing: 'border-box', width: '100%', padding: '0px 16px'}}>
                        <button style={{width: '100%', border: 'none', fontSize: '16px', fontWeight: '600', color: '#ffffff', backgroundColor: '#ED503C', cursor: 'pointer', height: '48px', textAlign: 'center', borderRadius: '8px'}} onClick={() => attendGold()}>
                            광고보고 출석 금화받기
                        </button>
                    </div>
                </Drawer>
            }

            {/*<iframe
                src="https://goodking.ai/"
                width="100%"
                height="800px"
                style={{position: 'fixed', top: '0px', left: '0px'}}
            />*/}

        </div>
    )
}
// @ts-ignore
export default HomeScreen

interface GradientCircularProgressbarProps {
    fortuneNumber: number;
}

const GradientCircularProgressbar: React.FC<GradientCircularProgressbarProps> = ({ fortuneNumber }) => (
    <div style={{ position: 'relative', width: '105px', height: '105px' }}>
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor="#F9533E" />
                    <stop offset="100%" stopColor="#F38375" />
                </linearGradient>
            </defs>
        </svg>
        <CircularProgressbar
            value={fortuneNumber}
            text={`${fortuneNumber}%`}
            strokeWidth={15}
            styles={buildStyles({
                textSize: '18px',
                pathColor: 'url(#gradient)',
                textColor: '#000000',
                trailColor: '#FFE1DD',
                backgroundColor: '#3e98c7',
            })}
        />
    </div>
);

export { GradientCircularProgressbar };