import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, gotoSignin, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { IBirthday, ICategory, ICharacter, IUser, IUserAttachment } from '../../shared/types';
import { setMe } from '../../store/auth';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageList: {
    width: 500,
    height: 450,
  },
}));

function EditProfileScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [gender, setGender] = useState('male');
  const [yang, setYang] = useState(true);
  const [sangsi, setSangsi] = useState(true);
  const [birthdayYear, setBirthdayYear] = useState(2000);
  const [birthdayMonth, setBirthdayMonth] = useState(1);
  const [birthdayDay, setBirthdayDay] = useState(1);
  const [birthdayHour, setBirthdayHour] = useState(12);
  const [birthdayMinute, setBirthdayMinute] = useState(0);
  const [mbti, setMbti] = useState('ISTJ')
  const fullNameRegex = /^[가-힣]+$/;

  //
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const uploadedImage = React.useRef<HTMLImageElement>(null);
    const imageUploader = React.useRef<HTMLInputElement>(null);
    const uploadImageFile = React.useRef<File>();
    const dispatch = useDispatch();

    let birthday: IBirthday = {
        name: name,
        year: birthdayYear,
        month: birthdayMonth,
        day: birthdayDay,
        yang: yang, // 필요에 따라 입력 받음
        sex: gender === 'male',
        sangsi: sangsi, // 필요에 따라 입력 받음
        hour: birthdayHour,
        minute: birthdayMinute,
        birthplace: true, // 필요에 따라 입력 받음
        mbti: mbti
    };

  //
  function edit() {
      //
      birthday.name = name;
      birthday.year = birthdayYear;
      birthday.month = birthdayMonth;
      birthday.day = birthdayDay;
      birthday.hour = birthdayHour;
      birthday.minute = birthdayMinute;
      birthday.yang = yang;
      birthday.sex = gender == 'male';
      birthday.sangsi = sangsi;
      birthday.mbti = mbti;

      //
      const formData = new FormData();
      if(uploadImageFile.current)
        formData.set('image', uploadImageFile.current);
      formData.set('name', name);
      formData.set('mbti', mbti);
      formData.set('birthday', JSON.stringify(birthday));

      //
      httpPostFormData('/api/users/edit', formData).then((res) => {
          if (res.status >= 0) {
              dispatch(setMe(res.me));
              history.replace('/more');
          }
      });

      return false;
  }

  const handleImageUpload = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      uploadImageFile.current = file;

      //
      reader.onload = e => {
        uploadedImage.current!.src = e.target?.result as any;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if(!me)
      gotoSignin(false);
    else {
      setName(me.name);
      setGender(me.birthday && (me.birthday?.sex ? 'male' : 'female') || '');
      setYang(me.birthday && me?.birthday?.yang ? true : false);
      setSangsi(me.birthday && me?.birthday?.sangsi ? true : false);
    }
  }, []);

  useEffect(() => {
      if (me && me?.birthday) {
          setBirthdayYear(me?.birthday.year ?? 2000);
          setBirthdayMonth(me?.birthday.month ?? 1);
          setBirthdayDay(me?.birthday.day ?? 1);
          setBirthdayHour(me?.birthday.hour ?? 12);
          setBirthdayMinute(me?.birthday.minute ?? 0);
          // 성별 상태 업데이트
          setGender(me?.birthday.sex ? 'male' : 'female');
          // 음양 상태 업데이트
          setSangsi(me?.birthday.sangsi);
          setMbti(me.birthday.mbti ?? '');
      }
  }, [me]);

    return (
    <>
    <Box sx={{flexGrow: 1}}>
        <form onSubmit={(e) => {e.preventDefault();edit();}} style={{ marginTop: '40px', padding: '0px 24px'}}>
            <div className="form-wrapper">
                <FormGroup className={classes.root}>
                    {/*<FormControl component="fieldset" style={{display:'flex', justifyContent: 'center', marginBottom: '30px'}}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                                display: "none"
                            }}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: "120px",
                                height: "120px",
                                margin: "0px auto",
                                borderRadius: '100px',
                                textAlign: "center",
                                cursor:"pointer",
                                overflow: "hidden"
                            }}
                            onClick={() => imageUploader.current!.click()}
                        >
                            <EditOutlinedIcon id="editImage"/>
                            <img
                                src={me?.imageURL}
                                ref={uploadedImage}
                                style={{
                                    width: "120px",
                                    height: "120px",
                                    filter: 'brightness(60%)'
                                }}
                            />
                        </div>
                    </FormControl>*/}
                    {/*<div className="input-wrapper">
                        <label>{getString('Signup_Username')}</label>
                        <input className={`${email !== '' && !emailRegex.test(email) ? "error" : ""}`} type="email" value={email} placeholder="이메일 입력" onChange={(e) => setEmail(e.target.value)} autoComplete="email"/>
                        {
                            email !== '' && !emailRegex.test(email) &&
                            <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                        }
                    </div>*/}
                    <div className="global-input-wrapper">
                        <label>{getString('Signup_Username')}</label>
                        <input className={`${name !== '' && !fullNameRegex.test(name) ? "error" : ""}`} type="text" defaultValue={name} placeholder="이름을 입력하세요" onChange={(e) => setName(e.target.value)}/>
                        {
                            name !== '' && !fullNameRegex.test(name) &&
                            <p className="error-text">올바르지 않은 이름 형식입니다</p>
                        }
                    </div>
                    <div className="global-input-wrapper">
                        <label>성별</label>
                        <div className="global-radio-wrapper">
                            <button type="button" className={`${gender === 'male' ? "clicked" : ""}`} onClick={() => setGender('male')}>남성</button>
                            <button type="button" className={`${gender === 'female' ? "clicked" : ""}`} onClick={() => setGender('female')}>여성</button>
                        </div>
                    </div>
                    <div className="global-input-wrapper">
                        <label>생년월일</label>
                        <div className="birthday-form">
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <TextField
                                    type="text"
                                    value={birthdayYear ? String(birthdayYear) : ''}
                                    style={{marginRight: '5px'}}
                                    onChange={(e) => {
                                        const year = Number(e.target.value);
                                        setBirthdayYear(year);
                                    }}
                                />
                                년
                            </div>
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <TextField
                                    type="text"
                                    value={birthdayMonth ? String(birthdayMonth).padStart(2, '0') : ''}
                                    style={{marginRight: '5px'}}
                                    onChange={(e) => {
                                        const month = Number(e.target.value);
                                        setBirthdayMonth(month);
                                    }}
                                />
                                월
                            </div>
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <TextField
                                    type="text"
                                    value={birthdayDay ? String(birthdayDay).padStart(2, '0') : ''}
                                    style={{marginRight: '5px'}}
                                    onChange={(e) => {
                                        const day = Number(e.target.value);
                                        setBirthdayDay(day);
                                    }}
                                />
                                일
                            </div>
                        </div>
                    </div>
                    <div className="global-input-wrapper">
                        <div className="global-radio-wrapper">
                            <button type="button" className={`${yang === true ? "clicked" : ""}`} onClick={() => setYang(true)}>양력</button>
                            <button type="button" className={`${yang === false ? "clicked" : ""}`} onClick={() => setYang(false)}>음력</button>
                        </div>
                    </div>
                    <div className="global-input-wrapper">
                        <label style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            <span>태어난 시간</span>
                            <span className="global-checkbox-wrapper">
                                <input type="checkbox" name="sangsi" defaultChecked={!me?.birthday?.sangsi} onClick={(e) => setSangsi(!e.currentTarget.checked)}/> <b>모름</b>
                            </span>
                        </label>
                        <TextField
                            type="time"
                            disabled={!sangsi}
                            defaultValue={me?.birthday?.hour && (String(me?.birthday?.hour).padStart(2, '0') + ':' + String(me?.birthday?.minute).padStart(2, '0')) || undefined}
                            onChange={(e) => {
                                const [hour, minute] = e.target.value.split(':').map(Number);
                                setBirthdayHour(hour);
                                setBirthdayMinute(minute);
                            }}
                        />
                    </div>
                    {
                        <div className="global-input-wrapper" style={{display: 'none'}}>
                            <label style={{display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <span>MBTI</span>
                            </label>
                            <Select
                                id="mbtiSelector"
                                value={mbti}
                                style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                                <MenuItem value={'ISTJ'}>ISTJ</MenuItem>
                                <MenuItem value={'ISFJ'}>ISFJ</MenuItem>
                                <MenuItem value={'INFJ'}>INFJ</MenuItem>
                                <MenuItem value={'INTJ'}>INTJ</MenuItem>
                                <MenuItem value={'ISTP'}>ISTP</MenuItem>
                                <MenuItem value={'ISFP'}>ISFP</MenuItem>
                                <MenuItem value={'INFP'}>INFP</MenuItem>
                                <MenuItem value={'INTP'}>INTP</MenuItem>
                                <MenuItem value={'ESTP'}>ESTP</MenuItem>
                                <MenuItem value={'ESFP'}>ESFP</MenuItem>
                                <MenuItem value={'ENFP'}>ENFP</MenuItem>
                                <MenuItem value={'ENTP'}>ENTP</MenuItem>
                                <MenuItem value={'ESTJ'}>ESTJ</MenuItem>
                                <MenuItem value={'ESFJ'}>ESFJ</MenuItem>
                                <MenuItem value={'ENFJ'}>ENFJ</MenuItem>
                                <MenuItem value={'ENTJ'}>ENTJ</MenuItem>
                            </Select>
                        </div>
                    }
                    {/*<FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>이름</FormLabel>
                        <TextField fullWidth value="" />
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>생년월일</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>거주지</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>
                    <FormControl component="fieldset" className="name-change" style={{marginBottom: '30px'}}>
                        <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left', color: '#000000'}}>전화번호</FormLabel>
                        <TextField fullWidth value="" onChange={(e) => setName(e.target.value)}/>
                    </FormControl>*/}

                    <button type="submit" className="goodking-btn" style={{marginTop: '20px'}}>
                        저장
                    </button>
                </FormGroup>
            </div>
        </form>
    </Box>
    </>
  )
}

 export default EditProfileScreen
