import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {
    AppBar,
    Box,
    Tabs,
    Tab,
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {
    CodeResponse,
    CredentialResponse,
    TokenResponse,
    useGoogleLogin,
    useGoogleOneTapLogin
} from '@react-oauth/google';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '@src/store';
import {IUser} from '@src/shared/types';
import {setMe} from '../../store/auth';
import {StatusCode} from '@src/shared/statuscode';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    profile: {
        backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        width: '50%',
        height: '10em',
        maxWidth: '100%',
    }
}));


function PrivacyScreen() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [secret, setSecret] = useState('');
    const dispatch = useDispatch();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

    const classes = useStyles();
    const [language, setLanguage] = useState(true);
    return (
        <>
            <div className="policy-tabs">
                <a href="/terms">이용약관</a>
                <a className="selected" href="/privacy">개인정보 보호정책</a>
                <a href="/refund">환불정책</a>
            </div>
            <Box sx={{flexGrow: 1}} textAlign="left" style={{fontSize: '14px', fontWeight:'400', lineHeight: '21px', color: 'rgba(0,0,0,0,85)', padding: '24px'}}>
                {/*<button type="submit" style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    color: '#ED503C',
                    border: 'none',
                    height: '42px',
                    fontWeight: 'bold',
                    marginLeft: 'auto',
                    float: 'right',
                    cursor: 'pointer'
                }}
                        onClick={() => setLanguage(!language)}
                >
                    {language ? "En" : "Ko"}
                </button>*/}
                {language ?
                    <>
                        <p style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginBottom: '16px',
                            padding: '10px',
                            border: '1px solid #E2E5EB',
                            borderRadius: '5px'
                        }}>시행일자 : 2024-03-05</p>
                        <br/>
                        <br/>
                        본 개인정보 처리방침("개인정보 처리방침" 또는 "정책")은 (주)탱고랜드 및 그 계열사(FLABS AI”, "goodking.ai", "당사", "우리" 또는 "우리")가
                        귀하로부터 수집하고 처리하는 정보의 유형에 대해 설명합니다.
                        <br/>
                        <br/>
                        본 개인정보처리방침에 설명된 경우를 제외하고, 본 개인정보처리방침은 모든 웹사이트, 모바일 애플리케이션, goodking.ai가 제공하는 기타 전자 및/또는 디지털 제품
                        및/또는 기타 서비스 및 본 방침에 링크되어 있는 당사의 오프라인 서비스(총칭하여 "서비스")에 적용됩니다.
                        <br/><br/>
                        서비스를 이용함으로써 귀하는 본 방침에 설명된 관행에 동의하는 것입니다. 본 정책에 설명된 관행에 동의하지 않는 경우 서비스에 액세스하거나 서비스를 이용하지 마시기 바랍니다.
                        <br/><br/>
                        본 약관에서 정의되지 않은 대문자로 표기된 용어는 사주천왕 서비스 약관에 명시된 의미를 갖 습니다.
                        <br/><br/>
                        1. 당사가 수집하는 정보
                        <br/>
                        귀하가 당사의 서비스에 액세스하거나 기타 방식으로 서비스를 이용할 때, 당사는 귀하로부터 정 보를 수집할 수 있습니다. 당사가 수집하는 정보의 유형은 귀하의 서비스 이용 방식에 따라 다릅 니다. 당사는 귀하에게 서비스를 제공하기 위해 특정 유형의 정보가 필요하다는 점에 유의하시기 바랍니다. 이러한 정보를 당사에 제공하지 않거나 해당 정보를 삭제하도록 요청하는 경우, 귀하는 더 이상 특정 서비스에 액세스하거나 사용하지 못할 수 있습니다. 당사가 수집하는 정보에는 귀 하가 당사에 직접 제공하는 데이터, 귀하가 당사의 서비스와 상호 작용하여 자동으로 획득하는 데이터 및 기타 출처에서 획득하는 데이터가 포함될 수 있습니다.
                        <br/><br/>
                        귀하가 당사에 직접 제공하는 정보. 당사는 귀하로부터 직접 정보를 수집할 수 있습니다. 예를 들 어, 귀하는 서비스 이용, 당사와의 커뮤니케이션, 계정 등록, 뉴스레터 구독 또는 프로모션 참여 시 당사에 정보를 제공할 수 있습니다. 귀하가 당사에 직접 제공하는 정보는 귀하와 타인에 관한 것일 수 있으며, 다음을 포함하되 이에 국한되지 않습니다:
                        <br/><br/>
                        이메일 주소; 사용자 이름;
                        <br/>
                        결제 정보(예: 결제 처리 벤더를 통한 결제);
                        <br/>
                        채팅 커뮤니케이션, 게시된 이미지, 공유 캐릭터 등 귀하의 사용자 콘텐츠(서비스 약관에 정의된 바와 같이)
                        <br/>
                        당사와의 커뮤니케이션 내용.
                        <br/>
                        이러한 정보를 당사에 제공할 의무는 없지만, 요청된 정보를 제공하지 않으면 서비스의 특정 기 능에 액세스하지 못하거나 사용하지 못할 수 있습니다.
                        <br/>
                        <br/>
                        당사가 자동으로 수집하는 정보. 당사와 광고 네트워크 및 Google 애널리틱스와 같은 분석 회사 를 포함한 제3자 공급업체는 쿠키, 웹 비콘 및 기타 추적 기술을 사용하여 귀하가 서비스에 액세 스하는 데 사용하는 컴퓨터 또는 장치(모바일 장치 포함)에 대한 정보를 수집할 수 있습니다. 아 래에 자세히 설명된 바와 같이, 당사는 (a) 브라우저 유형, (b) ISP 또는 운영 체제, (c) 도메인 이름,(d) 접속 시간, (e) 참조 또는 종료 페이지, (f) 페이지 조회 수, (g) IP 주소, (h) 고유 기기 식별자(예: IDFA 또는 Android ID), (i) 사용 중인 당사 서비스 버전, ( j) 사용 기기 유형을 포함하되 이에 국한 되지 않는 정보를 수집 및 분석할 수 있습니다. 또한 당사는 귀하가 당사의 사이트 또는 모바일 애플리케이션에 참여하거나 탐색하는 방법을 포함하여 귀하가 서비스에 액세스하거나 서비스를 사용하는 시기와 빈도를 추적할 수 있습니다. 당사는 이러한 정보(제3자 공급업체가 수집한 정보 포함)를 분석(서비스의 어느 부분이 가장 자주 사용되는지, 사용자가 무엇을 좋아하고 싫어하는지 파악하기 위한 목적 포함), 광고 캠페인의 성공 여부 평가 및 본 방침에 달리 명시된 목적에 사용 할 수 있습니다.
                        <br/>
                        <br/>
                        당사와 타사 공급업체는 사용자 행동을 더 잘 이해하고, 환경설정을 개인화하며, 연구 및 분석을 수행하고, 서비스를 개선하는 데 도움이 되는 쿠키, 투명 GIF, 픽셀 태그 및 기타 기술을 사용할 수 있습니다. 예를 들어, 이러한 기술을 통해 당사는 귀하의 필요에 맞게 서비스를 맞춤화하고, 비밀번호를 비밀번호로 보호된 영역에 저장하고, 귀하가 방문한 페이지를 추적하고, 콘텐츠 관리를 돕고, 서비스 사용에 대한 통계를 수집할 수 있습니다. 또한 당사 또는 타사 공급업체는 이메일 응답률을 추적하고, 이메일 열람 시기를 식별하며, 이메일 전달 여부를 추적하기 위해 고객에게 보내는 이메일에 이러한 기술 중 일부를 사용할 수 있습니다.
                        <br/><br/>
                        대부분의 웹 브라우저는 자동으로 쿠키를 허용하지만, 원하는 경우 브라우저 설정을 수정하여 쿠키를 거부할 수 있습니다. 쿠키를 비활성화하면 서비스가 제대로 작동하지 않을 수 있으므로 서비스를 최대한 활용하지 못할 수 있습니다. 당사는 추가 기술을 채택함에 따라 다른 방법을 통해 추가 정보를 수집할 수도 있습니다. 자세한 내용은 https://www.allaboutcookies.org/ 을 참조하세요.
                        <br/><br/>
                        귀하가 서비스를 이용할 때 당사는 일반 위치 정보(예: IP 주소에서 유추한 일반 위치)를 수집할 수 있습니다.
                        <br/><br/>
                        기타 출처에서 수집하는 정보. 당사는 또한 다른 사용자가 당사를 추천하는 경우와 같이 다른 당사자로부터 귀하에 관한 정보를 수집할 수 있습니다. 당사는 또한 귀하가 당사의 소셜 미디어 프로필과 상호 작용할 때 Facebook 또는 Instagram과 같은 제3자 소셜 플랫폼으로부터 귀하에 관한 정보를 받을 수 있습니다.
                        <br/><br/>
                        또한 당사는 귀하가 소셜 미디어 계정 등 제3자가 관리하는 계정("제3자 계정")을 사용하여 귀하의 계정에 연결할 때 정보를 수집할 수 있습니다. 서비스는 귀하의 권한에 따라 귀하의 제3자 계정에서 귀하에 관한 정보를 수집할 수 있습니다. 귀하가 Facebook 또는 Google과 같은 제3자 계정을 통해 당사에 연결할 경우, 당사는 해당 제3자로부터 귀하의 계정을 식별하는 정보를 수신합니다. 당사는 이 정보를 수집 및 저장하여 회원님의 서비스 연결을 돕기 위해 사용합니다. 귀하의 계정을 제3자 계정에 연결하는 것은 전적으로 선택 사항이며, 귀하는 연결을 시도할 때 권한을 부여할 수 있는 기회를 갖게 됩니다. 귀하는 제3자 계정에 로그인하여 해당 계정에서 goodking.ai의 연결을 해제하거나 스마트폰의 기본 애플리케이션을 통해 권한을 취소할 수 있습니다. 당사는 이전에 귀하로부터 수집한 정보를 보유할 수 있습니다.
                        <br/><br/>

                        당사가 수집하는 정보의 사용 방법
                        <br/>
                        당사는 다음 목적을 위해 귀하의 정보를 사용할 수 있습니다:
                        <br/>

                        서비스 제공 및 관리; 서비스 맞춤 설정;
                        <br/>
                        귀하가 관심을 가질 만한 서비스의 기능 또는 측면에 대한 정보를 제공하거나 당사의 이용약관 또는 정책 변경에 대해 귀하와 소통하는 것을 포함하여 귀하와 소통합니다;
                        <br/>
                        귀하의 문의, 의견, 피드백 또는 질문에 응답하는 등 귀하와의 기타 커뮤니케이션에 참여합니다;
                        <br/>
                        당사의 인공지능/머신러닝 모델 학습을 포함하여 서비스를 분석, 유지, 개선, 수정, 맞춤화 및 측정합니다;
                        <br/>
                        새로운 프로그램과 서비스를 개발합니다;
                        <br/>
                        사기, 범죄 행위 또는 서비스 오용을 탐지 및 방지하고, 당사의 IT 시스템, 아키텍처 및 네트워크의 보안을 보장합니다;
                        <br/>
                        법적 의무 및 법적 절차를 준수하고 당사의 서비스 약관 및 기타 계약의 집행을 포함하여 당사의 권리, 개인정보, 안전 또는 재산 및/또는 당사의 계열사, 귀하 또는 기타 당사자의 권리를 보호하기 위한 목적.
                        <br/>
                        정보 수집의 다른 목적 수행.
                        <br/><br/>
                        당사는 서비스를 통해 귀하로부터 수집한 정보를 다른 출처에서 얻은 정보와 결합할 수 있습니다. 또한 당사는 서비스를 통해 수집한 정보를 집계 및/또는 비식별화할 수 있습니다. 당사는 연구 및 마케팅 목적을 포함하되 이에 국한되지 않는 모든 목적을 위해 비식별화되거나 집계된 데이터를 사용 및 공개할 수 있습니다.
                        <br/><br/>
                        당사가 수집한 정보를 공개하는 경우
                        <br/>
                        당사는 다음과 같은 상황에서 귀하의 정보를 공개할 수 있습니다:
                        <br/>

                        계열사: 당사는 계열사, 즉 goodking.ai를 지배하거나, 지배를 받거나, goodking.ai와 공동 지배를 받는 법인을 대상으로 정보를 공개할 수 있습니다. 당사의 계열사는 당사가 공개하는 정보를 본 방침에 부합하는 방식으로 사용할 수 있습니다.
                        <br/>
                        벤더: 당사는 데이터 저장, 결제, 기술 지원 및 서비스, 고객 서비스, 분석, 사기 방지, 법률 서비스 및 마케팅 서비스를 제공하는 업체 등 당사를 대신하여 업무를 수행하거나 서비스를 수행하기 위해 해당 정보에 액세스해야 하는 직원, 컨설턴트 및 기타 벤더에게 귀하의 정보를 공개할 수 있습니다.
                        <br/>
                        goodking.ai 및 타인의 안전 및 보호: 당사는 (i) 본 정책, 당사의 서비스 약관 또는 기타 계약상의 약정을 방어 또는 집행하는 것을 포함하여 goodking.ai 또는 기타 당사자를 보호 또는 방어하고, (ii) goodking.ai, 당사의 대리인 및 계열사, 당사의 직원, 사용자 및/또는 대중의 권리, 재산 또는 개인 안전을 보호하기 위해 선의로 그렇게 하는 것이 필요하거나 적절하다고 판단하는 경우 특정 정보를 공개할 수 있습니다.
                        <br/><br/>
                        광고 및 분석: 당사는 본 정책 시행일 현재 광고에 관여하지 않지만, 향후 인터넷에서 당사를 대신하여 광고를 게재하고 분석 서비스를 제공하기 위해 광고 및 분석 파트너에게 귀하의 정보 중 일부를 공개하거나 제공할 수 있습니다. 이러한 업체는 쿠키 및 추적 기술을 사용하여 무엇보다도 데이터를 추적 및 분석하고, 특정 콘텐츠의 인기도를 파악하며, 회원님의 관심사에 맞는 광고 및 콘텐츠를 제공하고, 회원님의 온라인 활동을 더 잘 이해할 수 있도록 할 수 있습니다.
                        <br/><br/>
                        법적 요구사항: 당사는 영장, 소환장, 법원 명령 또는 기타 관련 법규에 대응하는 등 법 집행, 법률 또는 규제 절차를 준수하기 위해 선의로 그렇게 하는 것이 필요하거나 적절하다고 판단하는 경우 특정 정보를 공개할 수 있습니다.
                        <br/><br/>
                        사업 양도: 당사는 합병, 회사 자산 매각, 자금 조달 또는 다른 회사에 대한 사업 전부 또는 일부의 인수와 관련하여 또는 협상 또는 종결 과정에서 특정 정보를 공개할 수 있습니다.
                        <br/><br/>
                        귀하의 사용자 콘텐츠: 귀하가 취하는 특정 조치는 서비스의 다른 사용자에게 공개되거나 서비스의 다른 사용자가 이용할 수 있는 콘텐츠가 될 수 있습니다. 예를 들어, 귀하가 공유 캐릭터를 생성하는 등 사용자 콘텐츠를 생성하는 경우, 당사는 해당 콘텐츠를 서비스에서 사용할 수 있도록 할 수 있습니다. 귀하가 그룹 채팅을 사용하여 소통하는 경우, 귀하는 해당 그룹 채팅의 다른 구성원들이 귀하의 채팅을 이용할 수 있도록 당사에 지시하는 것이며, 여기에는 서비스의 캐릭터 및 다른 사용자들이 포함될 수 있습니다.
                        <br/><br/>
                        귀하의 동의 또는 귀하의 지시에 따른 기타 공개: 당사는 귀하의 동의에 따라 비계열 제3자에게 귀하의 정보를 공개할 수 있습니다. 예를 들어, 귀하는 귀하의친구나 다른 지인에게 당사 또는 당사의 서비스를 추천할 때 귀하에 관한 정보를 공개하도록 당사에 지시할 수 있습니다.
                        <br/><br/>

                        온라인 분석 및 맞춤형 광고
                        <br/>
                        분석: 당사는 서비스에서 Google 애널리틱스와 같은 제3자 웹 분석 서비스를 사용할 수 있습니다. 이러한 공급업체는 당사가 자동으로 수집하는 정보 섹션에 설명된 기술을 사용하여 사용자가 서비스를 이용하는 방식을 분석하는 데 도움을 주며, 여기에는 사용자가 방문한 제3자 웹사이트를 기록하는 것도 포함됩니다. 이러한 기술에 의해 수집된 정보는 이러한 공급업체에 공개되거나 해당 공급업체가 직접 수집하며, 해당 공급업체는 해당 정보를 사용하여 회원님의 서비스 이용을 평가합니다. Google 애널리틱스가 웹 분석을 위해 귀하의 정보를 사용하지 못하도록 하려면, Google 애널리틱스 옵트아웃 브라우저 부가기능을 설치할 수 있습니다.
                        <br/>
                        맞춤형 광고: 당사는 본 정책 시행일 현재 광고에 관여하지 않지만, 향후 제3자 광고 네트워크가 귀하의 컴퓨터, 휴대폰 또는 기타 장치에 쿠키 또는 기타 추적 기술을 배치하여 귀하의 서비스 사용에 관한 정보를 수집하도록 허용하여 (a) 과거 당사 웹사이트 및 기타 온라인 서비스 방문을 기반으로 마케팅 콘텐츠를 알리고 최적화하여 제공하고, (b) 마케팅 콘텐츠 노출 및 이러한 마케팅 노출과의 상호작용이 당사 온라인 서비스 방문과 어떻게 연관되는지 보고할 수 있도록 할 수 있습니다. 당사는 또한 다른 비계열사(예: 광고 네트워크 및 Google 애널리틱스와 같은 광고 서버)가 귀하에게 맞춤형 마케팅을 제공하고 귀하의 컴퓨터, 휴대폰 또는 귀하가 서비스에 액세스하는 데 사용하는 기타 기기에서 자체 쿠키 또는 기타 추적 기술에 액세스하도록 허용할 수 있습니다. 이러한 기술을 사용하는 당사자는 아래에 설명된 대로 귀하에게 타겟팅 광고를 거부할 수 있는 방법을 제공할 수 있습니다.
                        <br/><br/>
                        귀하의 선택
                        <br/>
                        당사는 귀하에 관한 정보의 수집, 사용 및 공개와 관련하여 귀하에게 특정 선택권을 제공합니다. 프로필 정보, 마케팅 커뮤니케이션, 쿠키 및 분석 처리의 거부, 회원님에 관한 정보에 대한 권리 등을 포함합니다. 현지 법률은 귀하의 정보와 관련하여 귀하에게 특정 권리를 부여할 수 있습니다. 관할권에 따라 귀하는 당사에 요청할 수 있습니다:
                        <br/>
                        당사가 수집하거나 공개하는 개인정보의 범주, 그러한 정보의 출처, 그러한 정보를 수집하는 사업적 또는 상업적 목적, 그러한 개인정보를 공개하는 비계열사의 범주에 대한 정보를 제공합니다. 이러한 정보는 본 정책에도 명시되어 있습니다.
                        당사가 보유한 귀하에 관한 특정 정보에 대한 액세스 및/또는 사본을 제공할 수 있습니다.
                        직접 마케팅 목적(프로파일링에 기반한 직접 마케팅 처리 포함)으로 귀하의 정보를 처리하지 못하도록 합니다.
                        오래되거나 잘못된 정보를 업데이트합니다.
                        당사가 귀하에 대해 보유하고 있는 특정 정보를 삭제합니다.
                        당사가 귀하에 관한 특정 정보를 처리하고 공개하는 방식을 제한합니다; 타사 서비스 제공업체에 회원님의 정보를 전송할 수 있습니다.
                        해당되는 경우, 법적 또는 이와 유사하게 중대한 영향을 미치는 의사결정을 위한 프로파일링 목적으로 귀하의 개인 정보를 처리하지 않도록 옵트아웃합니다.
                        정보 처리에 대한 동의를 철회할 수 있습니다.
                        귀하는 귀하를 대신하여 요청을 할 수 있는 권한 있는 대리인을 지정할 수 있습니다. 승인된 대리인의 신원을 확인하려면 승인된 대리인에게 해당 요청을 할 수 있는 서명된 서면 허가서 또는 위임장을 제공해야 합니다. 또한 당사는 관련 법률에서 허용하는 바에 따라 승인된 대리인의 요청을 처리하기 전에 귀하의 신원을 확인하기 위해 귀하에게 후속 조치를 취할 수 있습니다.
                        <br/>
                        특정 정보는 관련 법률에 따라 이러한 요청이 면제될 수 있다는 점에 유의하시기 바랍니다. 예를 들어, 당사는 법률 준수 및 서비스 보안을 위해 특정 정보를 보유할 수 있습니다. 당사는 귀하에게 서비스를 제공하기 위해 특정 정보가 필요할 수 있으며, 귀하가 삭제를 요청하는 경우 더 이상 서비스를 이용하지 못할 수 있습니다.
                        <br/>
                        또한 회원님은 관련 법률에 규정된 바에 따라 권리 행사로 인해 차별을 받지 않을 권리가 있습니다.
                        <br/>
                        귀하는 또한 귀하의 개인정보 판매 또는 타겟 광고를 위한 귀하의 개인정보 처리를 거부할 권리가 있습니다. 향후 당사가 제공할 수 있는 타겟 광고를 거부하려면 위의 맞춤형 광고 섹션에 설명된 도구를 사용하시기 바랍니다.
                        <br/>
                        관련 법률에 따른 귀하의 권리에 관한 정보를 원하거나 권리를 행사하고자 하는 경우, https://goodking.ai로 문의하시기 바랍니다. 당사는 귀하의개인 정보와 보안을 보호하기 위해 요청을 승인하기 전에 귀하의 신원과 요청을 확인하기 위한 합리적인 조치를 취합니다. 귀하의 신원을 확인할 수 없는 경우, 당사는 귀하의 요청에 응답하지 못할 수 있습니다.
                        <br/>
                        관련 법률에 따라 귀하는 요청 거부 결정에 대해 이의를 제기할 권리가 있을 수 있습니다(해당되는 경우). 당사는 요청을 거부하는 답변에서 해당 권리를 행사하는 방법에 대한 정보를 제공할 것입니다.
                        <br/>
                        지역별 개인정보 공개 및 권리: 대한민국 거주자를 위한 추가 공개 사항은 아래 지역별 개인정보 공개에 명시되어 있습니다.
                        <br/>
                        대한민국 거주자: goodking.ai는 본 정책에 따라 당사가 처리하는 "개인정보"(일반 데이터 보호 규정에 정의된 대로)의 "데이터 관리자"로 간주됩니다. 즉, goodking.ai는 관련 법률에 따라 개인 데이터를 수집, 사용 및 공개하는 방법을 결정할 책임이 있습니다. 대한민국의 법률에 따라 데이터 컨트롤러는 귀하의 개인 데이터를 사용, 공유 또는 공개할 때 의존하는 법적 근거에 대해 귀하에게 알려야 합니다. 해당 법률이 적용되는 범위 내에서 당사의 법적 근거는 다음과 같습니다:
                        <br/>
                        계약상의 약속: 당사는 귀하와의 계약상 약속을 지키기 위해 개인정보를 사용, 공유 또는 공개할 수 있습니다. 예를 들어, 당사는 귀하와의 계약을 준수하고 귀하와 맺은 계약상의 약속을 지키기 위해 귀하의 개인 데이터를 처리합니다.
                        회원님의 동의 하에: 법률에 의해 요구되는 경우 및 기타 일부의 경우, 당사는 귀하의 동의에 따라 개인 데이터를 사용, 공유 또는 공개합니다.
                        적법한 이익: 대부분의 경우, 당사는 고객 서비스, 특정 판촉 활동, 비즈니스 분석 및 개선, 서비스 보안 제공, 사기 방지, 법적 문제 관리 등 영향을 받는 개인의 이익이나 기본적 권리 및 자유에 우선하지 않는 방식으로 당사의 정당한 사업적 이익을 증진한다는 이유로 개인정보를 사용, 공유 또는 공개합니다.
                        법률 준수: 당사는 법적 의무를 준수하기 위해 특정 방식으로 개인 데이터를 사용 및 공개해야 합니다.
                        여기에 설명된 권리 외에도 귀하는 관련 감독 기관에 불만을 제기할 권리가 있습니다. 그러나 먼저 당사에 연락해 주시면 귀하의 우려 사항을 해결하기 위해 최선을 다하겠습니다.
                        <br/>여기에 설명된 권리 외에도, 귀하는 관련 감독 기관에 불만을 제기할 권리가 있습니다. 그러나 먼저 당사에 연락해 주시면, 귀하의 우려 사항을 해결하기 위해 최선을 다하겠습니다.
                        <br/><br/>
                        본 방침의 발효일 이전 12개월 동안, 당사는 비즈니스 또는 상업적 목적으로 섹션 1에 설명된 개인정보 범주를 수집 및 공개했습니다. 개인정보 범주에는 식별자, 금융 정보(결제 정보 등), 상업적 또는 거래 정보, 인터넷 또는 기타 전자 네트워크 활동 정보, 일반적인 지리적 위치 데이터, 오디오 또는 비디오 정보(예: 귀하가 제출한 사진 및 동영상), 사용자 콘텐츠 등을 통해 귀하가 자발적으로 당사에 제공할 수 있는 기타 정보, 귀하를 식별할 수 있는 기타 정보, 당사가 수집한 정보로부터 도출한 추론 등이 포함됩니다.
                        <br/><br/>
                        당사는 위에 명시된 개인정보 범주를 위 2항에 자세히 설명된 목적을 위해 처리합니다.
                        <br/><br/>
                        당사는 다음 출처로부터 위에 명시된 개인정보 범주를 수집합니다: (1) 귀하로부터 직접, (2) 귀하의 서비스 사용을 통해, (3) 다른 사용자 및 비계열사와 같은 기타 당사자를 통해.
                        <br/><br/>
                        정보 공개 관행은 위의 섹션 3에서 자세히 설명합니다. 개인정보의 "판매" 및 "공유"
                        <br/><br/>
                        당사는 다른 웹사이트에서 서비스를 광고하기 위해 광고 파트너와 온라인 식별자(예: 쿠키 데이터, IP 주소, 기기 식별자, 일반 위치 정보 및 사용 정보)를 "공유"할 수 있는 제3자 분석 서비스 및 온라인 광고 서비스를 사용할 수 있습니다. 회원님 또는 회원님의 권한을 위임받은 대리인이 이러한 목적으로 귀하의 정보를 당사가 "공유"하는 것을 거부하고자 하는 경우, 위의 맞춤형 광고 섹션에 설명된 도구를 사용하여 거부할 수 있으며, 이러한 도구는 여기에서도 확인할 수 있습니다.
                        <br/><br/>
                        당사는 16세 미만 아동의 개인정보를 고의로 '판매'하거나 '공유'하지 않습니다. 추적 금지 공개
                        <br/><br/>
                        인터넷 업계는 현재 추적 금지 표준, 구현 및 솔루션을 개발 중이므로 브라우저에서 시작된 추적 금지 신호에는 응답하지 않습니다.
                        <br/><br/>
                        개인 정보 보유
                        <br/><br/>
                        아래 데이터 보존 섹션을 참조하세요.
                        <br/><br/>
                        어린이 개인정보 보호
                        <br/>
                        본 서비스는 13세 미만의 미성년자를 위한 것이 아니며, 당사의 서비스 약관은 13세 미만 또는 대한민국 거주자의 경우19세 미만의 사용을 금지하고 있습니다. 만약 13세 미만의 개인이 당사에 아동 온라인 개인정보 보호법(COPPA)에 정의된 "개인정보"를 제공한 사실을 발견하는 경우, 당사는 해당 계정을 폐쇄하고 요구하는 범위 내에서 해당 개인정보를 삭제합니다. 법이 허용하는 경우, 당사는 본 방침에 설명된 목적을 위해 특정 정보를 내부적으로 보유할 수 있습니다.
                        <br/><br/>
                        보안
                        <br/>
                        당사는 당사가 수집하는 정보를 분실, 오용, 무단 액세스, 공개, 변경 또는 파기로부터 보호하기 위해 기술적, 관리적, 물리적 안전장치를 구현합니다. 그러나 인터넷이나 이메일 전송이 완전히 안전하거나 오류가 없는 것은 아닙니다. 따라서 당사는 귀하의 정보 또는 통신의 보안을 약속하지 않으며 보장할 수 없습니다.
                        <br/><br/>
                        데이터 보존
                        <br/>
                        일반적으로 당사는 정보 처리 목적에 필요한 기간 동안 귀하의 정보를 보관합니다. 당사가 정보를 보유하는 기간은 당사가 정보를 수집하고 사용하는 목적과 귀하의 선택에 따라 달라지며, 그 이후에는 당사가 정보를 삭제하거나 통합할 수 있습니다. 또한 당사는 법적 의무를 준수하고 분쟁을 해결하며 계약을 이행하기 위해 필요한 경우 이 정보를 보유 및 사용할 수 있습니다.
                        <br/><br/>
                        인기 캐릭터: 귀하가 생성하여 "공개"로 설정한 캐릭터의 인기가 특정 임계값에 도달하면, 당사는 귀하가 귀하의 데이터와 계정을 삭제하더라도 해당 캐릭터의 특성을 보존하고 서비스에서 해당 캐릭터를 계속 활성 상태로 유지할 권리를 보유합니다. 이는 인기도가 높은 캐릭터의 정의상 수천 명의 사용자와 활발한 대화를 나누고 있다는 점을 고려하여 다른 사용자의 경험에 영향을 미치지 않도록 하기 위한 것입니다. 이 경우 당사가 귀하에 관한 정보를 보유하지 않도록 하려면 "공개" 캐릭터를 생성하는 경우 캐릭터 생성 절차의 일부로 그러한 정보를 제출하지 마시기 바랍니다.
                        <br/><br/>
                        이전 동의
                        <br/>
                        당사의 서비스는 글로벌 서비스이므로 귀하의 정보는 대한민국 및 귀하가 거주하는 국가의 법률과 다른 데이터 보호법이 적용되는 대한민국 외의 다른 국가에서 저장 및 처리될 수 있으며, 특정 상황에서 법 집행기관 및 국가 안보 당국이 데이터에 접근할 수 있습니다. 서비스를 이용하거나 당사에 정보를 제공함으로써 귀하는 귀하가 거주하거나 시민권자인 국가의 개인정보 보호법만큼 포괄적이지 않거나 이와 동등하지 않을 수 있는 대한민국 및 기타 해당 국가에서 그러한 정보를 수집, 처리, 유지 및 전송하는 데 동의하게 됩니다.
                        <br/><br/>
                        타사 웹사이트로의 링크
                        <br/>
                        서비스에는 제3자 웹사이트 또는 서비스에 대한 링크가 포함될 수 있습니다. 당사는 해당 웹사이트 또는 서비스의 콘텐츠 또는 관행에 대해 책임을 지지 않습니다. 제3자에 의한 회원님의 정보 수집, 사용 및 공개는 본 정책이 아닌 제3자 웹사이트 또는 서비스의 개인정보 처리방침의 적용을 받습니다. 이러한 제3자에게 정보를 제공하기 전에 해당 제3자의 개인정보처리방침 및 보안 정책을 읽어보시기 바랍니다.
                        <br/><br/>
                        본 정책의 변경 사항
                        <br/>
                        당사는 법률, 당사의 정보 관행 또는 서비스 기능의 변화를 반영하기 위해 본 정책을 변경할 수 있습니다. 당사는 본 방침에 가장 최근 업데이트 날짜를 표시합니다. 당사가 본 정책을 중요하게 변경하는 경우, 법적 요건에 따라 귀하에게 적절한 통지를 제공할 것입니다. 서비스를 계속 이용함으로써 귀하는 본 정책의 최신 버전을 읽고 이해했음을 확인하는 것입니다.
                        <br/><br/>
                        문의하기
                        <br/>
                        개인정보 처리방침 또는 정보 관행에 대해 궁금한 점이 있으면, 이메일(contact@flabs.ai)로 문의해주세요
                    </>
                    :
                    <>
                        <p style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginBottom: '16px',
                            padding: '10px',
                            border: '1px solid #E2E5EB',
                            borderRadius: '5px'
                        }}>Effective Date : 2024-03-05</p>
                        <br/>
                        <br/>
                        <p>This Privacy Policy (the “Privacy Policy” or “Policy”) describes the types of information
                            Flabs AI Inc. and its affiliates (“goodking.ai,” “we,” “our,” or “us”) collect and process
                            from and about you.</p>
                        <br/>
                        <p>Except as described in this Privacy Policy, the Policy applies to any and all websites,
                            mobile applications, and any other electronic and/or digital products and/or other services
                            that are made available by goodking.ai and that link to this Policy, and our offline
                            services (collectively, the “Services”).</p>
                        <br/>
                        <p>By using the Services, you are agreeing to the practices described in this Policy. If you do
                            not agree to the practices described in this Policy, please do not access or use the
                            Services.</p>
                        <br/>
                        <p>Undefined capitalized terms used herein shall have the meaning set forth in the goodking.ai
                            Terms of Service.</p>
                        <br/>
                        <p>1. Information We Collect</p>
                        <p>When you access or otherwise use our Services, we may collect information from you. The types
                            of information we collect depend on how you use our Services. Please note that we need
                            certain types of information to provide the Services to you. If you do not provide us with
                            such information, or if you ask us to delete that information, you may no longer be able to
                            access or use certain Services. The information we collect may include data you directly
                            provide to us, data we obtain automatically from your interactions with our Services, and
                            data we obtain from other sources.</p>
                        <br/>
                        <p>Information you provide directly to us. We may collect information directly from you. For
                            example, you may provide us with information when you use the Services, communicate with us,
                            register for an account, subscribe to newsletters, or participate in a promotion.
                            Information you provide directly to us may concern you and others and may include, but is
                            not limited to:</p>
                        <br/>
                        <p>Email address;</p>
                        <p>User name;</p>
                        <p>Payment information (e.g. through our payment processing vendors);</p>
                        <p>Your User Content (as defined in the Terms of Service), such as chat communications, posted
                            images, and shared Characters; and</p>
                        <p>Contents of communications with us.</p>
                        <p>You are not required to provide us with such information, but certain features of the
                            Services may not be accessible or available absent the provision of the requested
                            information.</p>
                        <br/>
                        <p>Information we collect automatically. We and our third-party vendors, which include ad
                            networks and analytics companies such as Google Analytics, may use cookies, web beacons, and
                            other tracking technologies to collect information about the computers or devices (including
                            mobile devices) you use to access the Services. As described further below, we may collect
                            and analyze information including but not limited to (a) browser type; (b) ISP or operating
                            system; (c) domain name; (d) access time; (e) referring or exit pages; (f) page views; (g)
                            IP address; (h) unique device identifiers (e.g. IDFA or Android ID); (i) version of our
                            Services you’re using; and (j) the type of device that you use. We may also track when and
                            how frequently you access or use the Services, including how you engage with or navigate our
                            site or mobile application. We may use this information (including the information collected
                            by our third-party vendors) for analytics (including to determine which portions of the
                            Services are used most frequently and what our users like/do not like), to evaluate the
                            success of any advertising campaigns, and as otherwise described in this Policy.</p>
                        <br/>
                        <p>We and our third-party vendors may use cookies, clear GIFs, pixel tags, and other
                            technologies that help us better understand user behavior, personalize preferences, perform
                            research and analytics, and improve the Services. These technologies, for example, may allow
                            us to tailor the Services to your needs, save your password in password-protected areas,
                            track the pages you visit, help us manage content, and compile statistics about usage of our
                            Services. We or our third-party vendors also may use certain of these technologies in emails
                            to our customers to help us track email response rates, identify when our emails are viewed,
                            and track whether our emails are forwarded.</p>
                        <br/>
                        <p>Most web browsers automatically accept cookies, but your browser may allow you to modify your
                            browser settings to decline cookies if you prefer. If you disable cookies, you may be
                            prevented from taking full advantage of the Services, because the Services may not function
                            properly. As we adopt additional technologies, we may also gather additional information
                            through other methods. For more information, see https://www.allaboutcookies.org/.</p>
                        <br/>
                        <p>When you use the Services, we may collect general location information (such as general
                            location inferred from an IP address).</p>
                        <br/>
                        <p>Information we collect from other sources. We may also collect information about you from
                            other parties, such as when you are referred to us by another user. We may also receive
                            information about you from third party social platforms, such as Facebook or Instagram, when
                            you interact with our social media profiles.</p>
                        <br/>
                        <p>We may also collect information when you connect to your account using an account maintained
                            by a third party, such as a social media account (“Third-Party Account”). The Services may
                            collect information about you from your Third-Party Accounts in accordance with your
                            permissions. When you connect to us through a Third-Party Account like Facebook or Google,
                            we receive information from that third party identifying your account. We collect and store
                            this information and use it to help you connect to the Services. Connecting your account to
                            a Third-Party Account is completely optional, and you will have the opportunity to grant
                            permission when you attempt to connect. You can revoke permission by logging into the
                            Third-Party Account and disconnecting goodking.ai from there, and through the native
                            applications on your smartphone. We may retain the information we collected previously from
                            you.</p>
                        <br/>
                        <p>2. How We Use the Information We Collect</p>
                        <p>We may use your information for any of the following purposes:</p>
                        <br/>
                        <p>Provide and administer the Services;</p>
                        <p>Personalize the Services;</p>
                        <p>Communicate with you, including to information you about features or aspects of the Services
                            we believe might be of interest to you, or to communicate with you about changes to our
                            terms, conditions, or policies;</p>
                        <p>Engage in other communications with you, such as to respond to your inquiries, comments,
                            feedback, or questions;</p>
                        <p>Analyze, maintain, improve, modify, customize, and measure the Services, including to train
                            our artificial intelligence/machine learning models;</p>
                        <p>Develop new programs and services;</p>
                        <p>Detect and prevent fraud, criminal activity, or misuses of our Service, and to ensure the
                            security of our IT systems, architecture, and networks;</p>
                        <p>Comply with legal obligations and legal process and to protect our rights, privacy, safety,
                            or property, and/or that of our affiliates, you, or other parties, including to enforce our
                            Terms of Service and any other agreements; and</p>
                        <p>Carry out any other purpose for which the information was collected.</p>
                        <p>We may combine information that we collect from you through the Services with information
                            that we obtain from other sources. We may also aggregate and/or de-identify information
                            collected through the Services. We may use and disclose de-identified or aggregated data for
                            any purpose, including without limitation for research and marketing purposes.</p>
                        <br/>
                        <p>3. When We Disclose the Information We Collect</p>
                        <p>We may disclose your information in any of the following circumstances:</p>
                        <br/>
                        <p>Affiliates: We may disclose information to our affiliates, meaning an entity that controls,
                            is controlled by, or is under common control with goodking.ai. Our affiliates may use the
                            information we disclose in a manner consistent with this Policy.</p>
                        <br/>
                        <p>Vendors: We may disclose your information to employees, consultants, and other vendors who
                            need access to such information to carry out work or perform services on our behalf, such as
                            those who provide data storage, payment, technology support and services, customer service,
                            analytics, fraud prevention, legal services, and marketing services.</p>
                        <br/>
                        <p>Safety and Protection of goodking.ai and Others: We may disclose certain information if we
                            believe in good faith that doing so is necessary or appropriate to (i) protect or defend the
                            goodking.ai or other parties, including to defend or enforce this Policy, our Terms of
                            Service, or any other contractual arrangement; and/or (ii) protect the rights, property or
                            personal safety of goodking.ai, our agents and affiliates, our employees, users and/or the
                            public.</p>
                        <br/>
                        <p>Advertising and Analytics: Though we do not engage in advertising as of the date of this
                            Policy, we may in the future disclose or make available some of your information with
                            advertising and analytics partners to serve advertisements on our behalf across the internet
                            and to provide analytics services. These entities may use cookies and tracking technologies
                            to allow us to, among other things, track and analyze data, determine the popularity of
                            certain content, deliver advertising and content targeted to your interests and better
                            understand your online activity. For more information about how to manage having your web
                            browsing information used for advertising purposes, please see the Online Analytics and
                            Tailored Advertising section below.</p>
                        <br/>
                        <p>Legal Requirements. We may disclose certain information if we believe in good faith that
                            doing so is necessary or appropriate to comply with any law enforcement, legal, or
                            regulatory process, such as to respond to a warrant, subpoena, court order, or other
                            applicable laws and regulations.</p>
                        <br/>
                        <p>Business Transfers. We may disclose certain information, in connection with or during
                            negotiations or closing of any merger, sale of company assets, financing, or acquisition of
                            all or a portion of our business to another company.</p>
                        <br/>
                        <p>Your User Content. Certain actions you take may be visible to other users of the Services or
                            result in content that is made available to other users of the Services. For example, if you
                            create User Content, such as creating shared Characters, we may also make that available on
                            the Services. If you communicate using a Group Chat, you are directing us to make your chats
                            available to other members of that Group Chat, which may include Characters on the Services
                            and other users.</p>
                        <br/>
                        <p>Other Disclosures with Consent or at Your Direction. We may disclose your information to
                            nonaffiliated third parties based on your consent to do so. For example, you may direct us
                            to disclose information about you when you refer us or our Services to your friends or other
                            acquaintances.</p>
                        <br/>
                        <p>4. Online Analytics and Tailored Advertising</p>
                        <p>Analytics. We may use third-party web analytics services on the Services, such as those of
                            Google Analytics. These vendors use the sort of technology described in the “Information We
                            Collect Automatically” section above to help us analyze how users use the Services,
                            including by noting the third-party website from which you arrive. The information collected
                            by such technology will be disclosed to or collected directly by these vendors, who use the
                            information to evaluate your use of the Services. We also may use Google Analytics for
                            certain purposes related to advertising, as described in the following section. To prevent
                            Google Analytics from using your information for web analytics, you may install the Google
                            Analytics Opt-Out Browser Add-on.</p>
                        <br/>
                        <p>Tailored Advertising. Though we do not engage in advertising as of the date of this Policy,
                            we may in the future allow third-party advertising networks to place cookies or other
                            tracking technologies on your computer, mobile phone, or other device to collect information
                            about your use of the Services in order to (a) inform, optimize, and serve marketing content
                            based on past visits to our website and other online services and (b) report how our
                            marketing content impressions and interactions with these marketing impressions are related
                            to visits to our online services. We may also allow other unaffiliated parties (e.g., ad
                            networks and ad servers such as Google Analytics) to serve tailored marketing to you and to
                            access their own cookies or other tracking technologies on your computer, mobile phone, or
                            other device you use to access the Services. Those parties that use these technologies may
                            offer you a way to opt out of targeted advertising as described below. You may receive
                            tailored advertising on your computer through a web browser. Cookies may be associated with
                            de-identified data linked to or derived from data you voluntarily have submitted to us
                            (e.g., your email address) that we may disclose to a vendor in hashed, non-human-readable
                            form.</p>
                        <br/>
                        <p>If you are interested in more information about tailored advertising and how you can
                            generally control cookies from being put on your computer to deliver tailored marketing, you
                            may visit the Network Advertising Initiative's (“NAI”) Consumer Opt-Out Link, the Digital
                            Advertising Alliance's (“DAA”) Consumer Opt-Out Link to opt-out of receiving tailored
                            advertising from companies that participate in those programs. To exercise choices about how
                            Google personalizes Display Advertising or to customize Google Display Network ads, you can
                            visit the Google Ads Settings page. Please note that to the extent advertising technology is
                            integrated into the Services, you may still receive advertising content even if you opt out
                            of tailored advertising. In that case, the advertising content may not be tailored to your
                            interests. Also, we do not control any of the above opt-out links and are not responsible
                            for any choices you make using these mechanisms or the continued availability or accuracy of
                            these mechanisms. If your browsers are configured to reject cookies when you visit these
                            opt-out pages, or you subsequently erase your cookies, or use a different computer or change
                            web browsers, your opt-out may no longer be effective.</p>
                        <br/>
                        <p>5. Your Choices</p>
                        <p>We offer you certain choices regarding the collection, use, and disclosure of information
                            about you.</p>
                        <br/>
                        <p>Profile information. You may deactivate your account through your account profile page. You
                            may also verify, correct, update, or delete certain of your information through your account
                            profile page.</p>
                        <br/>
                        <p>Marketing communications. You can unsubscribe from marketing emails by following the
                            directions in those emails. Please note that if you unsubscribe from marketing emails, we
                            may still send you administrative emails regarding the Services, including, for example,
                            notices of updates to our Terms of Service or this Policy.</p>
                        <br/>
                        <p>Cookies and analytics. You can opt out of certain cookie-related and analytics processing by
                            following the instructions in this Policy.</p>
                        <br/>
                        <p>Rights to information about you. Your local law may provide you certain rights with respect
                            to your information. Depending on your jurisdiction, you may request that we:</p>
                        <br/>
                        <p>Provide you with information about the categories of personal information we collect or
                            disclose about you; the categories of sources of such information; the business or
                            commercial purpose for collecting such information; and the categories of unaffiliated
                            parties to whom we disclose such personal information. Such information is also set forth in
                            this Policy;</p>
                        <br/>
                        <p>provide access to and/or a copy of certain information we hold about you;</p>
                        <br/>
                        <p>Prevent the processing of your information for direct-marketing purposes (including any
                            direct marketing processing based on profiling);</p>
                        <br/>
                        <p>Update information which is out of date or incorrect;</p>
                        <br/>
                        <p>Delete certain information that we are holding about you;</p>
                        <br/>
                        <p>Restrict the way that we process and disclose certain information about you;</p>
                        <br/>
                        <p>Transfer your information to a third-party provider of services;</p>
                        <br/>
                        <p>Opt you out of the processing of your personal information for purposes of profiling in
                            furtherance of decisions that produce legal or similarly significant effects, if applicable;
                            and</p>
                        <br/>
                        <p>Revoke your consent for the processing of your information.</p>
                        <br/>
                        <p>You may be able to designate an authorized agent to make requests on your behalf. In order
                            for an authorized agent to be verified, you must provide the authorized agent with signed,
                            written permission to make such requests or a power of attorney. We may also follow up with
                            you to verify your identity before processing the authorized agent’s request as permitted by
                            applicable law.</p>
                        <br/>
                        <p>Please note that certain information may be exempt from such requests under applicable law.
                            For example, we may retain certain information for legal compliance and to secure our
                            Services. We may need certain information in order to provide the Services to you; if you
                            ask us to delete it, you may no longer be able to use the Services.</p>
                        <br/>
                        <p>You also have the right to not be discriminated against (as provided for in applicable law)
                            for exercising your rights.</p>
                        <br/>
                        <p>You may also have the right to opt out of sales of your personal information, or the
                            processing of your personal information for targeted advertising. To opt out of any future
                            targeted advertising we may offer, please use the tools described in the section above on
                            Tailored Advertising.</p>
                        <br/>
                        <p>If you would like information regarding your rights under applicable law or would like to
                            exercise any of them, contact us at https://support.goodking.ai/hc/en-us/requests/new. To
                            protect your privacy and security, we take reasonable steps to verify your identity and
                            requests before granting such requests. If we are unable to verify your identity, we may be
                            unable to respond to your requests.</p>
                        <br/>
                        <p>Depending on applicable law, you may have the right to appeal our decision to deny your
                            request, if applicable. We will provide information about how to exercise that right in our
                            response denying the request.</p>
                        <br/>
                        <p>Regional privacy disclosures and rights. Additional disclosures for residents of Republic of
                            Korea are set forth below in Regional Privacy Disclosures.</p>
                        <br/>
                        <p>6. Regional Privacy Disclosures</p>
                        <p>Residents of Republic of Korea. goodking.ai is considered the “data controller” of the
                            “personal data” (as defined under the General Data Protection Regulation) we handle under
                            this Policy. In other words, goodking.ai is responsible for deciding how to collect, use,
                            and disclose personal data, subject to applicable law. The laws of the Republic of Korea
                            require data controllers to tell you about the legal ground that they rely on for using,
                            sharing, or disclosing your personal data. To the extent those laws apply, our legal grounds
                            are as follows:</p>
                        <br/>
                        <p>Contractual Commitments: We may use, share, or disclose personal data to honor our
                            contractual commitments to you. For example, we will process your personal data to comply
                            with our agreements with you, and to honor our commitments in any contracts that we have
                            with you.</p>
                        <br/>
                        <p>With Your Consent: Where required by law, and in some other cases, we use, share, or disclose
                            personal data on the basis of your consent.</p>
                        <br/>
                        <p>Legitimate Interests: In many cases, we use, share, or disclose personal data on the ground
                            that it furthers our legitimate business interests in ways that are not overridden by the
                            interests or fundamental rights and freedoms of the affected individuals, such as customer
                            service, certain promotional activities, analyzing and improving our business, providing
                            security for the Services, preventing fraud, and managing legal issues.</p>
                        <br/>
                        <p>Legal Compliance: We need to use and disclose personal data in certain ways to comply with
                            our legal obligations.</p>
                        <br/>
                        <p>In addition to those rights described herein, you have the right to lodge a complaint with
                            the relevant supervisory authority. However, we encourage you to contact us first, and we
                            will do our very best to resolve your concern.</p>
                        <br/>
                        <p>In the twelve months leading up to the effective date of this Policy, we have collected and
                            disclosed the categories of personal information described in Section 1 for business or
                            commercial purposes. The categories of personal information are: identifiers, financial
                            information (such as payment information), commercial or transactional information, internet
                            or other electronic network activity information, general geolocation data, audio or video
                            information (such as pictures and videos you submit), other information about you that you
                            may provide to us voluntarily, such as through User Content, other information that may
                            identify you, and inferences drawn from the information we collect.</p>
                        <br/>
                        <p>We process the categories of personal information identified above for the purposes as
                            described in more detail in Section 2 above.</p>
                        <br/>
                        <p>We collect the categories of personal information identified above from the following
                            sources: (1) directly from you; (2) through your use of the Services; and (3) other parties
                            such as other users and through unaffiliated parties.</p>
                        <br/>
                        <p>We describe our information disclosure practices in more detail in Section 3, above.</p>
                        <br/>
                        <p>“Sale” and “sharing” of personal information</p>
                        <br/>
                        <p>We may use third-party analytics services and online advertising services that may result in
                            the “sharing” of online identifiers (e.g., cookie data, IP addresses, device identifiers,
                            general location information, and usage information) with advertising partners to advertise
                            the Services on other websites. If you or your authorized agent would like to opt out of our
                            “sharing” of your information for such purposes, you may do so by using the tools described
                            in the section above on Tailored Advertising, which are also available here.</p>
                        <br/>
                        <p>We do not knowingly “sell” or “share” the personal information of children under 16.</p>
                        <br/>
                        <p>Do-Not-Track disclosure</p>
                        <br/>
                        <p>We do not respond to browser-initiated Do Not Track signals, as the Internet industry is
                            currently still working on Do Not Track standards, implementations, and solutions.</p>
                        <br/>
                        <p>Retention of your personal information</p>
                        <br/>
                        <p>Please see the Data Retention section below.</p>
                        <br/>
                        <p>7. Children’s Privacy</p>
                        <p>The Services are not designed for minors under 13, and our Terms of Service prohibit use by
                            anyone under 13 or under 19 if you are a resident of the Republic of Korea. If we discover
                            that an individual under 13 has provided us with “personal information,” as defined in the
                            Children's Online Privacy Protection Act (“COPPA”), we will close the account and delete the
                            personal information to the extent required by COPPA. We may, where permitted by law, retain
                            certain information internally for purposes described in this Policy.</p>
                        <br/>
                        <p>8. Security</p>
                        <p>goodking.ai implements technical, administrative, and physical safeguards to protect the
                            information we collect from loss, misuse, and unauthorized access, disclosure, alteration,
                            or destruction. However, no Internet or email transmission is ever fully secure or error
                            free. Therefore, we do not promise and cannot guarantee the security of your information or
                            communications.</p>
                        <br/>
                        <p>9. Data Retention</p>
                        <p>In General. We keep your information for the time necessary for the purposes for which it is
                            processed. The length of time for which we retain information depends on the purposes for
                            which we collected and use it and your choices, after which time we may delete and/or
                            aggregate it. We may also retain and use this information as necessary to comply with our
                            legal obligations, resolve disputes, and enforce our agreements.</p>
                        <br/>
                        <p>Popular Characters. If a Character you create and set to “Public” reaches a certain threshold
                            of popularity, we reserve the right to preserve that Character’s characteristics and to keep
                            that Character active on the Services, even if you otherwise delete your data and your
                            account. We do this to avoid impacting the experience of other users, given that a highly
                            popular Character by definition is having active conversations with many thousands of users.
                            To ensure that we do not retain information about you when this occurs, please avoid
                            submitting any such information as part of the Character creation flow if you are creating a
                            “Public” Character.</p>
                        <br/>
                        <p>10. Consent to Transfer</p>
                        <p>Our services are global and your information may be stored and processed in the Republic of
                            Korea and other countries outside the Republic of Korea that may have data protection laws
                            that differ from the laws in your country, and data may be accessible to law enforcement and
                            national security authorities under certain circumstances. By using the Services, or
                            providing us with any information, you consent to the collection, processing, maintenance,
                            and transfer of such information in and to the Republic of Korea and other applicable
                            countries in which the privacy laws may not be as comprehensive as, or equivalent to, those
                            in the country where you reside and/or are a citizen.</p>
                        <br/>
                        <p>11. Links to Third Party Websites</p>
                        <p>The Services may contain links to third-party websites or services. We are not responsible
                            for the content or practices of those websites or services. The collection, use, and
                            disclosure of your information by third parties will be subject to the privacy policies of
                            the third-party websites or services, and not this Policy. We urge you to read the privacy
                            and security policies of these third parties before providing information to them.</p>
                        <br/>
                        <p>12. Changes to this Policy</p>
                        <p>We may change this Policy to reflect changes in the law, our information practices or the
                            features of the Services. We will indicate the date of the most recent update in this
                            Policy. If we make a material change to the Policy, you will be provided with appropriate
                            notice in accordance with legal requirements. By continuing to use the Services, you are
                            confirming that you have read and understood the latest version of this Policy.</p>
                        <br/>
                        <p>13. Contact Us</p>
                        <p>If you have any questions about our Privacy Policy or information practices, please visit our
                            Help Center at goodking.ai or submit a request at https://goodking.ai</p>
                        <br/>
                        <br/>
                    </>
                }


            </Box>
        </>
    )
}

export default PrivacyScreen
 